import React from "react";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  TextField,
  Select,
  Accordion,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch } from "react-redux";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { clearAlertMessage } from "../../../store/actions/shared/alertActions";
import EnterpriseLogo from "../../../../Assets/Images/enterprise_logo.jpg";
import NationalLogo from "../../../../Assets/Images/national_logo.jpg";
import EnterpriseSmallLog from "../../../../Assets/Images/ET_logo_small.jpg";
import NationalSmallLogo from "../../../../Assets/Images/ZL_logo_small.jpg";
import ETZLSmallLogo from "../../../../Assets/Images/ET_ZL_logo_small.jpg";
import AddressLookup from "./controls/addressLookUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ReservationReasonForHireViewModel,
  BrandType,
  JourneyMethodType,
  ReservationLocationViewModel,
  ReservationLocInfoViewModel,
  ReservationLocationsViewModel,
  GooglApiLocation,
} from "./reservationFormType";
import axiosInstance from "../../../../helpers/axiosInstance";
import moment from "moment";
import { appInsights } from "../../../../helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Paper } from "@mui/material";
import { authService } from "services/authService";
import { convertKmToMiles, to12HourFormat } from "helpers/localization";
import { boolean } from "yup/lib/locale";
import { ReservationMode } from "helpers/useReservationMode";

const useStyles = makeStyles({
  title: {
    fontWeight: 12,
    color: "#fff",
    fontStyle: "bold",
  },
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: "0.875rem",
    fontWeight: "bold",
    verticalAlign: "middle",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  table: {
    "& .MuiTableRow-root.Mui-selected": {
      backgroundColor: "#00A25F",
    },
  },
  gridItem: {
    verticalAlign: "top",
    padding: "10px",
    paddingLeft: "0px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
    },
  },
  inputLabel: {
    fontWeight: "bold",
    color: "rgba(0,0,0,0.87)",
    fontSize: "0.8rem",
    lineHeight: "1.43",
  },
  branchError: {
    fontWeight: "bold",
    color: "#DF0000",
  },
  outlinedButton: {
    border: "1px solid rgba(0, 102, 57, 0.95);",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
});

const AccordionSummary = withStyles({
  root: {
    //  flexDirection: "column",
    backgroundColor: "#006639",
    fontWeight: "bold",
    color: "#fff",
    border: "2px solid #006639",
    height: "48px",
    "&$focused": {
      color: "#006639",
    },
    "&.Mui-expanded": {
      minHeight: "48px",
      height: "48px",
    },
    "&.Mui-focused": {
      backgroundColor: "#006639",
    },
  },

  content: {
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
    minHeight: "12px",
    height: "20px",
  },
  expandIcon: {
    marginRight: 0,
    paddingTop: 0,
    color: "#fff",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    "& ::placeholder": {
      color: "#767676",
      opacity: "1",
    },
  },
})(MuiAccordionDetails);

interface branchDetail {
  brand: string;
  stationId: string;
  peopleSoftId: string;
  openingPeriods: openingPeriod[];
  closed: boolean;
}

interface openingPeriod {
  openingHour: string;
  closingHour: string;
}

interface branchType {
  name: string;
  addressLines: string;
  calculatedDistance: string;
  branchDesc: string;
  country: string;
  peoplesoftId: string;
  stationId: string;
  telephone: string;
  brandlogo: string;
  brand: string;
  isDeliveryAccepted: boolean;
  isCollectionAccepted: boolean;
  isAirportLocation: boolean;
  airportCity: string;
  groupBranchCode: string;
}

export default function JourneyStartEndMethods(props) {
  const classes = useStyles();
  const locale = authService.getUserLocale();
  const dispatch = useDispatch();

  const [isdelColEnabled, setisdelColEnabled] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isStartLocation, setIsStartLocation] = React.useState(true);
  const [journeyType, setJourneyType] = React.useState(JourneyMethodType.none);
  const { t } = useTranslation();
  const dateFormat = t("journeyMethod.dateFormat");
  const infoRef = React.useRef<HTMLButtonElement>(null);
  const walkinRef = React.useRef<HTMLButtonElement>(null);
  const nearestBranchRef = React.useRef<HTMLDivElement>();
  const {
    useStartAsEndLocation,
    journeyUId,
    reservationDate,
    reservationStartDate,
    reservationEndDate,
    field: { name, ...field },
    form: { setFieldValue },
  } = props;

  const startLocationDetails: ReservationLocationViewModel =
    props.locationView.startLocation;
  const endLocationDetails: ReservationLocationViewModel =
    props.locationView.endLocation;

  const [startLocationView, setStartLocationView] =
    React.useState<ReservationLocationViewModel>({
      ...props.locationView.startLocation,
      branchDescriptionWithBranchCode: "",
    });
  const [endLocationView, setEndLocationView] =
    React.useState<ReservationLocationViewModel>({
      ...props.locationView.endLocation,
      branchDescriptionWithBranchCode: "",
    });

  const [startBranchDetails, setStartBranchDetails] =
    React.useState<branchType | null>(null);
  const [endBranchDetails, setEndBranchDetails] =
    React.useState<branchType | null>(null);

  const [startBranchOpeningHours, setStartBranchOpeningHours] = React.useState<
    branchDetail[]
  >([]);
  const [endBranchOpeningHours, setEndBranchOpeningHours] = React.useState<
    branchDetail[]
  >([]);

  const [startClosestBranches, setStartClosestBranches] = React.useState<
    branchType[]
  >([]);
  const [endClosestBranches, setEndClosestBranches] = React.useState<
    branchType[]
  >([]);

  const reasonForHire: ReservationReasonForHireViewModel = props.reasonForHire;

  const [locationSearch, setlocationSearch] =
    React.useState<GooglApiLocation>();
  const [startLocationSearch, setStartLocationSearch] =
    React.useState<GooglApiLocation>();
  const [endLocationSearch, setEndLocationSearch] =
    React.useState<GooglApiLocation>();
  const [startBranchLookup, setStartBranchLookup] = React.useState(false);
  const [endBranchLookup, setEndBranchLookup] = React.useState(false);

  const [collectionNotAvailable, setCollectionNotAvailable] =
    React.useState(false);
  const [noStartBranchFound, setNoStartBranchFound] = React.useState(false);
  const [noEndBranchFound, setNoEndBranchFound] = React.useState(false);

  const [closedHoursAlertMessage, setclosedHoursAlertMessage] =
    React.useState(false);
  const [startClosedHoursAlertMessage, setStartClosedHoursAlertMessage] =
    React.useState(false);
  const [endClosedHoursAlertMessage, setEndClosedHoursAlertMessage] =
    React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(-1);

  const [startAddressType, setStartAddressType] = React.useState(
    startLocationDetails.addressType.items[0].value
  );
  const [endAddressType, setEndAddressType] = React.useState(
    endLocationDetails.addressType.items[0].value
  );

  const [startLocationDestination, setStartLocationDestination] =
    React.useState(props.startLocationDestination);
  const [endLocationDestination, setEndLocationDestination] = React.useState(
    props.endLocationDestination
  );
  const [startLocInformation, setStartLocInformation] =
    React.useState<ReservationLocInfoViewModel>();
  const [endLocInformation, setEndLocInformation] =
    React.useState<ReservationLocInfoViewModel>();

  const [startAddress, setStartAddress] = React.useState<GooglApiLocation>({
    location: "",
    peopleSoftId: "",
    stationId: "",
    latitude: "",
    longitude: "",
    brand: "",
    countryCode: "",
    address1: startLocationDetails.addressLine1 ?? "",
    address2: startLocationDetails.addressLine2 ?? "",
    address3: startLocationDetails.addressLine3 ?? "",
    townOrCity: startLocationDetails.townOrCity ?? "",
    postcode: startLocationDetails.postCode ?? "",
    addressSearch: startLocationDetails.addressLocationSearch ?? "",
  });
  const [endAddress, setEndAddress] = React.useState<GooglApiLocation>({
    location: "",
    peopleSoftId: "",
    stationId: "",
    latitude: "",
    longitude: "",
    brand: "",
    countryCode: "",
    address1: endLocationDetails.addressLine1 ?? "",
    address2: endLocationDetails.addressLine2 ?? "",
    address3: endLocationDetails.addressLine3 ?? "",
    townOrCity: endLocationDetails.townOrCity ?? "",
    postcode: endLocationDetails.postCode ?? "",
    addressSearch: endLocationDetails.addressLocationSearch ?? "",
  });

  const [startJourneyMethod, setStartJourneyMethod] =
    React.useState<JourneyMethodType>(JourneyMethodType.none);
  const [endJourneyMethod, setEndJourneyMethod] =
    React.useState<JourneyMethodType>(JourneyMethodType.none);
  const [isDirty, setIsDirty] = React.useState(false);

  const btnRef = React.useRef<HTMLButtonElement>(null);
  const [isAutoGridOpened, setIsAutoGridOpened] = React.useState(false);

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "##fff",
      color: theme.palette.common.black,
      borderBottom: "1px solid #000",
      borderTop: "1px solid #ddd",
      borderRight: "1px solid #ddd",
      fontWeight: "bold",
      height: "50px",
      padding: "0px 15px",
    },
    body: {
      fontSize: 12,
      padding: "0px 15px",
      height: "50px",
    },
  }))(TableCell);

  const loadData = async (typeofLocation) => {
    let result = await axiosInstance.get(
      `places/autocomplete?filter=${
        typeofLocation === 1
          ? encodeURIComponent(startLocationDestination)
          : encodeURIComponent(endLocationDestination)
      }`
    );
    return result;
  };

  const airportWording = [
    t("AirportWording", { lng: "de_DE" }).toLowerCase(),
    t("AirportWording", { lng: "en_CA" }).toLowerCase(),
    t("AirportWording", { lng: "en_GB" }).toLowerCase(),
    t("AirportWording", { lng: "en_US" }).toLowerCase(),
    t("AirportWording", { lng: "es_ES" }).toLowerCase(),
    t("AirportWording", { lng: "fr_CA" }).toLowerCase(),
    t("AirportWording", { lng: "fr_FR" }).toLowerCase(),
  ];

  const nameOfPlaceIsAirport = (googleLocation) => {
    for (let stringLocation of Object.values(googleLocation)) {
      let splitNameOfPlace = `${stringLocation}`
        .toLowerCase()
        .split(" ")
        .map((t) => t.trim());
      let containsAirport =
        splitNameOfPlace.filter((word) => airportWording.includes(word))
          .length > 0;
      if (containsAirport) return true;
    }
    return false;
  };

  const loadAddressDetailData = async (
    addressId,
    locationName,
    typeofLocation
  ) => {
    let locationSearch: GooglApiLocation;
    let response = await axiosInstance.get(`places/${addressId}`);

    if (response) {
      locationSearch = {
        location: locationName,
        peopleSoftId: "",
        stationId: "",
        latitude: response.data.latitude,
        longitude: response.data.longitude,
        brand: "",
        countryCode: response.data.countryCode,
        address1: response.data.address1,
        address2: response.data.address2,
        address3: response.data.address3,
        townOrCity: response.data.townOrCity,
        postcode: response.data.postcode ? response.data.postcode : "",
        addressSearch: locationName,
      };
      if (typeofLocation === 1) {
        setStartLocationSearch(locationSearch);
        setStartAddress(locationSearch);
        setlocationSearch(locationSearch);
      } else {
        setEndLocationSearch(locationSearch);
        setEndAddress(locationSearch);
      }

      return locationSearch;
    } else {
      appInsights.trackException({
        exception: new Error("error"),
        properties: {
          method: "places",
          Component: "ReservationLocation",
        },
        severityLevel: SeverityLevel.Error,
      });
      console.log("error");
      return null;
    }
  };

  const isDelEligble = async (countryCode) => {
    const result = await axiosInstance.get(
      `/reservation/deliveryeligibility/${countryCode}`
    );
    console.log(result);
    if (result.data !== undefined && result.data !== null) {
      return result.data;
    } else {
      appInsights.trackException({
        exception: new Error("error"),
        properties: {
          method: "isDelEligible",
          Component: "ReservationLocation",
        },
        severityLevel: SeverityLevel.Error,
      });
      console.log("error");
      return null;
    }
  };

  // the call to the function must happen before so that it is quick
  const loadInitialData = async () => {
    const isdelCol = startLocationDetails.methodTypes.length > 1;
    const isCountryInDcList = await checkDelEligibility();
    if (!props.isExistingRes || (props.isExistingRes && !props.isReadOnly)) {
      setisdelColEnabled(isCountryInDcList ? false : isdelCol);
    } else {
      setisdelColEnabled(isdelCol);
    }

    if (!props.isExistingRes) {
      let startlocInfo: ReservationLocInfoViewModel | undefined | null;
      let endlocInfo: ReservationLocInfoViewModel | undefined | null;
      let isAuto = false;
      await loadData(1).then(async (response) => {
        if (response.data.data[0]) {
          await loadAddressDetailData(
            response.data.data[0]?.stationId,
            response.data.data[0]?.location,
            1
          ).then(async (data) => {
            if (!isdelCol || isCountryInDcList) {
              setStartJourneyMethod(JourneyMethodType.walkIn);
              startlocInfo = await processDefaultMethods(
                JourneyMethodType.walkIn,
                data
              );
            }
          });
          // processWalkInAndReturnBranches(JourneyMethodType.walkIn, data);
        }
      });

      await loadData(2).then(async (response) => {
        if (response.data.data[0]) {
          await loadAddressDetailData(
            response.data.data[0]?.stationId,
            response.data.data[0]?.location,
            2
          ).then(async (data) => {
            if (!isdelCol || isCountryInDcList) {
              setEndJourneyMethod(JourneyMethodType.returnToBranch);
              endlocInfo = await processDefaultMethods(
                JourneyMethodType.returnToBranch,
                data
              );
            }
          });
        }
      });
      updateCars(startlocInfo, endlocInfo);
      //for default method selected for first time if the grid is opened we have to set its focus to avoid double click
      if (isAutoGridOpened) {
        btnRef.current?.focus();
      }
      /*   await loadData(2).then(async (response) => {
        if (response.data.data[0]) {
          await loadAddressDetailData(
            response.data.data[0]?.stationId,
            response.data.data[0]?.location,
            2
          ).then(async(data) => {
            if (!isdelColEnabled) {
              setEndJourneyMethod(JourneyMethodType.returnToBranch);
              // processWalkInAndReturnBranches(
              //   JourneyMethodType.returnToBranch,
              //  data
              //);
              await processEndMethods(JourneyMethodType.walkIn, data).then((endInfo) => {
                endlocInfo = endInfo ;
              });
            }
          });
        }
      });
*/
    } else {
      //set the default location received

      let selectedMethodType = JourneyMethodType.none;
      if (startLocationView && +startLocationView.selectedMethodTypeId === 1)
        selectedMethodType = JourneyMethodType.walkIn;
      else if (
        startLocationView &&
        +startLocationView.selectedMethodTypeId === 2
      )
        selectedMethodType = JourneyMethodType.delivery;
      setStartJourneyMethod(selectedMethodType);
      setStartLocationSearch(
        convertLocationToGooleApiLocation(startLocationView)
      );

      setStartAddress(convertLocationToGooleApiLocation(startLocationView));
      let cbranch = convertLocationToBranch(startLocationView);
      setStartBranchDetails(cbranch);
      await showBranchOpeningHours(1, cbranch, false, selectedMethodType).then(
        (startlocInfo) => {
          setStartLocInformation(
            startlocInfo === null ? undefined : startlocInfo
          );
        }
      );

      selectedMethodType = JourneyMethodType.none;
      if (endLocationView && +endLocationView.selectedMethodTypeId === 3)
        selectedMethodType = JourneyMethodType.returnToBranch;
      else if (endLocationView && +endLocationView.selectedMethodTypeId === 4)
        selectedMethodType = JourneyMethodType.collection;

      setEndJourneyMethod(selectedMethodType);

      setEndLocationSearch(convertLocationToGooleApiLocation(endLocationView));
      setEndAddress(convertLocationToGooleApiLocation(endLocationView));
      let ebranch = convertLocationToBranch(endLocationView);

      setEndBranchDetails(ebranch);
      await showBranchOpeningHours(2, ebranch, false, selectedMethodType).then(
        (endlocInfo) => {
          setEndLocInformation(endlocInfo === null ? undefined : endlocInfo);
        }
      );
      props.isLocationValid(true);
      updateCars(startLocInformation, endLocInformation);
    }
  };

  const convertLocationToGooleApiLocation = (
    loc: ReservationLocationViewModel
  ): GooglApiLocation => {
    let location: GooglApiLocation = {
      location: loc.locationEditorName,
      peopleSoftId: loc.peopleSoftId,
      stationId: loc.stationId,
      latitude: loc.latitude,
      longitude: loc.longitude,
      brand: loc.brand,
      countryCode: loc.country,
      address1: loc.addressLine1,
      address2: loc.addressLine2,
      address3: loc.addressLine3,
      townOrCity: loc.townOrCity,
      postcode: loc.postCode,
      addressSearch: loc.addressLocationSearch,
    };
    return location;
  };

  const convertLocationToBranch = (
    loc: ReservationLocationViewModel
  ): branchType => {
    let branch: branchType = {
      name: "",
      addressLines: "",
      calculatedDistance: "",
      branchDesc: loc.branchDescription,
      country: loc.country,
      peoplesoftId: loc.peopleSoftId,
      stationId: loc.stationId,
      telephone: loc.telephone ?? "",
      brandlogo: "",
      brand: loc.brand,
      isDeliveryAccepted: loc.isDeliveryAccepted,
      isCollectionAccepted: loc.isCollectionAccepted,
      isAirportLocation: loc.isAirportLocation,
      airportCity: loc.airportCity ?? "",
      groupBranchCode: "",
    };
    return branch;
  };

  const processEndMethods = async (
    journeyType,
    defaultLocation: any = null
  ) => {
    setCollectionNotAvailable(false);
    setEndBranchDetails(null);
    setLoading(true);
    const reservationDt = getJourneyTime(2);
    let lat: string = "";
    let lon: string = "";
    let loc = defaultLocation === null ? endLocationSearch : defaultLocation;
    lat = loc.latitude;
    lon = loc.longitude;
    let country = loc.countryCode;
    let isPlaceAirport = nameOfPlaceIsAirport(loc);
    setNoEndBranchFound(false);
    if (journeyType === JourneyMethodType.returnToBranch) {
      //get closest branches list
      let result = await GetClosestBranches(
        reservationDt,
        lat,
        lon,
        2,
        getAvailableBrands(2, JourneyMethodType.returnToBranch),
        country,
        isPlaceAirport
      );

      if (result !== null && result.length > 0) {
        let closedBranches = result;
        // we will not open the grid for End method (just for start method)

        closedBranches = mergeDualBrandBranchesForGrid(closedBranches);
        setEndClosestBranches(closedBranches);
        let index = 0;
        if (
          startJourneyMethod === JourneyMethodType.delivery &&
          useStartAsEndLocation
        ) {
          //find the first delivery accepted branch
          for (let i = 0; i < closedBranches.length; i++) {
            if (closedBranches[i].isDeliveryAccepted) {
              index = i;
              break;
            }
          }
        }
        //set the start branch details
        setEndBranchDetails(closedBranches[index]);
        // show the branch opening times against the brands for selected branch
        let endlocInfo = await showBranchOpeningHours(
          2,
          closedBranches[index],
          false,
          JourneyMethodType.returnToBranch
        );
        setEndLocInformation(endlocInfo === null ? undefined : endlocInfo);
        updateLocationDetails(
          2,
          closedBranches[index],
          JourneyMethodType.returnToBranch
        );

        if (startLocInformation) {
          updateCars(startLocInformation, endlocInfo);
        }
      } else {
        setNoEndBranchFound(true);
      }
    } else if (journeyType === JourneyMethodType.collection) {
      if (startBranchDetails?.brand === "ZL") {
        setCollectionNotAvailable(true);
        setEndBranchDetails(null);
        setEndLocInformation(undefined);
        setEndBranchOpeningHours([]);
        setFieldValue("carClass", null);
      } else {
        let result = await GetClosestBranches(
          reservationDt,
          lat,
          lon,
          2,
          "ET",
          country,
          isPlaceAirport
        );
        let endlocInfo: ReservationLocInfoViewModel | null = null;

        if (result !== null && result.length > 0) {
          //find the first delivery accespted branch
          for (let i = 0; i < result.length; i++) {
            if (result[i].isCollectionAccepted) {
              setEndBranchDetails(result[i]);
              endlocInfo = await showBranchOpeningHours(
                2,
                result[i],
                false,
                JourneyMethodType.collection
              );
              setEndLocInformation(
                endlocInfo === null ? undefined : endlocInfo
              );
              updateLocationDetails(2, result[i], JourneyMethodType.collection);
              if (startLocInformation) {
                updateCars(startLocInformation, endlocInfo);
              }

              break;
            }
          }
          setEndClosestBranches(result);
          //if End method already selected try to update if require (e.g brands should match)
        } else {
          setNoEndBranchFound(true);
        }
      }
    }
    setLoading(false);
  };

  const processDefaultMethods = async (
    journeyType,
    defaultLocation: any = null
  ) => {
    if (journeyType === JourneyMethodType.walkIn) {
      setCollectionNotAvailable(false);
      setStartBranchDetails(null);
      setLoading(true);
      const reservationDt = getJourneyTime(1);
      let lat: string = "";
      let lon: string = "";
      let loc =
        defaultLocation === null ? startLocationSearch : defaultLocation;
      lat = loc.latitude;
      lon = loc.longitude;
      let country = loc.countryCode;
      let isPlaceAirport = nameOfPlaceIsAirport(loc);
      setNoStartBranchFound(false);
      //get closest branches list
      let result = await GetClosestBranches(
        reservationDt,
        lat,
        lon,
        1,
        getAvailableBrands(1, JourneyMethodType.walkIn),
        country,
        isPlaceAirport
      );

      if (result !== null && result.length > 0) {
        let closedBranches = result;
        //if branches has multiple branches against the airport locations (more than two)
        if (hasMultiAirportBranches(closedBranches)) {
          setStartBranchLookup(true);
          setIsAutoGridOpened(true);
          //setSelectedRow(0);
          setStartClosestBranches(closedBranches);
          return null;
        } else {
          closedBranches = mergeDualBrandBranchesForGrid(closedBranches);
          setStartClosestBranches(closedBranches);
        }
        //set the start branch details
        setStartBranchDetails(closedBranches[0]);
        // show the branch opening times against the brands for selected branch
        let startlocInfo = await showBranchOpeningHours(
          1,
          closedBranches[0],
          false,
          JourneyMethodType.walkIn
        );
        setStartLocInformation(
          startlocInfo === null ? undefined : startlocInfo
        );
        updateLocationDetails(1, closedBranches[0], JourneyMethodType.walkIn);
        setLoading(false);
        return startlocInfo;
      } else {
        setNoStartBranchFound(true);
        setLoading(false);
        return null;
      }
    } else if (journeyType === JourneyMethodType.returnToBranch) {
      setEndBranchDetails(null);
      setNoEndBranchFound(false);
      setLoading(true);
      const reservationDt = getJourneyTime(2);
      let lat: string = "";
      let lon: string = "";
      let loc = defaultLocation === null ? endLocationSearch : defaultLocation;
      lat = loc.latitude;
      lon = loc.longitude;
      let country = loc.countryCode;
      let isPlaceAirport = nameOfPlaceIsAirport(loc);
      let result = await GetClosestBranches(
        reservationDt,
        lat,
        lon,
        2,
        getAvailableBrands(2, JourneyMethodType.returnToBranch),
        country,
        isPlaceAirport
      );

      if (result !== null && result.length > 0) {
        //
        let mergedBranches = mergeDualBrandBranchesForGrid(result);

        let brandList = startBranchDetails?.brand.split(",");
        let sameBrandFound = false;
        let index = 0;
        for (let i = 0; i < mergedBranches.length; i++) {
          let receivedBrand = mergedBranches[i].brand.split(",");

          for (let j = 0; j < receivedBrand.length; j++) {
            if (brandList && brandList.includes(receivedBrand[j])) {
              sameBrandFound = true;
              index = i;
              break;
            }
          }
          if (sameBrandFound) break;
        }

        setEndBranchDetails(mergedBranches[index]);
        let endlocInfo = await showBranchOpeningHours(
          2,
          mergedBranches[index],
          false,
          JourneyMethodType.returnToBranch
        );

        setEndLocInformation(endlocInfo === null ? undefined : endlocInfo);
        updateLocationDetails(
          2,
          mergedBranches[index],
          JourneyMethodType.returnToBranch
        );
        setEndClosestBranches(mergedBranches);
        setLoading(false);

        return endlocInfo;
      } else {
        setNoEndBranchFound(true);
        setLoading(false);

        return null;
      }
    }
  };

  const processStartMethods = async (
    journeyType,
    defaultLocation: any = null
  ) => {
    setCollectionNotAvailable(false);
    setStartBranchDetails(null);
    setLoading(true);
    const reservationDt = getJourneyTime(1);
    let lat: string = "";
    let lon: string = "";
    let loc = defaultLocation === null ? startLocationSearch : defaultLocation;
    lat = loc.latitude;
    lon = loc.longitude;
    let country = loc.countryCode;
    let isPlaceAirport = nameOfPlaceIsAirport(loc);
    setNoStartBranchFound(false);
    if (journeyType === JourneyMethodType.walkIn) {
      //get closest branches list
      let result = await GetClosestBranches(
        reservationDt,
        lat,
        lon,
        1,
        getAvailableBrands(1, JourneyMethodType.walkIn),
        country,
        isPlaceAirport
      );

      if (result !== null && result.length > 0) {
        let closedBranches = result;
        //if branches has multiple branches against the airport locations (more than two)
        if (hasMultiAirportBranches(closedBranches)) {
          setStartBranchLookup(true);
          setSelectedRow(0);
          setStartClosestBranches(closedBranches);
        } else {
          closedBranches = mergeDualBrandBranchesForGrid(closedBranches);
          setStartClosestBranches(closedBranches);

          //set the start branch details
          setStartBranchDetails(closedBranches[0]);
          // show the branch opening times against the brands for selected branch
          let startlocInfo = await showBranchOpeningHours(
            1,
            closedBranches[0],
            false,
            JourneyMethodType.walkIn
          );
          setStartLocInformation(
            startlocInfo === null ? undefined : startlocInfo
          );
          updateLocationDetails(1, closedBranches[0], JourneyMethodType.walkIn);

          //if End method already selected try to update if require (e.g brands should match)
          await updateEndMethods(
            defaultLocation,
            closedBranches[0],
            JourneyMethodType.walkIn,
            startlocInfo
          ).then((res) => {
            updateCars(startlocInfo, res);
          });
        }
      } else {
        setNoStartBranchFound(true);
      }
    } else if (journeyType === JourneyMethodType.delivery) {
      let result = await GetClosestBranches(
        reservationDt,
        lat,
        lon,
        1,
        "ET",
        country,
        isPlaceAirport
      );
      let startlocInfo: ReservationLocInfoViewModel | null = null;

      if (result !== null && result.length > 0) {
        //find the first delivery accespted branch
        for (let i = 0; i < result.length; i++) {
          if (result[i].isDeliveryAccepted) {
            setStartBranchDetails(result[i]);
            startlocInfo = await showBranchOpeningHours(
              1,
              result[i],
              false,
              JourneyMethodType.delivery
            );
            setStartLocInformation(
              startlocInfo === null ? undefined : startlocInfo
            );
            updateLocationDetails(1, result[i], JourneyMethodType.delivery);
            await updateEndMethods(
              defaultLocation,
              result[i],
              JourneyMethodType.delivery,
              startlocInfo
            ).then((res) => {
              updateCars(startlocInfo, res);
            });

            break;
          }
        }
        setStartClosestBranches(mergeDualBrandBranchesForGrid(result));
        //if End method already selected try to update if require (e.g brands should match)
      } else {
        setNoStartBranchFound(true);
      }
    }
    setLoading(false);
  };

  const updateEndMethods = async (
    defaultLocation,
    selectedBranch,
    methodType,
    locInfo
  ) => {
    let endlocInfo: ReservationLocInfoViewModel | null = null;

    if (
      endJourneyMethod &&
      (endJourneyMethod === JourneyMethodType.returnToBranch ||
        endJourneyMethod === JourneyMethodType.collection)
    ) {
      const reservationDt = getJourneyTime(2);
      let lat: string = "";
      let lon: string = "";
      let loc = defaultLocation === null ? endLocationSearch : defaultLocation;
      lat = loc.latitude;
      lon = loc.longitude;
      let country = loc.countryCode;
      let isPlaceAirport = nameOfPlaceIsAirport(loc);
      setNoEndBranchFound(false);
      if (endJourneyMethod === JourneyMethodType.returnToBranch) {
        let result = await GetClosestBranches(
          reservationDt,
          lat,
          lon,
          2,
          methodType === JourneyMethodType.delivery
            ? "ET"
            : selectedBranch.brand,
          country,
          isPlaceAirport
        );

        if (result !== null && result.length > 0) {
          let mergedBranches = mergeDualBrandBranchesForGrid(result);
          let sameBrandFound = false;
          let index = 0;
          //if start method is delivery and its two way journey then select the same or first delivery accepted branch.
          if (
            methodType === JourneyMethodType.delivery &&
            useStartAsEndLocation
          ) {
            //find the first delivery accepted branch
            for (let i = 0; i < mergedBranches.length; i++) {
              if (mergedBranches[i].isDeliveryAccepted) {
                setEndBranchDetails(mergedBranches[i]);
                endlocInfo = await showBranchOpeningHours(
                  2,
                  mergedBranches[i],
                  false,
                  methodType
                );
                updateLocationDetails(2, mergedBranches[i], endJourneyMethod);
                break;
              }
            }
          } else {
            //find the first branch which has same brand as start branch

            let brandList = selectedBranch?.brand.split(",");
            for (let i = 0; i < mergedBranches.length; i++) {
              let receivedBrand = mergedBranches[i].brand.split(",");

              for (let j = 0; j < receivedBrand.length; j++) {
                if (brandList && brandList.includes(receivedBrand[j])) {
                  sameBrandFound = true;
                  index = i;
                  break;
                }
              }
              if (sameBrandFound) break;
            }
            if (sameBrandFound) {
              setEndBranchDetails(mergedBranches[index]);
              endlocInfo = await showBranchOpeningHours(
                2,
                mergedBranches[index],
                false,
                methodType
              );
              updateLocationDetails(2, mergedBranches[index], endJourneyMethod);
            } else {
              setEndBranchDetails(null);
              setEndJourneyMethod(JourneyMethodType.none);
              setEndBranchOpeningHours([]);
              setEndBranchLookup(false);
              setJourneyType(JourneyMethodType.none);
            }
          }
        } else {
          setNoEndBranchFound(true);
        }
      } else if (endJourneyMethod === JourneyMethodType.collection) {
        if (selectedBranch?.brand === "ZL") {
          setCollectionNotAvailable(true);
          setEndBranchDetails(null);
          setEndLocInformation(undefined);
          setEndBranchOpeningHours([]);
          setFieldValue("carClass", null);
        } else {
          setCollectionNotAvailable(false);
          let result = await GetClosestBranches(
            reservationDt,
            lat,
            lon,
            2,
            "ET",
            country,
            isPlaceAirport
          );

          if (result !== null && result.length > 0) {
            let mergedBranches = result;

            //if start method is delivery and its two way journey then select the same or first delivery accepted branch.

            if (
              methodType === JourneyMethodType.delivery &&
              useStartAsEndLocation
            ) {
              //find the first delivery accepted branch
              for (let i = 0; i < mergedBranches.length; i++) {
                if (mergedBranches[i].isDeliveryAccepted) {
                  setEndBranchDetails(mergedBranches[i]);
                  endlocInfo = await showBranchOpeningHours(
                    2,
                    mergedBranches[i],
                    false,
                    methodType
                  );
                  console.log("Endlocation found ", endlocInfo);
                  updateLocationDetails(2, mergedBranches[i], endJourneyMethod);
                  break;
                }
              }
            } else {
              //find the first branch which has same brand as start branch
              /* let brandList = selectedBranch?.brand.split(",");
              for (let i = 0; i < mergedBranches.length; i++) {
                let receivedBrand = mergedBranches[i].brand.split(",");

                for (let j = 0; j < receivedBrand.length; j++) {
                  if (brandList && brandList.includes(receivedBrand[j])) {
                    sameBrandFound = true;
                    index = i;
                    break;
                  }
                }
                if (sameBrandFound) break;
              }*/

              //find the first collection accepted branch
              let branchFound = false;
              for (let i = 0; i < mergedBranches.length; i++) {
                if (mergedBranches[i].isCollectionAccepted) {
                  setEndBranchDetails(mergedBranches[i]);
                  endlocInfo = await showBranchOpeningHours(
                    2,
                    mergedBranches[i],
                    false,
                    methodType
                  );
                  updateLocationDetails(2, mergedBranches[i], endJourneyMethod);
                  branchFound = true;
                  break;
                }
              }
              if (!branchFound) {
                setEndBranchDetails(null);
                setEndJourneyMethod(JourneyMethodType.none);
                setEndBranchOpeningHours([]);
                setEndBranchLookup(false);
                setJourneyType(JourneyMethodType.none);
              }
            }
          } else {
            setNoEndBranchFound(true);
          }
        }
      }
    } else {
      //if useStartAsEndLocation then set the
    }
    return endlocInfo;
  };

  const hasMultiAirportBranches = (data: branchType[]) => {
    let result = false;
    //grid will only open when the start location is itself an airport and it has more than 2 branches with airport code true
    if (
      data &&
      data !== null &&
      data.length > 0 &&
      startLocationDetails &&
      startLocationDetails.isAirportLocation
    ) {
      let brandWithAirportCode = data.filter((x) => x.isAirportLocation);

      result = brandWithAirportCode.length > 2;
    }
    return result;
  };

  const updateCollectionBranchData = async (
    selectedBranch,
    defaultLocation: any,
    startMethod: JourneyMethodType
  ) => {
    let endlocInfo: ReservationLocInfoViewModel | null = null;

    // check if the brand is National then show message
    if (selectedBranch?.brand === "ZL" || collectionNotAvailable) {
      setEndBranchDetails(null);
      setEndJourneyMethod(JourneyMethodType.none);
      setEndBranchOpeningHours([]);
      setEndBranchLookup(false);
      setFieldValue("carClass", null);
    } else {
      // first get the latest branch data based upon selected branch
      setLoading(true);
      setCollectionNotAvailable(false);
      const reservationDt = getJourneyTime(2);
      let lat: string = "";
      let lon: string = "";
      let loc = defaultLocation === null ? endLocationSearch : defaultLocation;
      lat = loc.latitude;
      lon = loc.longitude;
      let country = loc.countryCode;
      let isPlaceAirport = nameOfPlaceIsAirport(loc);
      setNoEndBranchFound(false);
      let result = await GetClosestBranches(
        reservationDt,
        lat,
        lon,
        2,
        "ET",
        country,
        isPlaceAirport
      );

      if (result !== null && result.length > 0) {
        let mergedBranches = mergeDualBrandBranchesForGrid(result);
        //find the first collection accepted branch
        for (let i = 0; i < mergedBranches.length; i++) {
          if (mergedBranches[i].isCollectionAccepted) {
            setEndBranchDetails(mergedBranches[i]);
            endlocInfo = await showBranchOpeningHours(
              2,
              mergedBranches[i],
              false,
              startMethod
            );
            updateLocationDetails(2, mergedBranches[i], endJourneyMethod);
            break;
          }
        }
      } else {
        setNoEndBranchFound(true);
      }
    }
    setLoading(false);
    return endlocInfo;
  };
  const getAvailableBrands = (
    typeofLocation: number,
    methodType: JourneyMethodType = JourneyMethodType.none
  ) => {
    let brandArray = reasonForHire.brands
      .split(",")
      .filter((x) => x === BrandType.ENTERPRISE || BrandType.NATIONAL);

    switch (typeofLocation) {
      case 1:
        return brandArray.join(",");
      case 2:
        //check if startMethod has not been selected then return the account brands
        if (startJourneyMethod === undefined) return brandArray.join(",");
        else {
          let stBranch =
            startBranchDetails === null
              ? startLocationDetails
              : startBranchDetails;
          let isAirport = stBranch.isAirportLocation;

          let brandToSearchFor =
            methodType === JourneyMethodType.delivery ||
            methodType === JourneyMethodType.collection
              ? "ET"
              : brandArray.length > 1 && isAirport
              ? brandArray.join(",")
              : stBranch.brand;

          return brandToSearchFor;
        }
      default:
        return brandArray.join(",");
    }
  };
  const getJourneyTime = (typeofLocation: number) => {
    switch (typeofLocation) {
      case 1:
        return reservationStartDate !== null
          ? moment(reservationStartDate).format("YYYY-MM-DDTHH:mm:ss")
          : moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");

      case 2:
        return reservationEndDate !== null
          ? moment(reservationEndDate).format("YYYY-MM-DDTHH:mm:ss")
          : moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");

      default:
        return moment().startOf("day").format("YYYY-MM-DDTHH:mm:ss");
    }
  };
  const showBranchOpeningHours = async (
    typeOfLocation: number,
    branch: branchType,
    isGridSelectedBranch = false,
    methodType: JourneyMethodType = JourneyMethodType.none
  ) => {
    let brandArray = reasonForHire.brands
      .split(",")
      .filter((x) => x === BrandType.ENTERPRISE || BrandType.NATIONAL);
    const reservationDt = getJourneyTime(typeOfLocation);

    switch (typeOfLocation) {
      case 1:
        if (branch !== null) {
          let isAirport = branch.isAirportLocation;
          let peopleSoftId = isAirport ? branch.airportCity : branch.stationId;
          //if the branch is selected from grid then show only selected branch brands otherwise if airport then go for dual brand if supported
          //otherwise branch brand only
          let brandToSearchFor = isGridSelectedBranch
            ? branch.brand
            : brandArray.length > 1 && isAirport
            ? brandArray.join(",")
            : branch.brand;

          let branchOpeningHours = await GetBranchOpeningHours(
            reservationDt,
            peopleSoftId,
            brandToSearchFor,
            1
          );
          if (branchOpeningHours !== null && branchOpeningHours.length > 0) {
            setStartBranchOpeningHours(branchOpeningHours);

            //OTA checks only the first brand
            if (
              !isActualTimeInOpeningPeriods(
                reservationDt,
                branchOpeningHours[0].openingPeriods
              ) &&
              !branchOpeningHours[0].closed
            )
              setStartClosedHoursAlertMessage(true);
            else {
              setStartClosedHoursAlertMessage(false);
            }
            return updateLocationInfo(1, branchOpeningHours);
          } else console.log("Branch timing not found: ", branchOpeningHours);
        }

        break;
      case 2:
        if (branch !== null) {
          let isAirport = branch.isAirportLocation;
          let peopleSoftId = isAirport ? branch.airportCity : branch.stationId;
          let brandToSearchFor =
            methodType === JourneyMethodType.delivery ||
            methodType === JourneyMethodType.collection
              ? "ET"
              : isGridSelectedBranch
              ? branch.brand
              : brandArray.length > 1 && isAirport
              ? brandArray.join(",")
              : branch.brand;

          let branchOpeningHours = await GetBranchOpeningHours(
            reservationDt,
            peopleSoftId,
            brandToSearchFor,
            2
          );
          if (branchOpeningHours !== null && branchOpeningHours.length > 0) {
            setEndBranchOpeningHours(branchOpeningHours);
            //OTA checks only the first brand
            if (
              !isActualTimeInOpeningPeriods(
                reservationDt,
                branchOpeningHours[0].openingPeriods
              ) &&
              !branchOpeningHours[0].closed
            ) {
              setEndClosedHoursAlertMessage(true);
            } else {
              setEndClosedHoursAlertMessage(false);
            }
            return updateLocationInfo(2, branchOpeningHours);
          }
        }
        break;
    }
    return null;
  };

  function isActualTimeInOpeningPeriods(
    reservationDt: string,
    openingPeriods: openingPeriod[]
  ) {
    let result = false;
    let actualTime = moment(reservationDt);
    let actualMins = actualTime.hour() * 60 + actualTime.minute();

    openingPeriods.forEach((item) => {
      let openingTime = moment(item.openingHour, "HH:mm");
      let openingMins = openingTime.hour() * 60 + openingTime.minute();

      let closingTime = moment(item.closingHour, "HH:mm");
      let closingMins = closingTime.hour() * 60 + closingTime.minute();

      result =
        result || (actualMins >= openingMins && actualMins <= closingMins);
    });

    return result;
  }

  function getOpeningPeriodsString(openingPeriods: openingPeriod[]) {
    let result = "";
    let isOpenHourOverMidNight =
      openingPeriods.filter((x) => x.openingHour === "00:00").length > 0 &&
      openingPeriods.filter((x) => x.closingHour === "23:59").length > 0;

    if (isOpenHourOverMidNight) {
      let other = openingPeriods.filter(
        (x) => x.openingHour !== "00:00" && x.closingHour !== "23:59"
      );
      console.log(other);
      other.forEach((item) => {
        result +=
          to12HourFormat(locale, item.openingHour) +
          " - " +
          to12HourFormat(locale, item.closingHour) +
          " " +
          t("journeyMethod.clockText") +
          ",  ";
      });

      let openingHourOverMidNight = to12HourFormat(
        locale,
        openingPeriods.filter((x) => x.closingHour === "23:59")[0].openingHour
      );
      let closingHourOverMidNight = to12HourFormat(
        locale,
        openingPeriods.filter((x) => x.openingHour === "00:00")[0].closingHour
      );
      result +=
        openingHourOverMidNight +
        " - " +
        closingHourOverMidNight +
        " " +
        t("journeyMethod.clockText") +
        ",  ";
    } else {
      openingPeriods.forEach((item) => {
        result +=
          to12HourFormat(locale, item.openingHour) +
          " - " +
          to12HourFormat(locale, item.closingHour) +
          " " +
          t("journeyMethod.clockText") +
          ",  ";
      });
    }

    return result === "" ? result : result.slice(0, -3);
  }

  const updateLocationInfo = (
    typeofLocation: number,
    openhours: any
  ): ReservationLocInfoViewModel | null => {
    let stations: any = [];
    let peopleIds: any = [];
    switch (typeofLocation) {
      case 1:
        let locInfo1: ReservationLocInfoViewModel = {
          Name: startLocationDetails.branchDescription,
          Latitude: startLocationDetails.latitude,
          Longitude: startLocationDetails.longitude,
          StationIds: [],
          PeopleSoftIds: [],
        };
        if (openhours && openhours.length > 0) {
          openhours.forEach((element, index) => {
            stations.push({ brand: element.brand, value: element.stationId });
            peopleIds.push({
              brand: element.brand,
              value: element.peopleSoftId,
            });
          });
        }
        locInfo1.StationIds = stations;
        locInfo1.PeopleSoftIds = peopleIds;
        // setStartLocInformation(locInfo1);

        return locInfo1;

      case 2:
        let locInfo2: ReservationLocInfoViewModel = {
          Name: endLocationDetails.branchDescription,
          Latitude: endLocationDetails.latitude,
          Longitude: endLocationDetails.longitude,
          StationIds: [],
          PeopleSoftIds: [],
        };
        if (openhours && openhours.length > 0) {
          openhours.forEach((element, index) => {
            stations.push({ brand: element.brand, value: element.stationId });
            peopleIds.push({
              brand: element.brand,
              value: element.peopleSoftId,
            });
          });
        }
        locInfo2.StationIds = stations;
        locInfo2.PeopleSoftIds = peopleIds;
        //setEndLocInformation(locInfo2);

        return locInfo2;
    }
    return null;
  };
  async function GetClosestBranches(
    reservationDt: any,
    lat: string,
    lon: string,
    typeOfLocation: number,
    availableBrands: string,
    countryCode: string = "",
    isPlaceAirport: boolean = false,
    rows = 10
  ) {
    try {
      const result = await axiosInstance.get(
        "crossdomain/GetClosestBranchesBasicByLatLong?latitude=" +
          lat +
          "&longitude=" +
          lon +
          "&numberOfBranchesToFind=10&availableBrands=" +
          availableBrands +
          "&journeyDateTime=" +
          reservationDt +
          "&journeyUId=" +
          journeyUId +
          "&isPlaceAirport=" +
          isPlaceAirport +
          "&countryCode=" +
          countryCode
      );

      if (result && result.data) {
        const data = result.data.data;
        let closestBranchList: branchType[] = [];
        if (data !== null && data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const branchDetail: branchType = {
              name: data[i].branchName,
              addressLines: data[i].fullAddress,
              calculatedDistance: data[i].calculatedDistance,
              branchDesc: data[i].branchName + ", " + data[i].fullAddress,
              country: data[i].countryIso3Code,
              peoplesoftId: data[i].peoplesoftBranchId,
              stationId: data[i].stationId,
              telephone: data[i].formattedPhoneNumber,
              brand: data[i].brand,
              brandlogo:
                data[i].brand === BrandType.ENTERPRISE
                  ? EnterpriseSmallLog
                  : NationalSmallLogo,
              isDeliveryAccepted: data[i].isDeliveryAccepted,
              isCollectionAccepted: data[i].isCollectionAccepted,
              isAirportLocation: data[i].isAirportLocation,
              airportCity: data[i].airportCity,
              groupBranchCode: data[i].branchCode,
            };

            closestBranchList.push(branchDetail);
          }
        }
        return closestBranchList;
      } else {
        appInsights.trackException({
          exception: new Error("Error"),
          properties: {
            method: "GetClosestBranchesBasicByLatLong Collection",
            Component: "ReservationLocation",
          },
          severityLevel: SeverityLevel.Error,
        });
        console.error("There was an error!", "error");
        return [];
      }
    } catch {
      appInsights.trackException({
        exception: new Error("Error"),
        properties: {
          method: "GetClosestBranchesBasicByLatLong Collection",
          Component: "ReservationLocation",
        },
        severityLevel: SeverityLevel.Error,
      });
      console.error("There was an error!", "error");
      return null;
    }
  }

  async function GetBranchOpeningHours(
    reservationDt: any,
    peopleSoftId: string,
    brandToSearchFor: string,
    typeOfLocation: number
  ) {
    const branchHourList: branchDetail[] = [];
    const result = await axiosInstance.post(
      "crossdomain/getbranchopeninghoursbydate?stationIdOrPeopleSoftId=" +
        peopleSoftId +
        "&date=" +
        reservationDt +
        "&brands=" +
        brandToSearchFor +
        "&locationType=" +
        (typeOfLocation === 1 ? "StartLocation" : "EndLocation") +
        "&journeyUId=" +
        journeyUId
    );

    if (
      result.data != null &&
      result.data.data != null &&
      result.data.data.length > 1
    ) {
      result.data.data.forEach((element) => {
        if (element !== null) {
          const branchHours = element.branchHours;
          let isClosed = branchHours.closed;
          let periods: openingPeriod[] = [];

          branchHours.openCloseTime.forEach((item) => {
            let openTime = String(item.openTime);
            let closeTime = String(item.closeTime);

            if (openTime.length < 4) openTime = openTime.padStart(4, "0");
            if (closeTime.length < 4) closeTime = closeTime.padStart(4, "0");
            periods.push({
              openingHour: moment(openTime, "HHmm").format("HH:mm"),
              closingHour: moment(closeTime, "HHmm").format("HH:mm"),
            });
          });

          branchHourList.push({
            brand: element.brand,
            stationId: element.stationId,
            peopleSoftId: element.peopleSoftId,
            openingPeriods: periods,
            closed: isClosed,
          });
        }
      });

      return branchHourList;
    } else {
      appInsights.trackException({
        exception: new Error("Error"),
        properties: {
          method: "GetClosestBranchesBasicByLatLong Collection",
          Component: "ReservationLocation",
        },
        severityLevel: SeverityLevel.Error,
      });
      console.error("There was an error!", "error");
      return [];
    }
  }
  const updateCars = (
    startLoc: ReservationLocInfoViewModel | undefined | null,
    endLoc: ReservationLocInfoViewModel | undefined | null
  ) => {
    if (
      startLoc !== undefined &&
      startLoc !== null &&
      endLoc !== undefined &&
      endLoc !== null
    ) {
      props.updateCarClass(startLoc, endLoc);
    }
  };

  async function checkDelEligibility() {
    console.log(props);
    const { data } = await isDelEligble(
      props.initialLocation?.startLocation.country
    );
    return data;
  }

  React.useEffect(() => {
    async function getInitialData() {
      setLoading(true);
      await loadInitialData();
      setLoading(false);
    }
    getInitialData();
  }, []);

  React.useEffect(() => {
    async function UpdateCarsWhenTimeChange() {
      let startlocInfo: ReservationLocInfoViewModel | null = null;
      let endlocInfo: ReservationLocInfoViewModel | null = null;
      setLoading(true);
      if (startBranchDetails !== null) {
        await showBranchOpeningHours(
          1,
          startBranchDetails,
          false,
          startJourneyMethod
        ).then((stlocInfo) => {
          setStartLocInformation(stlocInfo === null ? undefined : stlocInfo);
          startlocInfo = stlocInfo;
        });
      }

      if (endBranchDetails !== null) {
        await showBranchOpeningHours(
          2,
          endBranchDetails,
          false,
          endJourneyMethod
        ).then((etlocInfo) => {
          setEndLocInformation(etlocInfo === null ? undefined : etlocInfo);
          endlocInfo = etlocInfo;
        });
      }

      updateCars(startlocInfo, endlocInfo);
      setLoading(false);
    }

    UpdateCarsWhenTimeChange();
  }, [reservationStartDate, reservationEndDate]);

  React.useEffect(() => {
    setStartLocationView({
      ...startLocationView,
      selectedMethodTypeId: startJourneyMethod,
    });
    setEndLocationView({
      ...endLocationView,
      selectedMethodTypeId: endJourneyMethod,
    });
  }, [startJourneyMethod, endJourneyMethod]);

  React.useEffect(() => {
    //to set the focus on the grid area so that there are no double clicks required to select branch in default load
    if (isAutoGridOpened) {
      btnRef.current?.focus();
    }
  }, [isAutoGridOpened]);

  React.useEffect(() => {
    props.setlocationsView({
      startLocation: startLocationView,
      endLocation: endLocationView,
    });
    setFieldValue("locations", {
      startLocation: startLocationView,
      endLocation: endLocationView,
    });
  }, [startLocationView, endLocationView]);

  function mergeDualBrandBranchesForGrid(data: branchType[]) {
    let brandWithAirportCode: branchType[] = [];
    var branches: branchType[] = [];
    let isCombinedAlready = false;
    if (
      data !== null &&
      data.length > 0 &&
      data.filter((x) => x.isAirportLocation).length > 0
    )
      brandWithAirportCode = data.filter((x) => x.isAirportLocation);
    if (data != null && data.length > 0) {
      //combine dual brand airport locations
      if (
        brandWithAirportCode.length === 2 &&
        ((brandWithAirportCode[0].brand === BrandType.ENTERPRISE &&
          brandWithAirportCode[1].brand === BrandType.NATIONAL) ||
          (brandWithAirportCode[0].brand === BrandType.NATIONAL &&
            brandWithAirportCode[1].brand === BrandType.ENTERPRISE))
      ) {
        //Airport
        let branchAirport = brandWithAirportCode[0];
        branchAirport.peoplesoftId =
          brandWithAirportCode[0].peoplesoftId +
          "," +
          brandWithAirportCode[1].peoplesoftId;
        branchAirport.stationId =
          brandWithAirportCode[0].stationId +
          "," +
          brandWithAirportCode[1].stationId;
        branchAirport.brand =
          brandWithAirportCode[0].brand + "," + brandWithAirportCode[1].brand;
        branchAirport.brandlogo = ETZLSmallLogo;
        for (let i = 0; i < data.length; i++) {
          if (data[i].isAirportLocation && !isCombinedAlready) {
            branches.push(branchAirport);
            isCombinedAlready = true;
          }
          if (!data[i].isAirportLocation) {
            branches.push(data[i]);
          }
        }
      } else {
        //when no dual brand locations are there
        branches.push(...data);
      }
    }

    return branches;
  }

  const handleselectedMethod = (value) => {
    setSelectedRow(-1);
    setIsDirty(true);

    if (
      value === JourneyMethodType.walkIn ||
      value === JourneyMethodType.delivery
    ) {
      // processWalkInAndReturnBranches(value);
      setStartJourneyMethod(value);
      processStartMethods(value);
    } else if (
      value === JourneyMethodType.returnToBranch ||
      value === JourneyMethodType.collection
    ) {
      // processDeliveryCollectionBranches(value);
      setEndJourneyMethod(value);
      processEndMethods(value);
    }
  };

  const handleLocationSelected = async (
    row: branchType,
    index: number,
    typeOfLocation: number
  ) => {
    setSelectedRow(index);
    setIsDirty(true);

    if (typeOfLocation === 1) {
      setStartBranchDetails(row);
      setStartBranchLookup(false);
      setNoStartBranchFound(false);
      await showBranchOpeningHours(1, row, true).then(async (startlocInfo) => {
        setStartLocInformation(
          startlocInfo === null ? undefined : startlocInfo
        );

        updateLocationDetails(1, row, JourneyMethodType.walkIn);
        if (
          endJourneyMethod &&
          endJourneyMethod === JourneyMethodType.returnToBranch
        ) {
          //check if the brands are different
          let startBranchBrandList = row?.brand.split(",");
          let endBranchBrandList: string[] = [];
          if (endBranchDetails && endBranchDetails.brand.length > 0)
            endBranchBrandList = endBranchDetails.brand.split(",");

          let found = false;
          if (endBranchBrandList.length > 0) {
            found = startBranchBrandList.some((r) =>
              endBranchBrandList.includes(r)
            );
          }

          if (!found) {
            setEndBranchDetails(null);
            setEndJourneyMethod((endJourneyMethod) => JourneyMethodType.none);
            setEndBranchOpeningHours([]);
            setEndBranchLookup(false);
            setJourneyType((journeyType) => JourneyMethodType.none);
            setFieldValue("carClass", null);
            //set to false because the return to branch radio button not being set.
            props.isLocationValid(false);
          } else {
            console.log(
              "updating car classes from start branch selection: ",
              startlocInfo
            );
            updateCars(startlocInfo, endLocInformation);
          }
        } else if (
          endJourneyMethod &&
          endJourneyMethod === JourneyMethodType.collection
        ) {
          let endlocInfo = await updateCollectionBranchData(
            row,
            null,
            startJourneyMethod
          );
          updateCars(startlocInfo, endlocInfo);
        }
      });
    } else {
      setNoEndBranchFound(false);
      //just to make sure that the branch selected has the same brand as start branch
      if (
        startJourneyMethod !== JourneyMethodType.none &&
        startBranchDetails !== null
      ) {
        let startBranchBrands = startBranchDetails.brand.split(",");
        let endBranchBrands = row.brand.split(",");
        if (startBranchBrands !== null && startBranchBrands.length > 0) {
          let brandFound = false;
          if (endBranchBrands.length > 0) {
            brandFound = startBranchBrands.some((r) =>
              endBranchBrands.includes(r)
            );
          }

          /*  if (startBranchBrands !== null && startBranchBrands.length > 0) {
          let brandFound = false;

          for (let i = 0; i < startBranchBrands.length; i++) {
            if (startBranchBrands[i] === row.brand) {
              brandFound = true;
              break;
            }
          }
*/
          if (!brandFound) {
            setEndBranchDetails(null);
            setEndJourneyMethod(JourneyMethodType.none);
            setEndBranchOpeningHours([]);
            setEndBranchLookup(false);
            setFieldValue("carClass", null);
          } else {
            setEndBranchDetails(row);
            setEndBranchLookup(false);
            let endlocInfo = await showBranchOpeningHours(2, row, true);
            setEndLocInformation(endlocInfo === null ? undefined : endlocInfo);
            updateLocationDetails(2, row, JourneyMethodType.returnToBranch);
            updateCars(startLocInformation, endlocInfo);
          }
        }
      } else {
        setEndBranchDetails(row);
        setEndBranchLookup(false);
        let endlocInfo = await showBranchOpeningHours(2, row, true);
        setEndLocInformation(endlocInfo === null ? undefined : endlocInfo);

        updateLocationDetails(2, row, JourneyMethodType.returnToBranch);
      }
    }
  };

  const updateLocationDetails = (
    typeofLocation: number,
    branch: branchType,
    journeyMethod: JourneyMethodType
  ) => {
    switch (typeofLocation) {
      case 1:
        let loc = JSON.parse(JSON.stringify(startLocationView));

        loc.stationId = branch.stationId;
        loc.branchDescriptionWithBranchCode = "";
        loc.peopleSoftId = branch.peoplesoftId;
        loc.branchDescription = branch.branchDesc;
        loc.brand = branch.brand;
        loc.isDeliveryAccepted = branch.isDeliveryAccepted;
        loc.isCollectionAccepted = branch.isCollectionAccepted;
        loc.isAirportLocation = branch.isAirportLocation;
        loc.airportCity = branch.airportCity;

        loc.selectedMethodTypeId = journeyMethod;
        loc.country = branch.country;
        loc.addressLine1 = startAddress.address1;
        loc.addressLine2 = startAddress.address2;
        loc.addressLine3 = startAddress.address3;
        loc.townOrCity = startAddress.townOrCity;
        loc.postCode = startAddress.postcode;
        loc.addressLocationSearch = startAddress.addressSearch;

        setStartLocationView(loc);

        break;
      case 2:
        let loc2 = JSON.parse(JSON.stringify(endLocationView));

        loc2.stationId = branch.stationId;
        loc2.branchDescriptionWithBranchCode = "";
        loc2.peopleSoftId = branch.peoplesoftId;
        loc2.branchDescription = branch.branchDesc;
        loc2.brand = branch.brand;
        loc2.isDeliveryAccepted = branch.isDeliveryAccepted;
        loc2.isCollectionAccepted = branch.isCollectionAccepted;
        loc2.isAirportLocation = branch.isAirportLocation;
        loc2.airportCity = branch.airportCity;
        loc2.selectedMethodTypeId = journeyMethod;
        loc2.country = branch.country;
        loc2.addressLine1 = endAddress.address1;
        loc2.addressLine2 = endAddress.address2;
        loc2.addressLine3 = endAddress.address3;
        loc2.townOrCity = endAddress.townOrCity;
        loc2.postCode = endAddress.postcode;
        loc2.addressLocationSearch = endAddress.addressSearch;
        setEndLocationView(loc2);

        break;
    }
  };

  const handleClearAddress = (typeofLocation: number) => {
    if (typeofLocation === 1) {
      setStartAddress({
        ...startAddress,
        address1: "",
        address2: "",
        address3: "",
        postcode: "",
        townOrCity: "",
      });
      setStartLocationView({
        ...startLocationView,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        postCode: "",
        townOrCity: "",
      });
      setStartAddressType(startLocationDetails.addressType.items[0].value);
    } else {
      setEndAddress({
        ...endAddress,
        address1: "",
        address2: "",
        address3: "",
        postcode: "",
        townOrCity: "",
      });
      setEndLocationView({
        ...endLocationView,
        addressLine1: "",
        addressLine2: "",
        addressLine3: "",
        postCode: "",
        townOrCity: "",
      });
      setEndAddressType(endLocationDetails.addressType.items[0].value);
    }
  };
  const handleCopyAddress = () => {
    setEndAddress({
      ...endAddress,
      address1: startAddress.address1,
      address2: startAddress.address2,
      address3: startAddress.address3,
      postcode: startAddress.postcode,
      townOrCity: startAddress.townOrCity,
    });
    setEndLocationView({
      ...endLocationView,
      addressLine1: startAddress.address1,
      addressLine2: startAddress.address2,
      addressLine3: startAddress.address3,
      postCode: startAddress.postcode,
      townOrCity: startAddress.townOrCity,
    });
    setEndAddressType(startAddressType);
  };

  const updateSearchLocation = async (location, typeOfLocation) => {
    setLoading(true);
    const reservationDt = getJourneyTime(typeOfLocation);
    let result = await GetClosestBranches(
      reservationDt,
      location.latitude,
      location.longitude,
      typeOfLocation,
      getAvailableBrands(typeOfLocation),
      location.countryCode,
      nameOfPlaceIsAirport(location)
    );

    if (result !== null && result.length > 0) {
      if (typeOfLocation === 1) {
        setStartClosestBranches(mergeDualBrandBranchesForGrid(result));
        setStartAddress(location);
        if (startBranchDetails)
          updateLocationDetails(1, startBranchDetails, startJourneyMethod);
      } else {
        setEndClosestBranches(mergeDualBrandBranchesForGrid(result));
      }
      setSelectedRow(-1);
    } else {
      if (typeOfLocation === 1) {
        setStartClosestBranches([]);
        // setNoStartBranchFound(true);
      } else {
        setEndClosestBranches([]);
        // setNoEndBranchFound(true);
        console.log("No branches found");
      }
    }
    setLoading(false);
  };
  const updateAddressSearch = async (location, typeOfLocation) => {
    if (typeOfLocation === 1) {
      setStartAddress(location);
      console.log("Location received: ", location);
      setStartLocationView({
        ...startLocationView,
        addressLine1: location.address1,
        addressLine2: location.address2,
        addressLine3: location.address3,
        townOrCity: location.townOrCity,
        postCode: location.postcode,
        //addressLocationSearch: location.addressSearch,
      });
    } else {
      setEndAddress(location);
      setEndLocationView({
        ...endLocationView,
        addressLine1: location.address1,
        addressLine2: location.address2,
        addressLine3: location.address3,
        townOrCity: location.townOrCity,
        postCode: location.postcode,
        //addressLocationSearch: location.addressSearch,
      });
    }
  };

  return (
    <Grid id={"collapseReservation"} container>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Accordion
        style={{ width: "100%" }}
        defaultExpanded
        id="startLocationDetails"
      >
        <h2>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
            aria-controls="journeyStartSummary"
            id="journeyStartSummary"
            aria-label={t(
              "ReservationWizard.reservationPage.labelSectionStart"
            )}
            className={classes.heading}
          >
            {t("ReservationWizard.reservationPage.labelSectionStart")}
          </AccordionSummary>
        </h2>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel
                  component="legend"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: "0.875rem",
                  }}
                >
                  {t("journeyMethod.selectMethod")}
                </FormLabel>
                <RadioGroup
                  name={"startSelectMethod"}
                  onChange={(e) => {
                    setStartJourneyMethod(
                      e.target.value === "1"
                        ? JourneyMethodType.walkIn
                        : JourneyMethodType.delivery
                    );
                    handleselectedMethod(e.target.value);
                  }}
                  value={startJourneyMethod}
                >
                  <FormControlLabel
                    inputRef={walkinRef}
                    id={"Locations_StartLocation_SelectedMethodTypeId_WIR"}
                    value={JourneyMethodType.walkIn}
                    control={<Radio color="primary" />}
                    disabled={!startLocationDetails.showControls}
                    label={t("journeyMethod.walkIn")}
                  />
                  {isdelColEnabled && (
                    <FormControlLabel
                      id={"Locations_StartLocation_SelectedMethodTypeId_DC"}
                      value={JourneyMethodType.delivery}
                      control={<Radio color="primary" />}
                      disabled={!startLocationDetails.showControls}
                      label={t("journeyMethod.delivery")}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              hidden={!(startJourneyMethod === JourneyMethodType.walkIn)}
            >
              <Grid
                id={"StartLocationbranchLocation"}
                hidden={startBranchLookup}
                aria-label={t("StartLocation")
                  .concat(" ")
                  .concat(t("Res.BranchDetails"))}
                role="region"
                tabIndex={0}
              >
                <Grid id={"StartLocationnearestBranch"} container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      style={{ fontWeight: "bold", marginRight: "0.5em" }}
                    >
                      {t("journeyMethod.yourNearestBranchIs")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {!noStartBranchFound ? (
                      <Typography id={"branchDescriptionDisplayStartLocation"}>
                        {startBranchDetails?.branchDesc}
                      </Typography>
                    ) : (
                      <Typography
                        id={"branchErrorStartLocation"}
                        className={classes.branchError}
                      >
                        {" "}
                        {t("journeyMethod.closestBranchToStartNotFound")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid id={"StartLocationBranchOpeninghours"} container>
                  <Grid item xs={12} md={4} style={{ minWidth: "32%" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        marginTop: "0.5em",
                      }}
                    >
                      {t("journeyMethod.branchOpeningHoursMessage", {
                        ResDate:
                          reservationStartDate !== null
                            ? moment(reservationStartDate).format(dateFormat)
                            : moment().format(dateFormat),
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {startBranchOpeningHours.map((p, index) => {
                      return (
                        <div key={index}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              style={{
                                marginTop: "0.5em",
                              }}
                            >
                              <img
                                src={
                                  p.brand === "ZL"
                                    ? NationalLogo
                                    : EnterpriseLogo
                                }
                                style={{
                                  height: "1.5em",
                                  width: 75,
                                }}
                                alt={
                                  p.brand === "ZL"
                                    ? "National Branch"
                                    : "Enterprise Branch"
                                }
                              />{" "}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={9}
                              style={{
                                marginTop: "0.5em",
                              }}
                            >
                              {p.openingPeriods.length === 0 ||
                              (p.openingPeriods.length > 0 &&
                                p.openingPeriods[0].openingHour === "00:00" &&
                                p.openingPeriods[0].closingHour === "00:00") ? (
                                <Typography className={classes.branchError}>
                                  {" "}
                                  {t("journeyMethod.closedText")}{" "}
                                </Typography>
                              ) : (
                                <Typography>
                                  {getOpeningPeriodsString(p.openingPeriods)}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </Grid>
                </Grid>
                {startClosedHoursAlertMessage && (
                  <Typography
                    id={"branchErrorStartLocation"}
                    className={classes.branchError}
                  >
                    {t("journeyMethod.branchOpeningClosedHoursAlertMessage")}
                  </Typography>
                )}
                {/* hide button on  edit model.showcontrols*/}
                {startLocationDetails.showControls && (
                  <Button
                    id={"btnChangeBranchStartLocation"}
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={() => {
                      setStartBranchLookup(true);
                    }}
                    style={{
                      marginTop: "0.5em",
                      textTransform: "none",
                    }}
                  >
                    {t("journeyMethod.changeBranch")}
                  </Button>
                )}
              </Grid>
              <Grid
                id={"StartLocationbranchLookup"}
                hidden={!startBranchLookup}
              >
                <Grid item xs={12}>
                  {startLocationDetails.showControls && startBranchLookup && (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <InputLabel
                          className={classes.inputLabel}
                          htmlFor={
                            "Locations_StartLocation_BranchLocationSearch"
                          }
                        >
                          {" "}
                          {t("journeyMethod.addressLookup")}
                        </InputLabel>
                        <IconButton
                          id={"infoIconStartLocation"}
                          ref={infoRef}
                          size="small"
                          color="primary"
                          style={{ marginLeft: 0, paddingBottom: 10 }}
                          title={t("journeyMethod.addressLookupTooltip")}
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <AddressLookup
                          id={"Locations_StartLocation_BranchLocationSearch"}
                          setlocationSearch={() => console.log("")}
                          updateLocationSearch={(loc) => {
                            updateSearchLocation(loc, 1);
                          }}
                          addressSearch={
                            startLocationDetails?.addressLocationSearch
                          }
                          locationDestination={startLocationDestination}
                          isexistingRes={props.isReadOnly}
                          isAutoLoad={true}
                        ></AddressLookup>
                      </Grid>
                    </Grid>
                  )}
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      marginTop: "1em",
                    }}
                  >
                    {t("journeyMethod.pleaseSelectABranch")}
                  </Typography>
                  <Grid
                    id={"branchesGridContainerStartLocation"}
                    container
                    item
                    xs={12}
                    md={12}
                    style={{ display: "flex", width: "100%" }}
                  >
                    <TableContainer component={Paper}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell width="10%">
                              <div>
                                {" "}
                                <p style={{ fontWeight: "bold" }}>
                                  {t("journeyMethod.brand")}
                                </p>{" "}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell width="70%">
                              <div>
                                {" "}
                                <p style={{ fontWeight: "bold" }}>
                                  {t("journeyMethod.branchNameandAdress")}
                                </p>{" "}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell width="20%">
                              <div>
                                {" "}
                                <p style={{ fontWeight: "bold" }}>
                                  {t("journeyMethod.distanceFromStart")}
                                </p>{" "}
                              </div>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {startClosestBranches &&
                          startClosestBranches.length === 0 ? (
                            <TableRow tabIndex={0}>
                              <TableCell
                                colSpan={9}
                                style={{
                                  textAlign: "center",
                                  justifyContent: "center",
                                  marginTop: "2em",
                                }}
                              >
                                {t("EmptyTable")}
                              </TableCell>
                            </TableRow>
                          ) : (
                            startClosestBranches &&
                            startClosestBranches?.map(
                              (row: branchType, index: number) => (
                                <TableRow
                                  key={index}
                                  selected={selectedRow === index}
                                  onClick={() =>
                                    handleLocationSelected(row, index, 1)
                                  }
                                >
                                  <StyledTableCell
                                    style={{ borderRight: "1px solid #ddd" }}
                                  >
                                    <img
                                      src={row.brandlogo}
                                      style={{
                                        height: "2em",
                                      }}
                                      alt={
                                        row.brand === BrandType.NATIONAL
                                          ? "National Branch"
                                          : "Enterprise Branch"
                                      }
                                    />{" "}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    id={"gridHeaderStartLocation" + index}
                                    style={{ borderRight: "1px solid #ddd" }}
                                    tabIndex={0}
                                    onKeyDown={(event) => {
                                      if (event.key === "Enter") {
                                        handleLocationSelected(row, index, 1);
                                      }
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {row.name +
                                        "(" +
                                        row.groupBranchCode +
                                        ")"}
                                    </Typography>
                                    <Typography variant="body2">
                                      {row.addressLines}
                                    </Typography>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Typography variant="body2">
                                      {convertKmToMiles(
                                        row.calculatedDistance,
                                        locale
                                      )}
                                    </Typography>
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Button
                  id={"btnChangeBranchCancelStartLocation"}
                  color="primary"
                  variant="contained"
                  ref={btnRef}
                  type="button"
                  onClick={() => {
                    setStartBranchLookup(false);
                  }}
                  style={{
                    marginTop: "0.5em",
                    textTransform: "none",
                  }}
                >
                  {t("journeyMethod.btnCancel")}
                </Button>
              </Grid>{" "}
            </Grid>

            {startJourneyMethod === JourneyMethodType.delivery && (
              <>
                <Grid
                  id={"addressLocationStartLocation"}
                  aria-label={"Delivery Address"}
                  role="region"
                  item
                  xs={12}
                  md={9}
                >
                  <Grid container>
                    <Typography
                      variant="h3"
                      style={{ fontWeight: "bold", marginRight: "0.5em" }}
                      tabIndex={0}
                      className="visuallyHidden"
                    >
                      {t("journeyMethod.deliveryHeading")}
                    </Typography>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_StartLocation_AddressType"}
                      >
                        {t("journeyMethod.addressType")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <FormControl fullWidth variant="outlined">
                        <Select
                          id={"Locations_StartLocation_AddressType"}
                          native
                          value={startAddressType}
                          disabled={!startLocationDetails.showControls}
                          onChange={(event) => {
                            setStartAddressType(event.target.value as string);
                          }}
                        >
                          {startLocationDetails.addressType.items?.map(
                            (opt: any) => (
                              <option key={opt.value} value={opt.value}>
                                {t("journeyMethod.addressType" + opt.text)}
                              </option>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {startLocationDetails.showControls && (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        style={{
                          display: "flex",
                          paddingRight: "0px",
                          alignItems: "baseline",
                        }}
                        className={classes.gridItem}
                      >
                        <InputLabel
                          className={classes.inputLabel}
                          htmlFor={
                            "Locations_StartLocation_AddressLocationSearch"
                          }
                        >
                          {" "}
                          {t("journeyMethod.addressLookup")}
                        </InputLabel>
                        <IconButton
                          id={"infoIconStartLocation"}
                          ref={infoRef}
                          size="small"
                          color="primary"
                          style={{ marginLeft: 0, paddingBottom: 10 }}
                          title={t("journeyMethod.addressLookupTooltipDC")}
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} md={10} className={classes.gridItem}>
                        <AddressLookup
                          id={"Locations_StartLocation_AddressLocationSearch"}
                          setlocationSearch={() => console.log("")}
                          updateLocationSearch={(loc) => {
                            updateAddressSearch(loc, 1);
                          }}
                          addressSearch={startAddress.addressSearch}
                          locationDestination={startLocationDestination}
                          isexistingRes={props.isReadOnly}
                          isAutoLoad={false}
                        ></AddressLookup>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_StartLocation_addressLine1"}
                      >
                        {t("journeyMethod.addressLine1")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        id={"Locations_StartLocation_addressLine1"}
                        name={"locations.startLocation.addressLine1"}
                        value={startAddress?.address1}
                        // onChange={handleAddressChange(1, "address1")}
                        onChange={(e) => {
                          setStartAddress({
                            ...startAddress,
                            address1: e.target.value,
                          });
                          setStartLocationView({
                            ...startLocationView,
                            addressLine1: e.target.value,
                          });
                        }}
                        inputProps={{
                          "aria-required": "true",
                          ...(!(startAddress?.address1.length > 0) && {
                            "aria-describedby":
                              "Error_StartLocation_addressLine1",
                          }),
                        }}
                        autoComplete="address-line1"
                        disabled={!startLocationDetails.showControls}
                        variant="outlined"
                        error={
                          !(startAddress?.address1.length > 0) ? true : false
                        }
                        //  helperText={t("journeyMethod.deliveryAddress1Required")}
                      />
                      {!(startAddress?.address1.length > 0) ? (
                        <span
                          id={"Error_StartLocation_addressLine1"}
                          className={classes.errorWarning}
                        >
                          {t("journeyMethod.deliveryAddress1Required") ?? ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_StartLocation_addressLine2"}
                      >
                        {t("journeyMethod.addressLine2")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        value={startAddress?.address2}
                        // onChange={handleAddressChange(1, "address2")}
                        onChange={(e) => {
                          setStartAddress({
                            ...startAddress,
                            address2: e.target.value,
                          });
                          setStartLocationView({
                            ...startLocationView,
                            addressLine2: e.target.value,
                          });
                        }}
                        id={"Locations_StartLocation_addressLine2"}
                        variant="outlined"
                        disabled={!startLocationDetails.showControls}
                        autoComplete="address-line2"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_StartLocation_addressLine3"}
                      >
                        {t("journeyMethod.addressLine3")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        value={startAddress?.address3}
                        // onChange={handleAddressChange(1, "address3")}
                        // onChange={handleAddressChange(1, "address1")}
                        onChange={(e) => {
                          setStartAddress({
                            ...startAddress,
                            address3: e.target.value,
                          });
                          setStartLocationView({
                            ...startLocationView,
                            addressLine3: e.target.value,
                          });
                        }}
                        id={"Locations_StartLocation_addressLine3"}
                        variant="outlined"
                        disabled={!startLocationDetails.showControls}
                        autoComplete="address-line3"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_StartLocation_townOrCity"}
                      >
                        {t("journeyMethod.townOrCity")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        id={"Locations_StartLocation_townOrCity"}
                        // onChange={handleAddressChange(1, "townOrCity")}
                        // onChange={handleAddressChange(1, "address1")}
                        onChange={(e) => {
                          setStartAddress({
                            ...startAddress,
                            townOrCity: e.target.value,
                          });
                          setStartLocationView({
                            ...startLocationView,
                            townOrCity: e.target.value,
                          });
                        }}
                        value={startAddress?.townOrCity}
                        variant="outlined"
                        inputProps={{
                          "aria-required": "true",
                          ...(!(startAddress?.townOrCity.length > 0) && {
                            "aria-describedby":
                              "Locations_StartLocation_townOrCity",
                          }),
                        }}
                        autoComplete="address-level1"
                        disabled={!startLocationDetails.showControls}
                        error={
                          !(startAddress?.townOrCity.length > 0) ? true : false
                        }
                      />
                      {!(startAddress?.townOrCity.length > 0) ? (
                        <span
                          id={"Error_StartLocation_townOrCity"}
                          className={classes.errorWarning}
                        >
                          {t("journeyMethod.deliveryTownRequired") ?? ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_StartLocation_postcode"}
                      >
                        {t("journeyMethod.postcode")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        id={"Locations_StartLocation_postcode"}
                        // onChange={handleAddressChange(1, "postcode")}
                        onChange={(e) => {
                          setStartAddress({
                            ...startAddress,
                            postcode: e.target.value,
                          });
                          setStartLocationView({
                            ...startLocationView,
                            postCode: e.target.value,
                          });
                        }}
                        value={startAddress?.postcode}
                        variant="outlined"
                        required
                        inputProps={{
                          "aria-required": "true",
                          ...(!(startAddress?.postcode.length > 0) && {
                            "aria-describedby":
                              "Locations_StartLocation_postcode",
                          }),
                        }}
                        autoComplete="postal-code"
                        disabled={!startLocationDetails.showControls}
                        error={
                          !(startAddress?.postcode.length > 0) ? true : false
                        }
                      />
                      {!(startAddress?.postcode.length > 0) ? (
                        <span
                          id={"Error_StartLocation_postcode"}
                          className={classes.errorWarning}
                        >
                          {t("journeyMethod.deliveryPostRequired") ?? ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  {startLocationDetails.showControls && (
                    <Grid container justifyContent="flex-end">
                      <Button
                        id={"btnClearAddressStartLocation"}
                        color="primary"
                        variant="outlined"
                        aria-label={
                          isStartLocation
                            ? t("journeyMethod.btnClearAriaLabelStart")
                            : t("journeyMethod.btnClearAriaLabelEnd")
                        }
                        classes={{ outlinedPrimary: classes.outlinedButton }}
                        onClick={() => handleClearAddress(1)}
                        type="button"
                        style={{
                          marginTop: "0.5em",
                          textTransform: "none",
                        }}
                      >
                        {t("journeyMethod.btnClearAddress")}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{ width: "100%" }}
        defaultExpanded
        id="endLocationDetails"
      >
        <h2>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "#FFFFFF" }} />}
            aria-controls="JourneyEndSummary"
            id="JourneyEndSummary"
            aria-label={t("ReservationWizard.reservationPage.labelSectionEnd")}
            className={classes.heading}
          >
            {t("ReservationWizard.reservationPage.labelSectionEnd")}
          </AccordionSummary>
        </h2>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel
                  component="legend"
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    fontSize: "0.875rem",
                  }}
                >
                  {t("journeyMethod.selectMethod")}
                </FormLabel>
                <RadioGroup
                  name={"endSelectMethod"}
                  onChange={(e) => {
                    setEndJourneyMethod(
                      e.target.value === "3"
                        ? JourneyMethodType.returnToBranch
                        : JourneyMethodType.collection
                    );
                    handleselectedMethod(e.target.value);
                  }}
                  value={endJourneyMethod}
                >
                  <FormControlLabel
                    inputRef={walkinRef}
                    id={"Locations_EndLocation_SelectedMethodTypeId_WIR"}
                    value={JourneyMethodType.returnToBranch}
                    control={<Radio color="primary" />}
                    disabled={!endLocationDetails.showControls}
                    label={t("journeyMethod.returnToBranch")}
                  />
                  {isdelColEnabled && (
                    <FormControlLabel
                      id={"Locations_EndLocation_SelectedMethodTypeId_DC"}
                      value={JourneyMethodType.collection}
                      control={<Radio color="primary" />}
                      disabled={!endLocationDetails.showControls}
                      label={t("journeyMethod.collection")}
                    />
                  )}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={9}
              hidden={!(endJourneyMethod === JourneyMethodType.returnToBranch)}
            >
              <Grid
                id={"EndLocationbranchLocation"}
                hidden={endBranchLookup}
                aria-label={t("EndLocation")
                  .concat(" ")
                  .concat(t("Res.BranchDetails"))}
                role="region"
                tabIndex={0}
              >
                <Grid id={"EndLocationnearestBranch"} container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h3"
                      style={{ fontWeight: "bold", marginRight: "0.5em" }}
                    >
                      {t("journeyMethod.yourNearestBranchIs")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {!noEndBranchFound ? (
                      <Typography id={"branchDescriptionDisplayEndLocation"}>
                        {endBranchDetails?.branchDesc}
                      </Typography>
                    ) : (
                      <Typography
                        id={"branchErrorEndLocation"}
                        className={classes.branchError}
                      >
                        {" "}
                        {t("journeyMethod.closestBranchToEndNotFound")}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
                <Grid id={"EndLocationBranchOpeninghours"} container>
                  <Grid item xs={12} md={4} style={{ minWidth: "32%" }}>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "0.8rem",
                        marginTop: "0.5em",
                      }}
                    >
                      {t("journeyMethod.branchOpeningHoursMessage", {
                        ResDate:
                          reservationEndDate !== null
                            ? moment(reservationEndDate).format(dateFormat)
                            : moment().format(dateFormat),
                      })}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {endBranchOpeningHours.map((p, index) => {
                      return (
                        <div key={index}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={3}
                              style={{
                                marginTop: "0.5em",
                              }}
                            >
                              <img
                                src={
                                  p.brand === "ZL"
                                    ? NationalLogo
                                    : EnterpriseLogo
                                }
                                style={{
                                  height: "1.5em",
                                  width: 75,
                                }}
                                alt={
                                  p.brand === "ZL"
                                    ? "National Branch"
                                    : "Enterprise Branch"
                                }
                              />{" "}
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={9}
                              style={{
                                marginTop: "0.5em",
                              }}
                            >
                              {p.openingPeriods.length === 0 ||
                              (p.openingPeriods.length > 0 &&
                                p.openingPeriods[0].openingHour === "00:00" &&
                                p.openingPeriods[0].closingHour === "00:00") ? (
                                <Typography className={classes.branchError}>
                                  {" "}
                                  {t("journeyMethod.closedText")}{" "}
                                </Typography>
                              ) : (
                                <Typography>
                                  {getOpeningPeriodsString(p.openingPeriods)}
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                  </Grid>
                </Grid>
                {endClosedHoursAlertMessage && (
                  <Typography
                    id={"branchErrorEndLocation"}
                    className={classes.branchError}
                  >
                    {t("journeyMethod.branchOpeningClosedHoursAlertMessage")}
                  </Typography>
                )}
                {/* hide button on  edit model.showcontrols*/}
                {endLocationDetails.showControls && (
                  <Button
                    id={"btnChangeBranchEndLocation"}
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={() => {
                      setEndBranchLookup(true);
                    }}
                    style={{
                      marginTop: "0.5em",
                      textTransform: "none",
                    }}
                  >
                    {t("journeyMethod.changeBranch")}
                  </Button>
                )}
              </Grid>
              <Grid id={"EndLocationbranchLookup"} hidden={!endBranchLookup}>
                <Grid item xs={12}>
                  {endLocationDetails.showControls && endBranchLookup && (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <InputLabel
                          className={classes.inputLabel}
                          htmlFor={"Locations_EndLocation_BranchLocationSearch"}
                        >
                          {" "}
                          {t("journeyMethod.addressLookup")}
                        </InputLabel>
                        <IconButton
                          id={"infoIconEndLocation"}
                          ref={infoRef}
                          size="small"
                          color="primary"
                          style={{ marginLeft: 0, paddingBottom: 10 }}
                          title={t("journeyMethod.addressLookupTooltip")}
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <AddressLookup
                          id={"Locations_EndLocation_BranchLocationSearch"}
                          setlocationSearch={(data) => console.log(data)}
                          updateLocationSearch={(loc) => {
                            updateSearchLocation(loc, 2);
                          }}
                          addressSearch={
                            endLocationDetails.addressLocationSearch
                          }
                          locationDestination={endLocationDestination}
                          isexistingRes={props.isReadOnly}
                          isAutoLoad={true}
                        ></AddressLookup>
                      </Grid>
                    </Grid>
                  )}
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "0.8rem",
                      marginTop: "1em",
                    }}
                  >
                    {t("journeyMethod.pleaseSelectABranch")}
                  </Typography>
                  <Grid
                    id={"branchesGridContainerEndLocation"}
                    container
                    item
                    xs={12}
                    md={12}
                    style={{ display: "flex", width: "100%" }}
                  >
                    <TableContainer component={Paper}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell width="10%">
                              <div>
                                {" "}
                                <p style={{ fontWeight: "bold" }}>
                                  {t("journeyMethod.brand")}
                                </p>{" "}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell width="70%">
                              <div>
                                {" "}
                                <p style={{ fontWeight: "bold" }}>
                                  {t("journeyMethod.branchNameandAdress")}
                                </p>{" "}
                              </div>
                            </StyledTableCell>
                            <StyledTableCell width="20%">
                              <div>
                                {" "}
                                <p style={{ fontWeight: "bold" }}>
                                  {t("journeyMethod.distanceFromStart")}
                                </p>{" "}
                              </div>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {endClosestBranches &&
                          endClosestBranches.length === 0 ? (
                            <TableRow tabIndex={0}>
                              <TableCell
                                colSpan={9}
                                style={{
                                  textAlign: "center",
                                  justifyContent: "center",
                                  marginTop: "2em",
                                }}
                              >
                                {t("EmptyTable")}
                              </TableCell>
                            </TableRow>
                          ) : (
                            endClosestBranches &&
                            endClosestBranches?.map(
                              (row: branchType, index: number) => (
                                <TableRow
                                  key={index}
                                  selected={selectedRow === index}
                                  onClick={() =>
                                    handleLocationSelected(row, index, 2)
                                  }
                                >
                                  <StyledTableCell
                                    style={{ borderRight: "1px solid #ddd" }}
                                  >
                                    <img
                                      src={row.brandlogo}
                                      style={{
                                        height: "2em",
                                      }}
                                      alt={
                                        row.brand === BrandType.NATIONAL
                                          ? "National Branch"
                                          : "Enterprise Branch"
                                      }
                                    />{" "}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    id={"gridHeaderEndLocation" + index}
                                    style={{ borderRight: "1px solid #ddd" }}
                                    tabIndex={0}
                                    onKeyDown={(event) => {
                                      if (event.key === "Enter") {
                                        handleLocationSelected(row, index, 2);
                                      }
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {row.name +
                                        "(" +
                                        row.groupBranchCode +
                                        ")"}
                                    </Typography>
                                    <Typography variant="body2">
                                      {row.addressLines}
                                    </Typography>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    <Typography variant="body2">
                                      {convertKmToMiles(
                                        row.calculatedDistance,
                                        locale
                                      )}
                                    </Typography>
                                  </StyledTableCell>
                                </TableRow>
                              )
                            )
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>
                <Button
                  id={"btnChangeBranchCancelEndLocation"}
                  color="primary"
                  variant="contained"
                  type="button"
                  onClick={() => {
                    setEndBranchLookup(false);
                  }}
                  style={{
                    marginTop: "0.5em",
                    textTransform: "none",
                  }}
                >
                  {t("journeyMethod.btnCancel")}
                </Button>
              </Grid>{" "}
            </Grid>

            {endJourneyMethod === JourneyMethodType.collection && (
              <>
                <Grid
                  id={"addressLocationEndLocation"}
                  aria-label={t("journeyMethod.collectionariaLabel")}
                  role="region"
                  hidden={collectionNotAvailable}
                  item
                  xs={12}
                  md={9}
                >
                  <Grid container>
                    <Typography
                      variant="h3"
                      style={{ fontWeight: "bold", marginRight: "0.5em" }}
                      tabIndex={0}
                      className="visuallyHidden"
                    >
                      {t("journeyMethod.collectionHeading")}
                    </Typography>
                  </Grid>
                  {endLocationDetails.showControls && (
                    <Grid container justifyContent="flex-end">
                      <Button
                        id={"btnCopyAddress"}
                        color="primary"
                        variant="contained"
                        type="button"
                        aria-label={t("journeyMethod.btnCopyAriaLabel")}
                        style={{
                          marginTop: "0.5em",
                          textTransform: "none",
                        }}
                        onClick={handleCopyAddress}
                      >
                        {t("journeyMethod.btnCopyAddress")}
                      </Button>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_EndLocation_AddressType"}
                      >
                        {t("journeyMethod.addressType")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <FormControl fullWidth variant="outlined">
                        <Select
                          id={"Locations_EndLocation_AddressType"}
                          native
                          value={endAddressType}
                          disabled={!endLocationDetails.showControls}
                          onChange={(event) => {
                            setEndAddressType(event.target.value as string);
                          }}
                        >
                          {endLocationDetails.addressType.items?.map(
                            (opt: any) => (
                              <option key={opt.value} value={opt.value}>
                                {t("journeyMethod.addressType" + opt.text)}
                              </option>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {endLocationDetails.showControls && (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={2}
                        style={{
                          display: "flex",
                          paddingRight: "0px",
                          alignItems: "baseline",
                        }}
                        className={classes.gridItem}
                      >
                        <InputLabel
                          className={classes.inputLabel}
                          htmlFor={
                            "Locations_EndLocation_AddressLocationSearch"
                          }
                        >
                          {" "}
                          {t("journeyMethod.addressLookup")}
                        </InputLabel>
                        <IconButton
                          id={"infoIconEndLocation"}
                          ref={infoRef}
                          size="small"
                          color="primary"
                          style={{ marginLeft: 0, paddingBottom: 10 }}
                          title={t("journeyMethod.addressLookupTooltipDC")}
                        >
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} md={10} className={classes.gridItem}>
                        <AddressLookup
                          id={"Locations_EndLocation_AddressLocationSearch"}
                          setlocationSearch={() => console.log("")}
                          updateLocationSearch={(loc) => {
                            updateAddressSearch(loc, 2);
                          }}
                          addressSearch={endAddress.addressSearch}
                          locationDestination={endLocationDestination}
                          isexistingRes={props.isReadOnly}
                          isAutoLoad={false}
                        ></AddressLookup>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_EndLocation_addressLine1"}
                      >
                        {t("journeyMethod.addressLine1")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        id={"Locations_EndLocation_addressLine1"}
                        name={"locations.endLocation.addressLine1"}
                        value={endAddress?.address1}
                        //onChange={handleAddressChange(2, "address1")}
                        onChange={(e) => {
                          setEndAddress({
                            ...endAddress,
                            address1: e.target.value,
                          });
                          setEndLocationView({
                            ...endLocationView,
                            addressLine1: e.target.value,
                          });
                        }}
                        autoComplete="address-line1"
                        inputProps={{
                          "aria-required": "true",
                          ...(!(endAddress?.address1.length > 0) && {
                            "aria-describedby":
                              "Error_EndLocation_addressLine1",
                          }),
                        }}
                        disabled={!endLocationDetails.showControls}
                        variant="outlined"
                        error={!(endAddress?.address1.length > 0)}
                      />
                      {!(endAddress?.address1.length > 0) ? (
                        <span
                          id={"Error_EndLocation_addressLine1"}
                          className={classes.errorWarning}
                        >
                          {t("journeyMethod.collectionAddress1Required") ?? ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_EndLocation_addressLine2"}
                      >
                        {t("journeyMethod.addressLine2")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        value={endAddress?.address2}
                        // onChange={handleAddressChange(2, "address2")}
                        onChange={(e) => {
                          setEndAddress({
                            ...endAddress,
                            address2: e.target.value,
                          });
                          setEndLocationView({
                            ...endLocationView,
                            addressLine2: e.target.value,
                          });
                        }}
                        id={"Locations_EndLocation_addressLine2"}
                        variant="outlined"
                        disabled={!endLocationDetails.showControls}
                        autoComplete="address-line2"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_EndLocation_addressLine3"}
                      >
                        {t("journeyMethod.addressLine3")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        value={endAddress?.address3}
                        // onChange={handleAddressChange(2, "address3")}
                        onChange={(e) => {
                          setEndAddress({
                            ...endAddress,
                            address3: e.target.value,
                          });
                          setEndLocationView({
                            ...endLocationView,
                            addressLine3: e.target.value,
                          });
                        }}
                        id={"Locations_EndLocation_addressLine3"}
                        variant="outlined"
                        disabled={!endLocationDetails.showControls}
                        autoComplete="address-line3"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_EndLocation_townOrCity"}
                      >
                        {t("journeyMethod.townOrCity")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        id={"Locations_EndLocation_townOrCity"}
                        //onChange={handleAddressChange(2, "townOrCity")}
                        onChange={(e) => {
                          setEndAddress({
                            ...endAddress,
                            townOrCity: e.target.value,
                          });
                          setEndLocationView({
                            ...endLocationView,
                            townOrCity: e.target.value,
                          });
                        }}
                        value={endAddress?.townOrCity}
                        variant="outlined"
                        inputProps={{
                          "aria-required": "true",
                          ...(!(endAddress?.townOrCity.length > 0) && {
                            "aria-describedby":
                              "Locations_EndLocation_townOrCity",
                          }),
                        }}
                        autoComplete="address-level1"
                        disabled={!endLocationDetails.showControls}
                        error={
                          !(endAddress?.townOrCity.length > 0) ? true : false
                        }
                      />
                      {!(endAddress?.townOrCity.length > 0) ? (
                        <span
                          id={"Error_EndLocation_townOrCity"}
                          className={classes.errorWarning}
                        >
                          {t("journeyMethod.collectionTownRequired") ?? ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} md={2} className={classes.gridItem}>
                      <InputLabel
                        className={classes.inputLabel}
                        htmlFor={"Locations_EndLocation_postcode"}
                      >
                        {t("journeyMethod.postcode")}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} className={classes.gridItem}>
                      <TextField
                        fullWidth
                        id={"Locations_EndLocation_postcode"}
                        //onChange={handleAddressChange(2, "postcode")}
                        onChange={(e) => {
                          setEndAddress({
                            ...endAddress,
                            postcode: e.target.value,
                          });
                          setEndLocationView({
                            ...endLocationView,
                            postCode: e.target.value,
                          });
                        }}
                        value={endAddress?.postcode}
                        variant="outlined"
                        required
                        inputProps={{
                          "aria-required": "true",
                          ...(!(endAddress?.postcode.length > 0) && {
                            "aria-describedby":
                              "Locations_EndLocation_postcode",
                          }),
                        }}
                        autoComplete="postal-code"
                        disabled={!endLocationDetails.showControls}
                        error={
                          !(endAddress?.postcode.length > 0) ? true : false
                        }
                      />
                      {!(endAddress?.postcode.length > 0) ? (
                        <span
                          id={"Error_EndLocation_postcode"}
                          className={classes.errorWarning}
                        >
                          {t("journeyMethod.collectionPostRequired") ?? ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                  {endLocationDetails.showControls && (
                    <Grid container justifyContent="flex-end">
                      <Button
                        id={"btnClearAddressEndLocation"}
                        color="primary"
                        variant="outlined"
                        aria-label={t("journeyMethod.btnClearAriaLabelEnd")}
                        classes={{ outlinedPrimary: classes.outlinedButton }}
                        onClick={() => handleClearAddress(2)}
                        type="button"
                        style={{
                          marginTop: "0.5em",
                          textTransform: "none",
                        }}
                      >
                        {t("journeyMethod.btnClearAddress")}
                      </Button>
                    </Grid>
                  )}
                </Grid>

                <Grid
                  id={"CollectionNotAvailableForNationalMsg"}
                  aria-label={"Collection Not Available"}
                  role="region"
                  hidden={!collectionNotAvailable}
                  item
                  xs={12}
                  md={9}
                >
                  <Typography
                    variant="h3"
                    style={{ fontWeight: "bold", marginRight: "0.5em" }}
                    tabIndex={0}
                  >
                    {t("journeyMethod.collectionNotAvailableForNationalTitle")}
                  </Typography>
                  <Typography style={{ marginTop: "1em" }}>
                    {t(
                      "journeyMethod.collectionNotAvailableForNationalMessage"
                    )}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}
