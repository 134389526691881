import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Button
} from "@mui/material";
import { ReservationProps } from "../../reservationType";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import { useDispatch, useSelector } from "react-redux";

import "react-datepicker/dist/react-datepicker.css";
import makeStyles from "@mui/styles/makeStyles";
import CompactJourneyDetailsView from "../Shared/compactJourneyDetailsView";

import {
  clearAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "components/store/actions/shared/alertActions";


import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: "0.875rem",
    fontWeight: "bold",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
  textarea: {
    resize: "both",
  },
  focused: { outline: "none" },
}));


const PaymentForm: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const titleRef = React.useRef<HTMLDivElement>(null);
  registerLocale("de", de);
  registerLocale("es", es);
  registerLocale("fr", fr);
  useEffect(() => {
    dispatch(clearAlertMessage());
  }, []);
  return (
    <Box
      boxShadow={3}
      p={3}
      bgcolor="background.paper"
      style={{ width: "100%" }}
    >
      <Grid container>
        <div ref={titleRef} tabIndex={-1} className={classes.focused}>
          <Typography
            id = "paymentPageTitle"
            variant="h1"
            style={{
              marginTop: 0,
              marginBottom: "15px",
            }}
          >
            {t("ReservationWizard.paymentPageTitle")}
          </Typography>
        </div>
        <CompactJourneyDetailsView
          journeyUId={"00-00"}
          journeyDetails={props.journeyDetails}
        />
      </Grid>
      <div style={{marginTop:10}}>
        <span id="lblRequiedFields" style={{ fontWeight: "bold", color: "#000000" }}>
          {t("lblRequiedFields")}
        </span>
        </div>
      <div id="lblSecureConnection" style={{textAlign:"center", marginTop:10}}> 
        {t("lblSecureConnection")}
      </div>
      <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1em",
                    }}
                  >
                    <Button
                      id="btnBack"
                      color="primary"
                      aria-label={t("lblBackToPreviousStep")}
                      variant="contained"
                      onClick={() => {
                        dispatch(clearAlertMessage());
                        if (props.handleBack) props.handleBack(0, 0);
                      }}
                    >
                      {t("btnBack")}
                    </Button>
                    <Button
                      id="btnSubmit"
                      color="primary"
                      variant="contained"
                      aria-label={t("lblContinueToNextStep")}
                      onClick={() => {
                        dispatch(clearAlertMessage());
                        if (props.handleNext) props.handleNext(0, 0);
                      }}
                    >
                      {t("btnContinue")}
                    </Button>
                   
                  </div>
                   
    </Box>
  );
};
export default PaymentForm;

