import React, { useState } from "react";

import { Route, Routes } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import { routes } from "./routes";
import { Paper } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Box } from "@mui/material";
import theme from "./theme";
import { ThemeProvider, Theme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import AuthRoute from "helpers/authRoute";
import { authService } from "services/authService";
import Layout from "./components/home/layout";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { GetCompanyInfo } from "components/store/actions/home/companyInfoActions";
import { useDispatch } from "react-redux";
import { appInsights } from "helpers/appInsights";
import { browsersLanugage } from "helpers/localization";
import Home from "views/home/home";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);

  const { instance } = useMsal();
  const { i18n } = useTranslation(["home"]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    authService.setAuthInProgress(false);

    if (!authService.isAuthenticated()) {
      i18n.changeLanguage(browsersLanugage());
      instance.handleRedirectPromise().then((res) => {
        if (res !== null) {
          authService.setAuthInProgress("true");
          console.log("Access TOken: ", res.accessToken);
          authService.setAuthInProgress(false);

          authService.authenticate(res.accessToken).then((result) => {
            authService.setAuthInProgress(false);
            if (result) {
              sessionStorage.setItem(
                "account",
                JSON.stringify(instance.getAllAccounts()[0])
              );
              sessionStorage.setItem("idToken", res.idToken);
              setIsAuthenticated(true);
              appInsights.trackEvent({
                name: "Login Successful",
                properties: {
                  userId: authService.getUserName(),
                  companyId: authService.getUserCompanyInternalId(),
                },
              });
            } else {
              const logoutRequest = {
                idTokenHint: res.idToken,
              };
              instance.logoutRedirect(logoutRequest);
            }
          });
        }
      });
    } else {
      setIsAuthenticated(true);
      i18n.changeLanguage(authService.getUserLocale() ?? "en-GB");
      dispatch(GetCompanyInfo());
      document.documentElement.setAttribute(
        "lang",
        authService.getUserLocale()
      );
    }

  }, [isAuthenticated]);



  return (
    <Container>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Paper elevation={3}>
              <HashRouter>
                <Layout>
                  <Box>
                    <Routes>
                      {routes.map((route, i) =>
                        route.needsAuth ? (
                          <Route
                            path={route.path}
                            key={i}
                            element={
                              <AuthRoute
                                path={route.path}
                                requiredRoles={route.requiredRoles || []}
                                key={i}
                              >
                                {route.component}
                              </AuthRoute>
                            }
                          />
                        ) : (
                          <Route
                            path={route.path}
                            element={route.component}
                            key={i}
                          />
                        )
                      )}
                    </Routes>
                  </Box>
                </Layout>
              </HashRouter>
            </Paper>
          </ThemeProvider>
    </Container>
);
};

export default App;
