import Header from "./header";
import Footer from "./footer";
import AlertMessage from "./alert";
import Announcement from "./announcement";
import { Box } from "@mui/material";
type Props = {
  children: JSX.Element;

};

export default function Layout(
  { children }: Props
 ) {
  return (
    <>
      <Header/>
      <AlertMessage />
      <Announcement />
      <Box
        role="main"
        id="main"
        px={1}
        style={{ minHeight: window.innerHeight / 2 }}
      >
        {children}
      </Box>
      <Footer />
    </>
  );
}
