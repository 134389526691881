import React, { useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import {
  Box,
  CardContent,
  Typography,
  FormControl,
  Select,
  Backdrop,
  CircularProgress,
  CardMedia,
  Card,
  CardActionArea,
  Checkbox,
  TextField,
} from "@mui/material";

import {
  JourneyStartModel,
  myJourneyIntitalValue,
  DriverDeclaration,
} from "./journeyInputType";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import {
  showSuccessAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
  clearAlertMessage,
} from "components/store/actions/shared/alertActions";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";

import EtdLocationLookup from "../../../../controls/formik/etdLocationLookup";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import EtdTimePicker from "../../../../controls/formik/EtdTimePicker";
import axiosInstance from "..//..//../../helpers/axiosInstance";
import { authService } from "../../../../services/authService";
import { useNavigate } from "react-router-dom";
import { resetReservation } from "components/store/actions/reservation/reservationActions";
import moment from "moment";
import DRProduct from "Assets/Images/DRProduct.png";
import SVProduct from "Assets/Images/SVProduct.png";
import AccessibleTooltip from "controls/AccessibleTooltip";
import {
  getDateObjectFromShortString,
  getIntlDateFormatForLocale,
} from "helpers/localization";
import DriverAutoComplete from "controls/formik/EtdDriverLookup";
import { CheckSharp } from "@mui/icons-material";
import { AppState } from "components/store/reducers/rootReducer";
import { resetSpecialityRequest } from "components/store/actions/reservation/specialityActions";
import {
  getUserPermissionsStatus,
  Permissions,
} from "helpers/getUserPermissions";

import { updateJourneyProfileDetails } from "components/store/slicers/journeyInputSlice";
import { resetDriverDetails } from "components/store/slicers/driverDetailSlice";
import { resetBookingReferenceDetails } from "components/store/slicers/bookingReferenceSlice";
import { resetAccountLocationDetails } from "components/store/slicers/accountLocationSlice";
import { HashLink as Link } from "react-router-hash-link";
const JourneyInputForm = (props): JSX.Element => {
  const [loading, setLoading] = React.useState(false);
  const [isSpeciality, setIsSpeciality] = React.useState(false);
  const [submitting, setFormSubmitting] = React.useState(false);
  const { companyInfo } = useSelector(
    (state: AppState) => state.companyInfo || {}
  );
  const [journeyInput, setjourneyInput] = React.useState<JourneyStartModel>(
    myJourneyIntitalValue
  );
  const [endDate, setEndDate] = React.useState("");
  const [primaryDriver, setPrimaryDriver] = React.useState<DriverDeclaration>({
    driverDeclarationDetailId: 0,
    driverDeclarationId: 0,
    companyInternalNumber: 0,
    firstName: "",
    lastName: "",
    emailAddress: "",
    driverDeclarationExpiryDate: new Date(),
    driverDeclarationStatusId: 0,
    driverDeclarationStatus: "",
  });
  const [additionalDriver, setAdditionalDriver] = React.useState<
    DriverDeclaration[]
  >([
    {
      driverDeclarationDetailId: 0,
      driverDeclarationId: 0,
      companyInternalNumber: 0,
      firstName: "",
      lastName: "",
      emailAddress: "",
      driverDeclarationExpiryDate: new Date(),
      driverDeclarationStatusId: 0,
      driverDeclarationStatus: "",
    },
  ]);
  const [enableMidPoints, setEnableMidPoints] = React.useState(true);
  const [isExistingReservation, setIsExistingReservation] =
    React.useState(false);
  const [enableAddLocationButton, setEnableAddLocationButton] =
    React.useState(true);
  const locale = authService.getUserLocale();
  const [isRebookLoad, setisRebookLoad] = React.useState(false);
  const defaultLocation = {
    brand: "",
    latitude: "",
    location: "",
    longitude: "",
    peopleSoftId: "",
    stationId: "",
  };
  const [enableAdditionalDriver, setEnableAdditionalDriver] =
    React.useState(true);
  const [enableAdditionalDriverBtn, setEnableAdditionalDriverBtn] =
    React.useState(true);
  const defaultAdditionalDriver = {
    driverDeclarationDetailId: 0,
    driverDeclarationId: 0,
    companyInternalNumber: 0,
    firstName: "",
    lastName: "",
    emailAddress: "",
    driverDeclarationExpiryDate: new Date(),
    driverDeclarationStatusId: 0,
    driverDeclarationStatus: "",
  };
  const btnAddLocRef = React.useRef<HTMLButtonElement>(null);
  const btnAddDriver = React.useRef<HTMLButtonElement>(null);
  const localeDateTimeFormat = getIntlDateFormatForLocale(locale);
  /// TODO:

  // const [modeOfTransportUId, setmodeOfTransportUId] = React.useState("");

  const titleRef = React.useRef<HTMLDivElement>(null);
  const titleDPRef = React.useRef<HTMLDivElement>(null);
  const companyCountry = authService.getUserCompanyCountryCode();
  const hasUserViewOnlyPermission = getUserPermissionsStatus(
    authService.getUserPermissions(),
    Permissions.ViewOnly
  );
  const formikRef = React.useRef<any>(null);
  const registrationNumberRules = /^[a-zA-Z0-9- ]*$/;

  const modOfTransportRegNumber = [
    "8ed341de-f166-4716-887c-71685c12c89a",
    "a03d60fe-d845-496f-88da-dc1eee23a3b3",
    "b29e9cd5-d489-4eb2-aa05-922787136865",
  ];
  const [oid, setOid] = React.useState("");

  const dispatch = useDispatch();
  let history = useNavigate();

  const [endDateChangedDirectly, setEndDateChangedDirectly] =
    React.useState(false);
  const { t } = useTranslation();

  var companyCountryCode = authService.getUserCompanyCountryCode();

  function validateLocation(value) {
    if (value && value["latitude"] && value["latitude"].length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function validateDateTime(dt, tm, isPastAllowed = false) {
    if (tm && tm.length > 1) {
      //if (!isPastAllowed && tm && tm.length > 1) {
      let startDateTime = moment(dt + "T" + tm, "YYYY-MM-DDTHH:mm:ss");
      if (startDateTime <= moment().subtract(2, "days")) return false;
    }

    return true;
  }
  const getTimeValue = (dt) => {
    let result = "0";
    if (dt) {
      let date = new Date(dt);
      var hours = ("0" + date.getHours()).slice(-2);
      var mins = ("0" + date.getMinutes()).slice(-2);
      var secs = "00";
      result = hours + ":" + mins + ":" + secs;
    }

    return result;
  };
  const validateSchema = Yup.object().shape({
    startLocation: Yup.object()
      .required("journeyInput.msgInvalidStartLocation")
      .test(
        "Start Location is valid",
        "journeyInput.msgInvalidStartLocation",
        (value) => validateLocation(value)
      ),

    endLocation: Yup.object()
      .required("journeyInput.msgInvalidEndLocation")
      .test(
        "End Location is valid",
        "journeyInput.msgInvalidEndLocation",
        (value) => validateLocation(value)
      ),

    viaPoints: Yup.array().when("isInternationalStartLocation", {
      is: false,
      then: Yup.array().of(
        Yup.object()
          .required("journeyInput.msgInvalidMidLocation")
          .test(
            "Mid Location is valid",
            "journeyInput.msgInvalidMidLocation",
            (value) => validateLocation(value)
          )
      ),
    }),

    startDate: Yup.string()
      .nullable()
      .required("journeyInput.msgInvalidStartDate")
      .test("valid-Date", "journeyInput.msgInvalidStartDate", function (value) {
        if (value?.length === 10) {
          const isvalid = moment(value, "YYYY-MM-DD").isValid();

          return isvalid;
        }
        return false;
      })
      .test(
        "date-in-future",
        "journeyInput.msgDateExceedMaxLimit",
        function (value) {
          let startDateTime = moment(value, "YYYY-MM-DDTHH:mm:ss");
          if (startDateTime > moment("2050-12-31T23:59:59")) return false;
          return true;
        }
      )
      // .test("start date in past Test", "startDateTimeError", function (value) {
      //   console.log("pppzzz this.parent.isJourneyProfile && !isExistingReservation="+ (this.parent.isJourneyProfile && !isExistingReservation))
      //   return validateDateTime(
      //     this.parent.startDate,
      //     this.parent.startTime,
      //     this.parent.isJourneyProfile && !isExistingReservation
      //   );
      // })
      .when(["isJourneyProfile"], {
        is: (isJourneyProfile) => isJourneyProfile === false,
        then: Yup.string().test(
          "past-date-noallowed",
          "journeyInput.msgDateCanOnlyBeUpToOneDayPast",
          function (value) {
            let startDateTime = moment(value, "YYYY-MM-DDTHH:mm:ss");
            if (startDateTime < moment().subtract(2, "day")) return false;
            return true;
          }
        ),
      }),

    endDate: Yup.string()
      .nullable()
      .required("journeyInput.msgInvalidEndDate")
      .test("valid-Date", "journeyInput.msgInvalidEndDate", function (value) {
        if (value?.length === 10) return moment(value, "YYYY-MM-DD").isValid();
        return false;
      })
      .test(
        "date-in-future",
        "journeyInput.msgDateExceedMaxLimit",
        function (value) {
          let startDateTime = moment(value, "YYYY-MM-DDTHH:mm:ss");
          if (startDateTime > moment("2050-12-31T23:59:59")) return false;
          return true;
        }
      )
      .test(
        "end date cannot be before start date ",
        "ERR20014",
        function (value) {
          let stDate = this.parent.startDate;
          let stTime = this.parent.startTime;
          let edTime = this.parent.endTime;
          if (
            stDate &&
            stTime &&
            edTime &&
            stTime.length > 1 &&
            edTime.length > 1
          ) {
            let startDateTime = moment(stDate + "T" + stTime);
            let endDateTime = moment(
              value + "T" + edTime,
              "YYYY-MM-DDTHH:mm:ss"
            );
            if (startDateTime >= endDateTime) {
              dispatch(broadcastAnnouncement(""));
              setTimeout(() => {
                dispatch(broadcastAnnouncement(t("ERR20014")));
              }, 100);
              return false;
            }
          }
          return true;
        }
      )
      .when(["isJourneyProfile"], {
        is: (isJourneyProfile) => isJourneyProfile === false,
        then: Yup.string().test(
          "past-date-noallowed",
          "journeyInput.msgDateCanOnlyBeUpToOneDayPast",
          function (value) {
            let endDateTime = moment(value, "YYYY-MM-DDTHH:mm:ss");
            if (endDateTime < moment().subtract(2, "day")) return false;
            return true;
          }
        ),
      })
      .when(["startDate", "startTime", "endTime"], {
        is: (startDate, startTime, endTime) => {
          if (
            startDate &&
            startTime &&
            endTime &&
            startDate.length > 1 &&
            startTime.length > 1 &&
            endTime.length > 1
          )
            return true;
          return false;
        },
        then: Yup.string().test(
          "enddata cannot be less than startdate",
          "journeyInput.msgStartDateAfterEndDate",
          function (value) {
            if (value && value?.toString().length > 1) {
              let sdt = moment(
                this.parent.startDate + "T" + this.parent.startTime,
                "YYYY-MM-DDTHH:mm:ss"
              );

              let edt = moment(
                value + "T" + this.parent.endTime,
                "YYYY-MM-DDTHH:mm:ss"
              );

              if (sdt > edt) return false;
            }
            return true;
          }
        ),
      }),

    startTime: Yup.string()
      .nullable()
      .required(t("journeyInput.msgInvalidStartTime"))
      .test("startTime-required", "Required", function (value) {
        return value !== null && value !== undefined && value.length > 1;
      }),

    endTime: Yup.string()
      .nullable()
      .test("endTime-required", "Required", function (value) {
        return value !== null && value !== undefined && value.length > 1;
      }),

    availableModesOfTransport: Yup.mixed().when(
      ["modeOfTransportUId", "isJourneyProfile"],
      {
        is: (modeOfTransportUId, isJourneyProfile) => {
          return isJourneyProfile;
        },
        then: Yup.object()
          .test("modOfTransport-required", "Required", function (value) {
            return (
              this.parent.modeOfTransportUId !== null &&
              this.parent.modeOfTransportUId !== undefined &&
              this.parent.modeOfTransportUId.toString().length > 0
            );
          })
          .nullable(),
      }
    ),

    registrationNumber: Yup.string().when(
      ["modeOfTransportUId", "isJourneyProfile"],
      {
        is: (modeOfTransportUId, isJourneyProfile) => {
          if (!isJourneyProfile) return false;
          if (
            modeOfTransportUId === "8ed341de-f166-4716-887c-71685c12c89a" || // company car
            modeOfTransportUId === "a03d60fe-d845-496f-88da-dc1eee23a3b3" || //own vehicle
            modeOfTransportUId === "b29e9cd5-d489-4eb2-aa05-922787136865"
          )
            //pool car
            return true;
          else return false;
        },
        then: Yup.string()
          .nullable()
          .required("Required")
          .max(10, t("max10registrationNumber"))
          .matches(registrationNumberRules, {
            message: t("journeyInput.invalidOption"),
          }),
      }
    ),

    primaryDriverDeclaration: Yup.object().when(["isDriverValidityEnabled"], {
      is: () => companyInfo?.isDriverValidityActivated === true,
      then: Yup.object()
        .required("journeyInput.msgInvalidPrimaryDriverDeclaration")
        .test(
          "testDriverDeclarationExpiryDate",
          "journeyInput.msgInvalidPrimaryDriverDeclaration",
          function () {
            var result =
              companyInfo?.isDriverValidityActivated &&
              this.parent.endDate &&
              this.parent.primaryDriverDeclaration &&
              this.parent.primaryDriverDeclaration.driverDeclarationStatus.toLowerCase() ===
                "accepted" &&
              this.parent.primaryDriverDeclaration
                .driverDeclarationExpiryDate >= this.parent.endDate;
            if (result) {
              dispatch(broadcastAnnouncement(""));
              setTimeout(() => {
                dispatch(broadcastAnnouncement(t("journeyInput.validOption1")));
              }, 100);
            } else {
              if (
                companyInfo?.isDriverValidityActivated &&
                this.parent.primaryDriverDeclaration
              ) {
                dispatch(broadcastAnnouncement(""));
                setTimeout(() => {
                  dispatch(
                    broadcastAnnouncement(
                      t("journeyInput.invalidOption1") +
                        " " +
                        t("journeyInput.msgInvalidPrimaryDriverDeclaration")
                    )
                  );
                }, 100);
              }
            }
            return result;
          }
        ),
    }),

    additionalDriver: Yup.array()
      .when("isAdditionalDriverEnabled", {
        is: () => companyInfo?.isAdditionalDriverActivated === true,
        then: Yup.array().of(
          Yup.object()
            .nullable()
            .notRequired()
            .test(
              "testAdditionalDriverExpiryDate",
              "journeyInput.msgInvalidAdditionalDriver",
              (value) => {
                if (value !== null) {
                  if (
                    value["firstName"] !== undefined &&
                    value["firstName"] !== ""
                  ) {
                    let driverExpiryDate = new Date(
                      value["driverDeclarationExpiryDate"]
                    );
                    let reservationEndDate = new Date(endDate);
                    if (driverExpiryDate < reservationEndDate) {
                      return false;
                    }
                  }
                  return true;
                }
                return true;
              }
            )
            .test(
              "testAdditionalDriverSameAsPrimaryDriver",
              "journeyInput.msgDuplicateAdditionalDriver",
              (value) => {
                if (value !== null) {
                  if (
                    value["driverDeclarationId"] ===
                    primaryDriver.driverDeclarationId
                  ) {
                    return false;
                  } else return true;
                }
                return true;
              }
            )
            .test(
              "AdditionalDriverEmpty",
              "journeyInput.msgInvalidAdditionalDriver",
              (value) => {
                if (
                  value &&
                  value !== null &&
                  value.firstName !== "" &&
                  value.firstName !== undefined
                ) {
                  return true;
                }
                return false;
              }
            )
            .test(
              "AdditionalDriverDuplicated",
              "journeyInput.msgDuplicateAdditionalDriver",
              function (value) {
                if (
                  this.parent != null &&
                  this.parent.length > 1 &&
                  value?.firstName !== undefined
                ) {
                  let valueArr = this.parent.map(function (item) {
                    return item?.driverDeclarationDetailId;
                  });
                  let isDuplicate = valueArr.some(function (item, idx) {
                    return valueArr.indexOf(item) !== idx;
                  });
                  if (isDuplicate) {
                    return false;
                  } else {
                    return true;
                  }
                }
                return true;
              }
            )
        ),
      })
      .when("isAdditionalDriverEnabled", {
        is: () => companyInfo?.isAdditionalDriverActivated === true,
        then: Yup.array().max(
          29,
          t("journeyInput.msgAdditionalDriversReachLimit")
        ),
      }),
  });

  const useStyles = makeStyles(() => ({
    root: {
      "& .alignItemCentre": {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
      },
      "& .topLeftZeroPadding": {
        paddingLeft: 0,
        paddingTop: 0,
      },
      "& .alignItemCentreWithH2Font": {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        fontVariant: "h2",
      },
      "& .themeheader": {
        borderRight: "1px solid #ddd",
        display: "flex",
      },
      "& .checkBoxBlue": {
        color: "blue",
        fontWeight: 18,
      },
      "& .RedWarningFont": {
        color: "#DF0000",
        marginTop: "15px",
        fontSize: 12,
      },
      "& .LargeTitle": {
        fontSize: 20,
        fontWeight: "bold",
      },
      "& .MidTitle": {
        fontSize: 16,
      },
      "& .NewJourneyTitle": {
        fontSize: "30",
      },
      "& .LargeTitleHR": {
        border: "none",
        //  border: none,
        height: 2,
        backgroundColor: "#111111",
      },
      "& .MidTitleHR": {
        border: "none",
        //  border: none,
        height: 1,
        backgroundColor: "#111111",
      },
      "& .MuiSvgIcon-fontSizeSmall": {
        fontSize: "1rem",
      },
      "& .MuiTypography-body1": {
        fontSize: "0.85rem",
      },
      "& input:disabled": {
        color: "#666",
      },
      "& .alignItemsNextToEachother": {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      },
    },
    skipToContentLink: {
      background: "#006639",
      color: "#fff",
      border: "1px solid #fff",
      height: "30px",
      left: "50%",
      padding: "8px",
      position: "absolute",
      transform: "translateY(-100%)",
      transition: "transform 0.3s",
      "&:focus": {
        transform: "translateY(5%)",
      },
    },
    backdrop: {
      zIndex: 99999,
      color: "#fff",
    },
    errorWarning: {
      color: "#DF0000",
      fontSize: "0.75rem",
      marginLeft: "15px",
    },
    labelText: {
      color: "#000",
      fontFamily: "Arial, Roboto, Segoe UI",
      fontSize: "0.80rem",
      fontWeight: "bold",
      verticalAlign: "middle",
    },
    disabledAddDriverButton: {
      color: "#767676",
    },
    list: {
      border: "1px solid gray",
    },
    text: {
      fontWeight: "bold",
      fontFamily: "courier",
      color: "blue",
      backgroundColor: "orange",
    },
    activeCardClass: {
      border: "3px solid #006639",

      "& .MuiCardMedia-img": {
        objectFit: "contain",
      },
      "& .MuiCardActionArea-root": {
        textAlign: "center",
      },
      "& .MuiCardContent-root": {
        padding: "0px",
      },
      "& .MuiTypography-body1": {
        fontWeight: "bold",
      },
      ":hover": {
        boxShadow: 20, // theme.shadows[20]
      },
      maxHeight: 75,
      width: "100%",
    },
    inActiveCardClass: {
      "& .MuiCardMedia-img": {
        objectFit: "contain",
      },
      "& .MuiCardActionArea-root": {
        textAlign: "center",
      },
      "& .MuiCardContent-root": {
        padding: "0px",
      },
      ":hover": {
        boxShadow: 20, // theme.shadows[20]
      },
      maxHeight: 75,
      width: "100%",
    },
  }));
  const classes = useStyles();

  const getJourneyDetails = (id: string, isStartAgain: string = "0") => {
    setLoading(true);
    // ClearInput();
    let isExisting = id !== null && id !== undefined && id.length > 0;
    let url = isExisting
      ? `journeyassessment/bookagain/${id}?isstartagain=${isStartAgain === "1"}`
      : `journeyassessment/newjourney`;
    setisRebookLoad(isExisting);
    axiosInstance({
      method: "get",
      url: url,
    }).then((response) => {
      //console.log(" loadCompanyProfile=>   ", response.data);
      console.log("TakoApiEnv=" + authService.getTakoApiEnv());

      //Translate availableModesOfTransport to user preferred language
      response.data.availableModesOfTransport[1].text = t(
        "availableModesOfTransport.Bicycle"
      );
      response.data.availableModesOfTransport[2].text = t(
        "availableModesOfTransport.Bus"
      );
      response.data.availableModesOfTransport[3].text = t(
        "availableModesOfTransport.CarClub"
      );
      response.data.availableModesOfTransport[4].text = t(
        "availableModesOfTransport.CompanyCar"
      );
      response.data.availableModesOfTransport[5].text = t(
        "availableModesOfTransport.OwnVehicle"
      );
      response.data.availableModesOfTransport[6].text = t(
        "availableModesOfTransport.Plane"
      );
      response.data.availableModesOfTransport[7].text = t(
        "availableModesOfTransport.PoolCar"
      );
      response.data.availableModesOfTransport[8].text = t(
        "availableModesOfTransport.Taxi"
      );
      response.data.availableModesOfTransport[9].text = t(
        "availableModesOfTransport.Train"
      );
      response.data.availableModesOfTransport[10].text = t(
        "availableModesOfTransport.VehicleHire"
      );

      setEnableMidPoints(true);
      let journeyDetails = response.data ? response.data : {};
      journeyDetails.registrationNumber =
        journeyDetails.registrationNumber ?? "";
      journeyDetails.startTime = "12:00:00";
      journeyDetails.endTime = "12:00:00";

      setIsSpeciality(journeyDetails.isSpecialityVehicle);
      if (journeyDetails.viaPoints.length === 0) {
        if (
          (journeyDetails.isDailyRentalSingleProduct &&
            !journeyDetails.isMidpointsEnabled) ||
          (isExisting && journeyDetails.isJourneyProfile) ||
          journeyDetails.isSpecialityVehicle
        ) {
          setEnableMidPoints(false);
        } else {
          let defaultMidPoint = [
            { destination: "", location: "", latitude: "", longitude: "" },
          ];
          if (journeyDetails.useStartAsEndLocation)
            // on add blank mid point if flag true for existing res
            journeyDetails.viaPoints = defaultMidPoint;
        }
      }
      if (isExisting) {
        setOid(journeyDetails.originalJourneyProfilerUId);

        if (isStartAgain === "1") {
          journeyDetails.useOwnVehicle = false; //reset the flag for MR
          let stDate = moment(
            journeyDetails.startDateTime,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("YYYY-MM-DD");
          //  setStartDate(stDate);
          journeyDetails.startDate = stDate;
          let eDate = moment(
            journeyDetails.endDateTime,
            "YYYY-MM-DDTHH:mm:ss"
          ).format("YYYY-MM-DD");
          // setEndDate(eDate);
          journeyDetails.endDate = eDate;
          journeyDetails.startTime = getTimeValue(journeyDetails.startDateTime);
          journeyDetails.endTime = getTimeValue(journeyDetails.endDateTime);
          if (companyInfo?.isDriverValidityActivated) {
            let existingDriverInfo = authService.getDriverInfo();
            console.log("get existing DriverInfo: ", existingDriverInfo);
            journeyDetails.primaryDriverDeclaration =
              existingDriverInfo?.primaryDriver;
            journeyDetails.additionalDriver =
              existingDriverInfo?.additionalDriver;
          }
        }
      } else {
        authService.resetDriverInfo();
        authService.resetReservationDriver();
      }
      if (
        (journeyDetails.isDailyRentalSingleProduct &&
          !journeyDetails.isMidpointsEnabled) ||
        journeyDetails.isSpecialityVehicle
      ) {
        setEnableMidPoints(false);
        setEnableAddLocationButton(false);
      }
      setjourneyInput(journeyDetails);

      setLoading(false);
      //the following code is to enable submit button in case of start again if all values are correct
      if (isStartAgain === "1") {
        setTimeout(() => {
          if (formikRef.current) {
            formikRef.current.setFieldValue(
              "registrationNumber",
              formikRef.current.values.registrationNumber + " "
            );
            formikRef.current.setFieldTouched("registrationNumber", true);
          }
        }, 1000);
      }
    });
  };

  function hasValidMidPoints(viaPoints) {
    if (viaPoints && viaPoints.length > 0) {
      for (let i = 0; i < viaPoints.length; i++) {
        if (viaPoints[i].latitude !== null && viaPoints[i].latitude.length > 1)
          return true;
      }
    }
    return false;
  }

  function handleAddMidLocation(viaPoints) {
    let newmidPoints = [
      ...viaPoints,
      { location: "", destination: "", latitude: "", longitude: "" },
    ];
    setEnableMidPoints(true);
    if (newmidPoints.length > 5) {
      dispatch(broadcastAnnouncement(t("Maxminum5MidLocationAllowed")));
      dispatch(showInfoAlertMessage(t("Maxminum5MidLocationAllowed")));
      window.scroll(0, 0);
    } else {
      if (formikRef.current) {
        formikRef.current.setFieldValue("viaPoints", newmidPoints);
        formikRef.current.setFieldTouched("viaPoints", true);
      }
    }
  }

  function deleteMidLocation(viaPoints, id) {
    let newmidPoints = _.clone(viaPoints);
    newmidPoints.splice(id, 1);
    formikRef.current.setFieldValue("viaPoints", newmidPoints);

    if (newmidPoints.length <= 5) {
      dispatch(clearAlertMessage());
    }
    if (id === viaPoints.length - 1) {
      //if its last midpoint to be deleted then set focus on add button
      if (btnAddLocRef.current) btnAddLocRef.current.focus();
    }
  }

  function updateMidPoint(viaPoints, index, location) {
    let newmidPoints = _.clone(viaPoints);
    newmidPoints[index] = location;
    newmidPoints[index].destination = location.location;
    // formikRef.current.values.viaPoints = newmidPoints;
    formikRef.current.setFieldValue("viaPoints", newmidPoints);
  }

  function handleaddAdditionalDriverList(additionalDriver) {
    if (additionalDriver) {
      //additionalDriverList Exist
      let newAdditionalDriverList = [
        ...additionalDriver,
        {
          firstName: "",
          lastName: "",
          emailAddress: "",
          driverDeclarationExpiryDate: new Date(),
        },
      ];
      setEnableAdditionalDriver(true);
      if (newAdditionalDriverList.length > 29) {
        dispatch(
          broadcastAnnouncement(
            t("journeyInput.msgAdditionalDriversReachLimit")
          )
        );
        dispatch(
          showInfoAlertMessage(t("journeyInput.msgAdditionalDriversReachLimit"))
        );
        window.scroll(0, 0);
      } else {
        if (formikRef.current) {
          formikRef.current.setFieldValue(
            "additionalDriver",
            newAdditionalDriverList
          );
          //formikRef.current.setFieldTouched("additionalDriver", true);
        }
      }
    } else {
      //additionalDriverList doesn't Exist
      let newAdditionalDriverList = [
        {
          firstName: "",
          lastName: "",
          emailAddress: "",
          driverDeclarationExpiryDate: new Date(),
        },
      ];
      setEnableAdditionalDriver(true);
      formikRef.current.setFieldValue(
        "additionalDriver",
        newAdditionalDriverList
      );
      //formikRef.current.setFieldTouched("additionalDriver", true);
    }
  }

  function handleaddAdditionalDriver(
    currentAdditionalDriver,
    newAdditionalDriver
  ) {
    let newadditionalDriverList;
    if (currentAdditionalDriver === undefined) {
      newadditionalDriverList = [newAdditionalDriver];
      formikRef.current.setFieldValue(
        "additionalDriver",
        newadditionalDriverList
      );
    } else {
      newadditionalDriverList = [
        {
          firstName: "",
          lastName: "",
          emailAddress: "",
          driverDeclarationExpiryDate: new Date(),
        },
      ];
      for (let i = 0; i < currentAdditionalDriver.length; i++) {
        newadditionalDriverList[i] = currentAdditionalDriver[i];
      }
      newadditionalDriverList[currentAdditionalDriver.length] =
        newAdditionalDriver;
      formikRef.current.setFieldValue(
        "additionalDriver",
        newadditionalDriverList
      );
    }
    setEnableAdditionalDriver(true);
    setAdditionalDriver(newadditionalDriverList);
  }

  function deleteAdditionalDriver(additionalDriver, touched, id) {
    let newAdditionalDriverList = _.clone(additionalDriver);
    newAdditionalDriverList.splice(id, 1);
    if (touched.additionalDriver && touched.additionalDriver[id]) {
      touched.additionalDriver[id] = false;
    }
    formikRef.current.setFieldValue(
      "additionalDriver",
      newAdditionalDriverList
    );
    setAdditionalDriver(newAdditionalDriverList);
    if (newAdditionalDriverList.length === 0) {
      setEnableAdditionalDriver(false);
      if (touched.additionalDriver) {
        delete touched.additionalDriver;
      }
    }
  }

  const handleModesOfTransportChange = (event, data, setFieldValue) => {
    //setmodeOfTransportUId(event.target.value);
    setFieldValue("modeOfTransportUId", event.target.value);
  };

  const handleBookHire = (values: JourneyStartModel) => {
    setIsSpeciality(false);
    values.isSpecialityVehicle = false;
    if (values.isDailyRentalSingleProduct && !values.isMidpointsEnabled) {
      setEnableMidPoints(false);
      setEnableAddLocationButton(false);
    } else {
      setEnableMidPoints(true);
      if (values.viaPoints.length === 0 && values.useStartAsEndLocation) {
        handleAddMidLocation(values.viaPoints);
      }
      setEnableAddLocationButton(true);
    }
  };
  const handleSpecialityVehicle = (values: JourneyStartModel) => {
    setIsSpeciality(true);
    setEnableMidPoints(false);
    setEnableAddLocationButton(false);
    values.isSpecialityVehicle = true;
    values.viaPoints = [];
    formikRef.current && formikRef.current.setFieldValue("viaPoints", []);
  };

  useEffect(() => {
    setIsExistingReservation(
      props.id !== null && props.id !== undefined && props.id.length > 0
    );

    getJourneyDetails(props.id, props.isStartAgain);
  }, [props.id]);

  function locationEquals(loc1, loc2) {
    if (loc1.longitude && loc1.latitude && loc2.longitude && loc2.latitude) {
      return (
        loc1.latitude === loc2.latitude && loc1.longitude === loc2.longitude
      );
    } else return false;
  }
  function validateMidLocations(values) {
    let isvalid = false;
    //if no midpoints and start end are same
    if (values.viaPoints.length === 0 && !values.useStartAsEndLocation) {
      isvalid = !locationEquals(values.startLocation, values.endLocation);

      return [isvalid, -1];
    }
    // check if midpoint not mandatory/enabled
    if (
      values.isInternationalStartLocation ||
      (values.isDailyRentalSingleProduct && !values.isMidpointsEnabled) ||
      values.isSpecialityVehicle
    )
      return [true, -1];
    //validate first midpoint with start location
    isvalid =
      validateLocation(values.viaPoints[0]) &&
      !locationEquals(values.startLocation, values.viaPoints[0]);
    if (!isvalid) return [false, 0];

    //validate last midpoint with end location
    isvalid =
      validateLocation(values.viaPoints[values.viaPoints.length - 1]) &&
      !locationEquals(
        values.endLocation,
        values.viaPoints[values.viaPoints.length - 1]
      );
    if (!isvalid) return [false, values.viaPoints.length - 1];
    //validate midpoints between themselves
    for (let i = 0; i < values.viaPoints.length - 1; i++) {
      isvalid =
        validateLocation(values.viaPoints[i]) &&
        !locationEquals(values.viaPoints[i + 1], values.viaPoints[i]);

      if (!isvalid) return [false, i];
    }
    return [true, -1];
  }

  function checkPrimaryDriverDeclarationAvailability(errors, touched) {
    if (
      (Object.keys(errors).length === 0 && Object.keys(touched).length === 0) || //no input, no touched
      (Object.keys(errors).length === 0 &&
        Object.keys(touched).length === 1 &&
        touched.hasOwnProperty("viaPoints")) || //only viaPoints touched, exception (yup test doesn't run)
      (Object.keys(errors).length > 1 &&
        !errors.hasOwnProperty("additionalDriver")) //other fileds have input but invalid, errors.hasOwnProperty('primaryDriverDeclaration')=true and errors.hasOwnProperty('additionalDriver')=true
    ) {
      return true;
    } else if (
      (Object.keys(errors).length === 1 &&
        errors.hasOwnProperty("primaryDriverDeclaration")) || //only primaryDriverDeclaration invalid
      (Object.keys(errors).length === 2 &&
        errors.hasOwnProperty("primaryDriverDeclaration") &&
        errors.additionalDriver === "") || //only primaryDriverDeclaration invalid and additional driver is set
      (Object.keys(errors).length === 1 &&
        errors.hasOwnProperty("additionalDriver")) ||
      (Object.keys(errors).length === 0 &&
        touched.hasOwnProperty("primaryDriverDeclaration")) //all fields touched and valid
    ) {
      return false;
    } else if (
      Object.keys(errors).length > 0 &&
      !errors.hasOwnProperty("primaryDriverDeclaration") //primaryDriverDeclaration valid, other fields invalid
    ) {
      //formikRef.current.setFieldValue("primaryDriverDeclaration", "");
      return true;
    }
  }

  function disableAdditionalDriverButton(values, errors, touched) {
    if (
      checkPrimaryDriverDeclarationAvailability(errors, touched) ||
      (errors.hasOwnProperty("primaryDriverDeclaration") &&
        !values.additionalDriver) ||
      (errors.hasOwnProperty("primaryDriverDeclaration") &&
        values.additionalDriver &&
        values.additionalDriver.length === 0)
    ) {
      return true;
    }
    return false;
  }

  function isAdditionalDriverDuplicated(
    additionalDriver,
    newAdditionalDriver,
    index,
    errors
  ) {
    if (additionalDriver) {
      for (let i = 0; i < additionalDriver.length; i++) {
        if (
          additionalDriver[i] &&
          additionalDriver[i] !== null &&
          newAdditionalDriver !== null
        ) {
          if (
            additionalDriver[i] &&
            additionalDriver[i].driverDeclarationId &&
            additionalDriver[i].driverDeclarationId ===
              newAdditionalDriver.driverDeclarationId &&
            i !== index
          ) {
            //console.log("Driver already in the list <!>");
            errors.additionalDriver = t(
              "journeyInput.msgDuplicateAdditionalDriver"
            );
            return true;
          } else if (
            additionalDriver[i] &&
            additionalDriver[i].driverDeclarationId &&
            additionalDriver[i].driverDeclarationId ===
              primaryDriver.driverDeclarationId &&
            i === index
          ) {
            //console.log("Driver == primaryDriver <!>");
            errors.additionalDriver = t(
              "journeyInput.msgDuplicateAdditionalDriver"
            );
            return true;
          }
        }
      }
    }
    return false;
  }

  function additionalDriverIsSet(additionalDriverList, errors) {
    if (additionalDriverList) {
      for (let i = 0; i < additionalDriverList.length; i++) {
        if (additionalDriverList[i]) {
          if (
            isAdditionalDriverDuplicated(
              additionalDriverList,
              additionalDriverList[i],
              i,
              errors
            ) ||
            invalidAdditionalDriverList(additionalDriverList, endDate)
          ) {
            return true;
          }
          if (
            additionalDriverList[i].firstName === undefined ||
            additionalDriverList[i].firstName === ""
          ) {
            return false;
          }
        } else if (additionalDriverList[i] === null) {
          return false;
        }
      }
      return true;
    }
  }

  function invalidAdditionalDriverList(additionalDriverList, endDate) {
    if (additionalDriverList) {
      for (let i = 0; i < additionalDriverList.length; i++) {
        if (additionalDriverList[i]) {
          if (
            additionalDriverList[i].firstName !== undefined &&
            additionalDriverList[i].firstName !== ""
          ) {
            let driverExpiryDate = new Date(
              additionalDriverList[i].driverDeclarationExpiryDate
                .toString()
                .substring(0, 10)
            );
            let reservationEndDate = new Date(endDate);
            if (driverExpiryDate < reservationEndDate) {
              return true;
            }
          }
        }
      }
      return false;
    } else return false;
  }

  function transformValues(values) {
    if (values.startDate && values.startTime) {
      values.startDateTime = values.startDate + "T" + values.startTime;
    }
    if (values.endDate && values.endTime) {
      values.endDateTime = values.endDate + "T" + values.endTime;
    }
    //to remove space added in Ln: 678 as it causes unwanted Registration number entry in Journeyprofiles table
    if (props.isStartAgain === "1")
      values.registrationNumber = values.registrationNumber.trimEnd();
    return values;
  }

  function invalidAdditionalDriver(aD, values, index, errors, touched) {
    if (
      errors &&
      errors.additionalDriver &&
      errors.additionalDriver[index] ===
        t("journeyInput.msgInvalidAdditionalDriver")
    ) {
      return true;
    }
    if (
      aD &&
      (aD === null || aD.firstName === "") &&
      touched.additionalDriver &&
      touched.additionalDriver[index] &&
      touched.additionalDriver &&
      touched.additionalDriver[index] === true
    ) {
      errors.additionalDriver = t("journeyInput.msgInvalidAdditionalDriver");
      return true;
    }
    if (
      aD &&
      isAdditionalDriverValid(aD.driverDeclarationExpiryDate, values.endDate) &&
      touched.additionalDriver &&
      touched.additionalDriver[index] === true
    ) {
      //ExparyDate < endDate
      //console.log("<additionalDriverNoLongerValid>");
      errors.additionalDriver = t("journeyInput.msgInvalidAdditionalDriver");
      return true;
    } else if (
      isAdditionalDriverDuplicated(
        values.additionalDriver,
        aD,
        index,
        errors
      ) &&
      touched.additionalDriver &&
      touched.additionalDriver[index] === true
    ) {
      //driver already Exist or == to primaryDriver
      //console.log("<additionalDriver-ALREADY-IN-THE-LIST>");
      return true;
    }
    return false;
  }

  function isAdditionalDriverValid(driverDeclarationExpiryDate, endDate) {
    if (driverDeclarationExpiryDate) {
      let driverExpiryDate = new Date(
        driverDeclarationExpiryDate.toString().substring(0, 10)
      );
      let reservationEndDate = new Date(endDate);

      if (driverExpiryDate >= reservationEndDate) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  }

  function customValidation(values) {
    let errors: any = {};

    const [isValid, index] = validateMidLocations(values);
    if (!isValid) {
      //end location duplicate error
      if (index === -1) errors.endLocation = t("ERR20015");

      const size = values.viaPoints.length;
      errors.viaPoints = [];
      for (let i = 0; i < size; i++) {
        errors.viaPoints.push(i === index ? t("ERR20002") : null);
      }
    }
    if (hasUserViewOnlyPermission) {
      errors.viewOnly = t("ViewOnlyError");
    }

    let hasErrors = Object.keys(errors).length > 0;
    console.log("hasErrors: ", hasErrors, errors);
    return [hasErrors, errors];
  }
  return (
    <Card>
      <CardContent className={classes.root}>
        {loading ? (
          <>
            <div style={{ textAlign: "center" }}>{t("pleaseWait")}</div>
            <Backdrop className={classes.backdrop} open={loading}>
              <CircularProgress />{" "}
            </Backdrop>
          </>
        ) : (
          <Formik
            enableReinitialize={true}
            initialValues={journeyInput}
            validateOnChange={true}
            validateOnBlur={false}
            validationSchema={validateSchema}
            // validate={validateForm}

            innerRef={formikRef}
            onSubmit={async (
              values,
              { setSubmitting, setErrors, setStatus }
            ) => {
              // setLoading(true);
              setFormSubmitting(true);
              const [hasErrors, errors] = customValidation(values);
              if (hasErrors) {
                setErrors(errors);
                let valErrors: string[] = [];
                if (Object.keys(errors).length > 0) {
                  for (const [key, value] of Object.entries(errors)) {
                    let er: any = value;
                    if (typeof er === "string") valErrors.push(er);
                    else if (key === "viaPoints") {
                      let viaPointError = er.find((x) => x !== null);
                      if (viaPointError && viaPointError.length > 0)
                        valErrors.push(viaPointError);
                    }
                  }
                }
                dispatch(showErrorAlertMessage(valErrors));
                dispatch(broadcastAnnouncement(valErrors.join(". ")));
                window.scrollTo(0, 0);
                setLoading(false);
                setFormSubmitting(false);
              } else {
                //check JP and international
                if (
                  values.isJourneyProfile &&
                  values.isInternationalStartLocation
                ) {
                  dispatch(
                    broadcastAnnouncement(
                      t("InternationalJourneyProfileNotAllowed")
                    )
                  );
                  dispatch(
                    showErrorAlertMessage(
                      t("InternationalJourneyProfileNotAllowed")
                    )
                  );
                  setLoading(false);
                  setFormSubmitting(false);
                  window.scrollTo(0, 0);
                  return;
                }
                if (values.isSpecialityVehicle) {
                  dispatch(resetSpecialityRequest());
                }
                // process to server
                let data = transformValues(values);
                console.log("Submitted data: ", data);
                dispatch(updateJourneyProfileDetails(data));
                if (companyInfo?.isDriverValidityActivated) {
                  let drivers = {
                    primaryDriver: values.primaryDriverDeclaration,
                    additionalDriver: values.additionalDriver,
                    isBOBOToggleEnabledAndIsDriverDeclaration:
                      companyInfo?.isDriverValidityActivated,
                  };
                  authService.setDriverInfo(drivers);
                }
                axiosInstance
                  .post("journeyassessment/create", data)
                  .then((result) => {
                    console.log("Result data: ", result.data);
                    dispatch(clearAlertMessage());
                    let mode = values.isSpecialityVehicle
                      ? 4
                      : values.useOwnVehicle
                      ? 1
                      : values.isInternationalStartLocation ||
                        values.isDailyRentalSingleProduct
                      ? 3
                      : 2;

                    const baseURL =
                      "/reservationwizard?mode=" +
                      mode +
                      "&id=" +
                      result.data.id;

                    if (!values.isJourneyProfile && values.activeTravelPolicy) {
                      if (
                        mode === 1 &&
                        isRebookLoad &&
                        props.isStartAgain !== "1"
                      )
                        history(baseURL + "&oid=" + oid);
                      else history(baseURL);
                      //history(baseURL);
                    } else {
                      history("/");
                      dispatch(
                        showSuccessAlertMessage(t("Res.JourneyCreated"))
                      );
                      dispatch(broadcastAnnouncement(t("Res.JourneyCreated")));

                      window.scrollTo(0, 0);
                      setFormSubmitting(false);
                      setLoading(false);
                    }
                    dispatch(resetReservation());
                    dispatch(resetDriverDetails());
                    dispatch(resetBookingReferenceDetails());
                    dispatch(resetAccountLocationDetails());
                  })
                  .catch((error) => {
                    setFormSubmitting(false);
                    setLoading(false);
                    appInsights.trackException({
                      id: "CreateJourney",
                      exception: error,
                      severityLevel: SeverityLevel.Error,
                    });
                    if (error.response) {
                      appInsights.trackException({
                        id: "CreateJourney",
                        exception: error.response,
                        severityLevel: SeverityLevel.Error,
                      });
                    } else if (error.request) {
                      console.log("error.request", error.request);
                    } else if (error.message) {
                      console.log("error.message", error.message);
                    }
                    if (
                      error.response &&
                      error.response.data &&
                      error.response.data.errorMessage &&
                      error.response.data.errorMessage.indexOf(
                        "DailyRentalNearestBranch"
                      ) !== -1
                    ) {
                      var errormsg =
                        error.response.data.errorMessage.split("_")[0];
                      var radius =
                        error.response.data.errorMessage.split("_")[1];
                      dispatch(
                        showErrorAlertMessage(t(errormsg, { Radius: radius }))
                      );
                      dispatch(
                        broadcastAnnouncement(t(errormsg, { Radius: radius }))
                      );
                    } else {
                      dispatch(showErrorAlertMessage(t("ERR20003")));
                      dispatch(broadcastAnnouncement(t("ERR20003")));
                    }
                    window.scrollTo(0, 0);
                  });
              }
            }}
          >
            {({
              values,
              isValid,
              dirty,
              touched,
              setFieldValue,
              setFieldTouched,
              setFieldError,
              handleChange,
              errors,
              isSubmitting,
              validateForm,
            }) => (
              <Form data-testid="journeyInput">
                <Grid container spacing={1}>
                  <Backdrop
                    className={classes.backdrop}
                    open={submitting || loading}
                  >
                    <CircularProgress />{" "}
                  </Backdrop>

                  {!values.isDailyRentalSingleProduct ? (
                    <Grid style={{ paddingBottom: 10 }} item xs={12}>
                      <div
                        ref={titleRef}
                        tabIndex={-1}
                        className="visuallyHidden"
                      >
                        {t("NewJourney")}
                      </div>
                      <div>
                        <Typography
                          variant="h1"
                          onClick={() => {
                            console.log(
                              "values",
                              values,
                              "validateSchema",
                              validateSchema,
                              "errors",
                              errors,
                              "touched",
                              touched
                            );
                          }}
                        >
                          {" "}
                          {t("NewJourney")}
                        </Typography>
                      </div>
                    </Grid>
                  ) : (
                    <Grid style={{ paddingBottom: 10 }} item xs={12}>
                      <div>
                        <Typography className="LargeTitle">
                          {" "}
                          {t("WelcomeToEnterpriseTravelDirect")}{" "}
                        </Typography>
                        <hr className="LargeTitleHR"></hr>
                      </div>
                      {!companyInfo?.isSpecialityVehicleActive && (
                        <>
                          <div
                            ref={titleDPRef}
                            tabIndex={-1}
                            className="visuallyHidden"
                          >
                            {t("DailyRentalDirectPathJourneyTitle")}
                          </div>
                          <div>
                            <Typography className="MidTitle">
                              {" "}
                              {t("DailyRentalDirectPathJourneyTitle")}{" "}
                            </Typography>
                            <hr className="MidTitleHR"></hr>
                          </div>{" "}
                        </>
                      )}
                    </Grid>
                  )}
                  {companyInfo?.isSpecialityVehicleActive && (
                    <Grid
                      container
                      direction="row"
                      style={{
                        display: "flex",
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        <Card
                          raised={!isSpeciality}
                          className={
                            !isSpeciality
                              ? classes.activeCardClass
                              : classes.inActiveCardClass
                          }
                          onClick={() => {
                            handleBookHire(values);
                            setFieldTouched("viaPoints", true);
                          }}
                        >
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="50"
                              image={DRProduct}
                              alt="DR"
                            />
                            <CardContent>
                              <Typography>{t("SV.btnDRMRCC")}</Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        style={{ display: "flex", textAlign: "start" }}
                      >
                        <Card
                          className={
                            isSpeciality
                              ? classes.activeCardClass
                              : classes.inActiveCardClass
                          }
                          raised={isSpeciality}
                          onClick={() => {
                            handleSpecialityVehicle(values);
                          }}
                        >
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="50"
                              image={SVProduct}
                              alt="SV"
                            />
                            <CardContent>
                              <Typography>{t("SV.btnSpeciality")}</Typography>
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} className="alignItemsNextToEachother">
                    <div className="alignItemCentre">
                      <label 
                        data-testid="startLocation"
                        htmlFor={"startLocation"}
                        className={classes.labelText} 
                      >
                        {t("Start")}
                      </label>
                      <AccessibleTooltip text={t("SelectLocation")}>
                        <span
                          role="note"
                          style={{
                            marginTop: 11,
                            marginBottom: 11,
                            display: "inline-grid",
                          }}
                        >
                          <div>
                            <IconButton
                              id="infoIconStart"
                              size="small"
                              color="primary"
                              style={{ paddingBottom: 8 }}
                              aria-label={t("SelectLocation")}
                            >
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </span>
                      </AccessibleTooltip>
                    </div>
                    <div className="alignItemCentreWithH2Font">
                      <Field
                        inputProps={{
                          "aria-label": t("ReturnToStartLocation"),
                        }}
                        style={{
                          color: "#006639",
                          backgroundColor: "#FFFFFF",
                          paddingRight: 2,
                        }}
                        component={Checkbox}
                        size="small"
                        type="checkbox"
                        name="useStartAsEndLocation"
                        id="useStartAsEndLocation"
                        checked={values.useStartAsEndLocation}
                        onClick={(cb) => {
                          const isChecked = cb.target.checked;
                          setFieldValue("useStartAsEndLocation", isChecked);
                          if (!isChecked) {
                            //check if no viapoints selected so far
                            if (!hasValidMidPoints(values.viaPoints)) {
                              setFieldValue("viaPoints", []);
                            }

                            setFieldValue("endLocation", {
                              location: "",
                              peopleSoftId: "",
                              stationId: "",
                              latitude: "",
                              longitude: "",
                              brand: "",
                              countryCode: "",
                            });
                          } else {
                            //set start location as end loc
                            setFieldValue("endLocation", values.startLocation);
                            if (
                              (values.isDailyRentalSingleProduct &&
                                !values.isMidpointsEnabled) ||
                              values.isSpecialityVehicle
                            ) {
                              setEnableMidPoints(false);
                              setEnableAddLocationButton(false);
                            } else {
                              setEnableMidPoints(
                                hasValidMidPoints(values.viaPoints) || isChecked
                              );
                              if (values.viaPoints.length === 0) {
                                handleAddMidLocation(values.viaPoints);
                              }
                              setEnableAddLocationButton(true);
                            }
                            setFieldTouched("viaPoints", true);
                          }
                        }}
                      />
                      <Typography variant="body2">
                        {t("ReturnToStartLocation")}
                      </Typography>
                      <AccessibleTooltip text={t("ReturnToStartToolTip")}>
                        <span
                          role="note"
                          style={{
                            marginTop: 11,
                            marginBottom: 11,
                            display: "inline-grid",
                          }}
                        >
                          <div>
                            <IconButton
                              id="infoIconReturnToStart"
                              size="small"
                              color="primary"
                              style={{
                                marginLeft: 0,
                                paddingBottom: 8,
                                paddingLeft: 2,
                              }}
                              aria-label={t("ReturnToStartToolTip")}
                            >
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </span>
                      </AccessibleTooltip>
                    </div>
                  </Grid>
                  <Grid
                    style={{ paddingBottom: 0, paddingTop: 0, marginLeft: 0 }}
                    item
                    xs={12}
                  >
                    <Field
                      name="startLocation"
                      id="startLocation"
                      placeholder={t("StartLocationPostcodeAddress")}
                      defaultValue={
                        values &&
                        values.startLocation &&
                        values.startLocation.location !== null
                          ? values.startLocation
                          : defaultLocation
                      }
                      fullWidth
                      isReadOnly={false}
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      label={{ label: "Start  Location" }}
                      inputprops={{
                        "aria-label": t("startLocation"),
                        "aria-required": true,
                        "data-val-required": "Required",
                        "auto-complete": "false",
                      }}
                      component={EtdLocationLookup}
                      onInputChange={(val) => {
                        setFieldTouched("startLocation", true, true);
                      }}
                      setlocationSearch={(loc) => {
                        // setFieldTouched("startLocation", true);
                        // setFieldValue("startLocation", loc);
                        console.log("Set Start Location called");
                        const isInternational =
                          loc.countryCode !== companyCountry;
                        setFieldValue(
                          "isInternationalStartLocation",
                          isInternational
                        );
                        if (isInternational) {
                          setFieldValue("viaPoints", []);
                        } else {
                          if (
                            values.useStartAsEndLocation &&
                            values.viaPoints.length === 0 &&
                            !values.isSpecialityVehicle &&
                            (!values.isDailyRentalSingleProduct ||
                              values.isMidpointsEnabled)
                          ) {
                            handleAddMidLocation(values.viaPoints);
                          }
                        }
                        if (values.useStartAsEndLocation) {
                          setFieldValue("endLocation", loc);
                        }
                        setFieldTouched("startLocation", true);
                        setFieldValue("startLocation", loc);
                      }}
                      error={touched.startLocation && errors.startLocation}
                      aria-describedby="locationErrorMsg_startLocation"
                      aria-labelledby="start"
                    />
                    {touched.startLocation && errors && errors.startLocation ? (
                      <span
                        className={classes.errorWarning}
                        id="locationErrorMsg_startLocation"
                      >
                        {t(errors.startLocation.toString())}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  {!values.isInternationalStartLocation && enableMidPoints && (
                    <>
                      {values.viaPoints && values.viaPoints.length > 0 && (
                        <Grid item xs={12}>
                          <Box fontWeight="fontWeightBold">
                            <div className="alignItemCentreWithH2Font">
                              <label
                                data-testid="custom-midLocation1"
                                htmlFor={"midLocation1"}
                                className={classes.labelText}
                              > 
                                {t("ViaPointsLabel")}
                              </label>
                              <AccessibleTooltip text={t("SelectLocation")}>
                                <span
                                  role="note"
                                  style={{
                                    marginTop: 11,
                                    marginBottom: 11,
                                    display: "inline-grid",
                                  }}
                                >
                                  <div>
                                    <IconButton
                                      id="infoIconWhereAreYouGoing"
                                      size="small"
                                      color="primary"
                                      style={{ paddingBottom: 8 }}
                                      aria-label={t("SelectLocation")}
                                    >
                                      <InfoIcon fontSize="small" />
                                    </IconButton>
                                  </div>
                                </span>
                              </AccessibleTooltip>
                            </div>
                          </Box>
                          {values.viaPoints.map((location, index) => (
                            <div key={index}>
                              <Grid container>
                                <Grid
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  item
                                  xs={10}
                                >
                                  <Field
                                    name={`viaPoints.${index}`}
                                    id={"midLocation" + (index + 1)}
                                    placeholder={t(
                                      "MidLocationPostcodeAddress"
                                    )}
                                    //     countrycode={companyCountryCode}
                                    defaultValue={
                                      location.destination
                                        ? location
                                        : defaultLocation
                                    }
                                    //     locationInput={location.destination}
                                    //    addressSearch={location}
                                    fullWidth
                                    inputprops={{
                                      "aria-label": t("midLocation"),
                                      "aria-required": true,
                                      "data-val-required": "Required",
                                    }}
                                    //  label={{
                                    //    label: location.destination + index,
                                    //  }}
                                    component={EtdLocationLookup}
                                    isReadOnly={false}
                                    setlocationSearch={(loc) => {
                                      updateMidPoint(
                                        values.viaPoints,
                                        index,
                                        loc
                                      );
                                      setFieldTouched(
                                        `viaPoints.${index}`,
                                        true
                                      );
                                    }}
                                    onInputChange={(val) => {
                                      setFieldTouched(
                                        `viaPoints.${index}`,
                                        true
                                      );
                                    }}
                                    aria-describedby={
                                      "midLocationErrorMsg" + index
                                    }
                                    error={
                                      errors &&
                                      errors.viaPoints &&
                                      errors.viaPoints[index] &&
                                      touched &&
                                      touched.viaPoints &&
                                      touched.viaPoints[index]
                                        ? true
                                        : false
                                    }
                                  ></Field>
                                  {errors &&
                                  errors.viaPoints &&
                                  errors.viaPoints[index] &&
                                  touched &&
                                  touched.viaPoints &&
                                  touched.viaPoints[index] ? (
                                    <span
                                      className={classes.errorWarning}
                                      id={
                                        "locationErrorMsg_midLocation" +
                                        (index + 1)
                                      }
                                    >
                                      {t(errors.viaPoints[index].toString())}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Grid>

                                <Grid item xs={2} md={2} lg={2}>
                                  {index === 0 &&
                                  values.useStartAsEndLocation ? (
                                    ""
                                  ) : (
                                    <div>
                                      <AccessibleTooltip
                                        text={t("deleteLocation")}
                                      >
                                        <span
                                          style={{
                                            marginTop: 11,
                                            marginBottom: 11,
                                            display: "inline-grid",
                                          }}
                                        >
                                          <div>
                                            <IconButton
                                              id={"infoIconDelete" + index}
                                              aria-label={t("deleteLocation")}
                                              style={{
                                                color: "#d02216",
                                                borderColor: "#547D66",
                                                marginTop: 3,
                                              }}
                                              // role="img" // required in 39476 Mid-location delete buttons, reqiured only for this icon button
                                              onClick={() => {
                                                // remove(index);
                                                deleteMidLocation(
                                                  values.viaPoints,
                                                  index
                                                );
                                              }}
                                              edge="end"
                                              size="large"
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          </div>
                                        </span>
                                      </AccessibleTooltip>
                                    </div>
                                  )}
                                </Grid>

                                <Grid item xs={12}>
                                  <div style={{ height: 5 }}></div>
                                </Grid>
                              </Grid>
                            </div>
                          ))}
                        </Grid>
                      )}
                    </>
                  )}
                  {!values.isInternationalStartLocation &&
                    enableAddLocationButton && (
                      <>
                        <Grid item xs={12}>
                          <Box
                            role="note"
                            className="alignItemCentreWithH2Font"
                            justifyContent="flex-end"
                          >
                            <Button
                              title={t("AddLocation")}
                              ref={btnAddLocRef}
                              style={{ textTransform: "none" }}
                              id="addAddressLink"
                              color="inherit"
                              onClick={() =>
                                handleAddMidLocation(values.viaPoints)
                              }
                            >
                              {" "}
                              {t("AddLocation")}
                            </Button>
                            <AccessibleTooltip
                              text={t("AddLocationWithOptionalMsgToolTip")}
                            >
                              <span
                                role="note"
                                style={{
                                  marginTop: 11,
                                  marginBottom: 11,
                                  display: "inline-grid",
                                }}
                              >
                                <div>
                                  <IconButton
                                    id="infoIconAdd"
                                    size="small"
                                    color="primary"
                                    style={{ paddingBottom: 8 }}
                                    aria-label={t(
                                      "AddLocationWithOptionalMsgToolTip"
                                    )}
                                  >
                                    <InfoIcon fontSize="small" />
                                  </IconButton>
                                </div>
                              </span>
                            </AccessibleTooltip>
                          </Box>
                        </Grid>
                      </>
                    )}
                  <Grid item xs={12}>
                    <div className="alignItemCentreWithH2Font">
                      <label 
                        data-testid="custom-midLocation1"
                        htmlFor={"endLocation"}
                        className={classes.labelText} 
                      >
                        {t("End")}
                      </label>
                      <AccessibleTooltip text={t("SelectLocation")}>
                        <span
                          role="note"
                          style={{
                            marginTop: 11,
                            marginBottom: 11,
                            display: "inline-grid",
                          }}
                        >
                          <div>
                            <IconButton
                              id="infoIconEnd"
                              size="small"
                              color="primary"
                              style={{ paddingBottom: 8 }}
                              aria-label={t("SelectLocation")}
                            >
                              <InfoIcon fontSize="small" />
                            </IconButton>
                          </div>
                        </span>
                      </AccessibleTooltip>
                    </div>
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="endLocation"
                      id="endLocation"
                      placeholder={t("EndLocationPostcodeAddress")}
                      defaultValue={
                        values && values.endLocation.location
                          ? values.endLocation
                          : defaultLocation
                      }
                      fullWidth
                      isReadOnly={values.useStartAsEndLocation}
                      inputprops={{
                        id: "endLocation",
                        "aria-label": t("endLocation"),
                        "aria-required": true,
                        "data-val-required": "Required",
                      }}
                      component={EtdLocationLookup}
                      setlocationSearch={(loc) => {
                        if (!values.useStartAsEndLocation) {
                          setFieldTouched("endLocation", true);
                          setFieldValue("endLocation", loc);
                        }
                      }}
                      onInputChange={(val) => {
                        setFieldTouched("endLocation", true, true);
                      }}
                      error={touched.endLocation && errors.endLocation}
                      aria-describedby="endLocationErrorMsg"
                    ></Field>
                    {touched.endLocation && errors && errors.endLocation ? (
                      <span
                        aria-describedby="locationErrorMsg_endLocation"
                        className={classes.errorWarning}
                        id={"locationErrorMsg_endLocation"}
                      >
                        {t(errors.endLocation.toString())}
                      </span>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <hr></hr>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <label
                      htmlFor={"startDateTime-Date"}
                      className={classes.labelText}
                    >
                      {t("StartDate")}
                    </label>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <label
                      htmlFor={"startDateTime-time"}
                      className={classes.labelText}
                    >
                      <span className="sr-only">{t("Start")} </span>
                      {t("Time")}
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field
                      component={EtdDatePickerV2}
                      label="Start date"
                      name="startDate"
                      id="startDateTime-Date"
                      errorMessageId="startDateErrorMsg"
                      isPastDateAllowed={
                        true
                        // values.isJourneyProfile && !isExistingReservation
                        //   ? true
                        //   : false
                      }
                      daysPastDateAllowed={1}
                      localeDateTimeFormat={localeDateTimeFormat}
                      value={
                        values.startDate
                          ? getDateObjectFromShortString(values.startDate)
                          : null
                      }
                      handleChange={(e) => {
                        let newValue = e;
                        const format = localeDateTimeFormat.toUpperCase();
                        let parsedDate = moment(newValue, format, true);
                        if (parsedDate.isValid()) {
                          newValue = moment(parsedDate, "YYYY-MM-DD").format(
                            "YYYY-MM-DD"
                          );
                        } else {
                          newValue = null;
                        }

                        if (endDateChangedDirectly === false) {
                          let endDatePlusOne = moment(parsedDate, "YYYY-MM-DD")
                            .add(1, "days")
                            .format("YYYY-MM-DD");
                          setTimeout(() => {
                            setFieldValue(
                              "endDate",
                              newValue === null ? "" : endDatePlusOne
                            );
                          }, 50);
                        }
                        setFieldTouched("startDate", true, true);
                        setFieldValue(
                          "startDate",
                          newValue === null ? "" : newValue
                        );
                      }}
                      handleBlur={(e) => {
                        setFieldTouched("startDate", true);
                      }}
                      isError={
                        touched.startDate && errors && errors.startDate
                          ? true
                          : false
                      }
                      aria-describedby="startDateErrorMsg"
                    />
                    {touched.startDate && errors && errors.startDate ? (
                      <span
                        className={classes.errorWarning}
                        id="startDateErrorMsg"
                      >
                        {t(errors.startDate)}
                      </span>
                    ) : (
                      <span id="startDateErrorMsg"></span>
                    )}
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Field
                      id="startDateTime-time"
                      name={"startTime"}
                      fieldName={"startTime"}
                      component={EtdTimePicker}
                      format={t("TimeFormat")}
                      defaultValue={values.startTime}
                      onChange={(e) => {
                        let time = e.target.value;
                        //   setStartTime(time);
                        setFieldTouched("startTime", true);
                        setFieldValue("startTime", time);
                      }}
                      errorMessageId="startTimeRequired"
                      error={
                        touched.startTime && errors.startTime ? true : false
                      }
                      isError={
                        touched.startTime && errors && errors.startTime
                          ? true
                          : false
                      }
                      aria-describedby="startTimeRequired"
                    ></Field>

                    {touched.startTime && errors && errors.startTime ? (
                      <span
                        className={classes.errorWarning}
                        id="startTimeRequired"
                      >
                        {t(errors.startTime)}
                      </span>
                    ) : (
                      <span id="startTimeRequired"></span>
                    )}
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <label
                      htmlFor={"endDateTime-Date"}
                      className={classes.labelText}
                    >
                      {t("EndDate")}
                    </label>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <label
                      htmlFor={"endDateTime-time"}
                      className={classes.labelText}
                    >
                      <span className="sr-only">{t("End")} </span>
                      {t("Time")}
                    </label>
                  </Grid>
                  <Grid item xs={12} md={9}>
                    <Field
                      component={EtdDatePickerV2}
                      label="End date"
                      name="endDate"
                      id="endDateTime-Date"
                      errorMessageId="endDateErrorMsg"
                      isPastDateAllowed={
                        true
                        // values.isJourneyProfile && !isExistingReservation
                        //   ? true
                        //   : false
                      }
                      daysPastDateAllowed={1}
                      localeDateTimeFormat={localeDateTimeFormat}
                      value={
                        values.endDate
                          ? getDateObjectFromShortString(values.endDate)
                          : null
                      }
                      handleChange={(e) => {
                        let newValue = e;
                        const format = localeDateTimeFormat.toUpperCase();
                        let parsedDate = moment(newValue, format, true);

                        if (parsedDate.isValid()) {
                          newValue = moment(parsedDate, "YYYY-MM-DD").format(
                            "YYYY-MM-DD"
                          );
                        } else {
                          newValue = null;
                        }
                        setEndDate(newValue);
                        setFieldTouched("endDate", true);
                        setFieldValue(
                          "endDate",
                          newValue === null ? "" : newValue
                        );
                        setEndDateChangedDirectly(true);
                      }}
                      handleBlur={(e) => {
                        setFieldTouched("endDate", true);
                      }}
                      isError={touched.endDate && errors.endDate ? true : false}
                      aria-describedby="endDateErrorMsg"
                    />
                    {touched.endDate && errors && errors.endDate ? (
                      <span
                        className={classes.errorWarning}
                        id="endDateErrorMsg"
                      >
                        {t(errors.endDate)}
                      </span>
                    ) : (
                      <span id="endDateErrorMsg"></span>
                    )}
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Field
                      id="endDateTime-time"
                      name={"endTime"}
                      fieldName={"endTime"}
                      defaultValue={values.endTime}
                      component={EtdTimePicker}
                      format={t("TimeFormat")}
                      onChange={(e) => {
                        let time = e.target.value;
                        // setEndTime(time);
                        setFieldTouched("endTime", true);
                        setFieldValue("endTime", time);
                      }}
                      errorMessageId="endTimeRequired"
                      error={touched.endTime && errors.endTime ? true : false}
                      isError={
                        touched.endTime && errors && errors.endTime
                          ? true
                          : false
                      }
                      aria-describedby="endTimeRequired"
                    ></Field>

                    {touched.endTime && errors && errors.endTime ? (
                      <span
                        className={classes.errorWarning}
                        id="endTimeRequired"
                      >
                        {t(errors.endTime)}
                      </span>
                    ) : (
                      <span id="endTimeRequired"></span>
                    )}
                  </Grid>
                  {values.isJourneyProfile ? (
                    <Grid item xs={12} md={12} lg={12}>
                      <div
                        style={{
                          paddingTop: 5,
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          style={{
                            paddingTop: 0,
                            paddingLeft: 0,
                            color: "#000000",
                          }}
                          variant="h2"
                        >
                          {t("ModeofTransport")}
                        </Typography>
                      </div>
                    </Grid>
                  ) : (
                    ""
                  )}
                  {values.isJourneyProfile ? (
                    <Grid
                      style={{ paddingBottom: 5, paddingTop: 0 }}
                      item
                      xs={12}
                      md={12}
                      lg={12}
                    >
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          native
                          fullWidth
                          placeholder={t("ModeofTransport")}
                          name="availableModesOfTransport"
                          id="availableModesOfTransport"
                          inputProps={{
                            id: "ModeofTransport",
                            name: "ModeofTransport",
                            title: t("ModeofTransport"),
                            "aria-label": t("ModeofTransport"),
                          }}
                          value={values.modeOfTransportUId ?? ""}
                          error={
                            errors &&
                            touched.availableModesOfTransport &&
                            errors.availableModesOfTransport
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            setFieldTouched("availableModesOfTransport", true);
                            handleModesOfTransportChange(
                              e,
                              values,
                              setFieldValue
                            );
                          }}
                        >
                          {values.availableModesOfTransport &&
                            values.availableModesOfTransport.map(
                              (item, index) => (
                                <option key={index} value={item.value}>
                                  {item.text}
                                </option>
                              )
                            )}
                        </Select>
                      </FormControl>
                      <ErrorMessage name="availableModesOfTransport">
                        {(msg) => (
                          <span className={classes.errorWarning}>{t(msg)}</span>
                        )}
                      </ErrorMessage>
                    </Grid>
                  ) : (
                    ""
                  )}

                  {values.isJourneyProfile &&
                  values.modeOfTransportUId &&
                  modOfTransportRegNumber.includes(
                    values.modeOfTransportUId
                  ) ? (
                    <>
                      <Grid item xs={12} md={12} lg={12}>
                        <div
                          style={{
                            paddingTop: 5,
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography
                            style={{ paddingTop: 0, paddingLeft: 0 }}
                            variant="h2"
                          >
                            {t("RegNumber")}
                          </Typography>

                          <AccessibleTooltip text={t("RegNumberTooltip")}>
                            <span
                              role="note"
                              style={{
                                marginTop: 11,
                                marginBottom: 11,
                                display: "inline-grid",
                              }}
                            >
                              <div>
                                <IconButton
                                  id="infoIconRegNumber"
                                  size="small"
                                  color="primary"
                                  style={{ paddingBottom: 8 }}
                                  aria-label={t("RegNumberTooltip")}
                                >
                                  <InfoIcon fontSize="small" />
                                </IconButton>
                              </div>
                            </span>
                          </AccessibleTooltip>
                        </div>
                      </Grid>
                      <Grid
                        style={{
                          paddingBottom: 0,
                          paddingTop: 5,
                        }}
                        xs={12}
                        md={12}
                        lg={12}
                      >
                        <Field
                          style={{ width: 250, height: 50 }}
                          placeholder={t("RegNumberPlaceholder")}
                          name="registrationNumber"
                          id="registrationNumber"
                          component={TextField}
                          variant="outlined"
                          value={values.registrationNumber ?? ""}
                          error={
                            touched.registrationNumber &&
                            errors.registrationNumber
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            let input = e.target.value;

                            setFieldTouched("registrationNumber", true);
                            setFieldValue("registrationNumber", input);
                          }}
                        ></Field>
                        <div>
                          {touched.registrationNumber &&
                          errors &&
                          errors.registrationNumber ? (
                            <span
                              id="registrationNumberMaxCharError"
                              className={classes.errorWarning}
                            >
                              {errors.registrationNumber}
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}
                  {values.activeMileageReimbursement &&
                    !values.isInternationalStartLocation &&
                    !values.isSpecialityVehicle && (
                      <Grid item xs={12} md={12} lg={12}>
                        <div className="alignItemCentreWithH2Font">
                          <Field
                            inputProps={{
                              "aria-label": t("UseOwnVehicleLabel"),
                            }}
                            component={Checkbox}
                            type="checkbox"
                            style={{
                              color: "#006639",
                              backgroundColor: "#FFFFFF",
                            }}
                            name="useOwnVehicle"
                            id="useOwnVehicleCheckBox"
                            checked={values.useOwnVehicle}
                            onClick={(e) =>
                              setFieldValue("useOwnVehicle", e.target.checked)
                            }
                          />
                          <Typography variant="body1">
                            {t("UseOwnVehicleLabel")}
                          </Typography>
                        </div>
                      </Grid>
                    )}
                  {companyInfo?.isDriverValidityActivated && (
                    <>
                      <Grid item xs={12}>
                        <div className="alignItemCentre">
                          <Typography
                            className="topLeftZeroPadding"
                            variant="h2"
                            id="driverDeclarationLabel"
                          >
                            {t("PrimaryDriverDeclaration")}
                          </Typography>
                          <AccessibleTooltip
                            text={t("journeyInput.primaryDriverTooltip")}
                          >
                            <span
                              role="note"
                              style={{
                                marginTop: 11,
                                marginBottom: 11,
                                display: "inline-grid",
                              }}
                            >
                              <div>
                                <IconButton
                                  id="primaryDriverinfoIcon"
                                  size="small"
                                  color="primary"
                                  style={{ paddingBottom: 8 }}
                                  aria-label={t(
                                    "journeyInput.primaryDriverTooltip"
                                  )}
                                >
                                  <InfoIcon fontSize="small" />
                                </IconButton>
                              </div>
                            </span>
                          </AccessibleTooltip>
                        </div>
                      </Grid>
                      {
                        <Grid item xs={12}>
                          <Box
                            id="PrimaryDDStatusBox"
                            display="flex"
                            flexDirection="row"
                            alignItems="flex-start"
                            style={{ float: "right" }}
                          >
                            {values.primaryDriverDeclaration ? (
                              errors && errors.primaryDriverDeclaration ? (
                                ""
                              ) : (
                                <CheckSharp
                                  style={{ color: "green", marginTop: 2 }}
                                />
                              )
                            ) : (
                              ""
                            )}
                            {values.primaryDriverDeclaration ? (
                              <span
                                style={{
                                  color:
                                    errors && errors.primaryDriverDeclaration
                                      ? ""
                                      : "green",
                                  marginLeft: "5px",
                                  marginTop: 4,
                                }}
                              >
                                {errors && errors.primaryDriverDeclaration
                                  ? ""
                                  : t("journeyInput.validOption1")}
                              </span>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Grid>
                      }
                      <Grid
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        item
                        xs={12}
                      >
                        <Field
                          name="primaryDriverDeclaration"
                          id="driverDeclarationTextbox"
                          placeholder={t("PrimaryDDNameEmailNumber")}
                          noMatchText={t("journeyInput.labelNoPrimaryDrivers")}
                          allowClearOnEscape={false}
                          journeyEndDate={values.endDate}
                          defaultValue={
                            values && values.primaryDriverDeclaration
                              ? values.primaryDriverDeclaration
                              : myJourneyIntitalValue.primaryDriverDeclaration
                          }
                          fullWidth
                          isReadOnly={checkPrimaryDriverDeclarationAvailability(
                            errors,
                            touched
                          )}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          Label={{ label: "Driver Declaration" }}
                          inputprops={{
                            "aria-label": t("PrimaryDriverDeclaration"),
                            "aria-required": true,
                            "data-val-required": "Required",
                            "auto-complete": "false",
                          }}
                          component={DriverAutoComplete}
                          onInputChange={(val) => {
                            setFieldTouched(
                              "primaryDriverDeclaration",
                              true,
                              true
                            );
                          }}
                          setDriverSearch={(dd) => {
                            setFieldTouched("primaryDriverDeclaration", true);
                            setFieldValue("primaryDriverDeclaration", dd);
                            setPrimaryDriver(dd);
                          }}
                          error={
                            touched.primaryDriverDeclaration &&
                            errors?.primaryDriverDeclaration
                              ? true
                              : false
                          }
                          aria-describedby="primaryDriverDeclarationErrorMsg"
                          aria-labelledby="start"
                        />
                        {touched.primaryDriverDeclaration &&
                        errors &&
                        errors.primaryDriverDeclaration ? (
                          <span
                            className={classes.errorWarning}
                            style={{ marginLeft: 3 }}
                            id="primaryDriverDeclarationErrorMsg"
                          >
                            {t(errors.primaryDriverDeclaration.toString())}
                          </span>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </>
                  )}
                  {companyInfo?.isDriverValidityActivated &&
                    values.additionalDriver &&
                    enableAdditionalDriver && (
                      <>
                        <Box
                          className="paddingLeft1cm"
                          style={{
                            marginTop: 30,
                            marginBottom: 5,
                            marginLeft: -30,
                            display: "inline-grid",
                          }}
                          fontWeight="fontWeightBold"
                        >
                          <Grid item xs={12}>
                            <div className="alignItemCentre">
                              <Typography
                                className="topLeftZeroPadding"
                                variant="h2"
                                id="additionalDriverLabel"
                              >
                                {t("AdditionalDriver")}
                              </Typography>
                            </div>
                          </Grid>
                        </Box>

                        {values.additionalDriver &&
                          values.additionalDriver.length > 0 && (
                            <>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                style={{ marginLeft: 0 }}
                              >
                                {values.additionalDriver.map((aD, index) => (
                                  <div key={index} style={{ marginTop: 10 }}>
                                    <Grid container>
                                      <Grid item xs={11}>
                                        <Box
                                          id={
                                            "AdditionalDriverStatus" +
                                            (index + 1)
                                          }
                                          display="flex"
                                          flexDirection="row"
                                          alignItems="flex-start"
                                          style={{ float: "right" }}
                                        >
                                          {aD /*&& aD.driverDeclarationId */ &&
                                          touched.additionalDriver &&
                                          touched.additionalDriver[index] ? (
                                            invalidAdditionalDriver(
                                              aD,
                                              values,
                                              index,
                                              errors,
                                              touched
                                            ) ? (
                                              ""
                                            ) : (
                                              <CheckSharp
                                                style={{
                                                  color: "green",
                                                  marginTop: 2,
                                                }}
                                              />
                                            )
                                          ) : (
                                            ""
                                          )}
                                          {aD /*&& aD.driverDeclarationId */ &&
                                          touched.additionalDriver &&
                                          touched.additionalDriver[index] ? (
                                            <span
                                              style={{
                                                color: invalidAdditionalDriver(
                                                  aD,
                                                  values,
                                                  index,
                                                  errors,
                                                  touched
                                                )
                                                  ? ""
                                                  : "green",
                                                marginLeft: "5px",
                                                marginTop: 4,
                                              }}
                                            >
                                              {invalidAdditionalDriver(
                                                aD,
                                                values,
                                                index,
                                                errors,
                                                touched
                                              )
                                                ? ""
                                                : t(
                                                    "journeyInput.validOption1"
                                                  )}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </Box>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={11}
                                        sm={11}
                                        md={11}
                                        lg={11}
                                      >
                                        <Field
                                          name={`additionalDriver.${index}`}
                                          id={"additionalDriver" + (index + 1)}
                                          placeholder={t(
                                            "PrimaryDDNameEmailNumber"
                                          )}
                                          noMatchText={t(
                                            "journeyInput.labelNoAdditionalDrivers"
                                          )}
                                          allowClearOnEscape={true}
                                          journeyEndDate={values.endDate}
                                          defaultValue={
                                            aD && aD.driverDeclarationId
                                              ? aD
                                              : myJourneyIntitalValue.additionalDriver
                                          }
                                          fullWidth
                                          isReadOnly={false}
                                          style={{
                                            paddingBottom: 0,
                                            paddingTop: 0,
                                          }}
                                          Label={{
                                            label: "Driver Declaration",
                                          }}
                                          inputprops={{
                                            "aria-label": t("AdditionalDriver"),
                                            "aria-required": false,
                                            "data-val-required": "Required",
                                            "auto-complete": "false",
                                          }}
                                          component={DriverAutoComplete}
                                          onInputChange={(val) => {
                                            setFieldTouched(
                                              `additionalDriver.${index}`,
                                              true,
                                              true
                                            );
                                            setAdditionalDriver(
                                              values.additionalDriver
                                            );
                                          }}
                                          setDriverSearch={(dd) => {
                                            setFieldTouched(
                                              `additionalDriver.${index}`,
                                              true
                                            );
                                            setFieldValue(
                                              `additionalDriver.${index}`,
                                              dd
                                            );
                                            setAdditionalDriver(
                                              values.additionalDriver
                                            );
                                          }}
                                          error={
                                            touched.additionalDriver &&
                                            errors?.additionalDriver &&
                                            invalidAdditionalDriver(
                                              aD,
                                              values,
                                              index,
                                              errors,
                                              touched
                                            )
                                          }
                                          aria-describedby="additionalDriverErrorMsg"
                                          aria-labelledby="start"
                                        />
                                        {touched.additionalDriver &&
                                        errors &&
                                        errors.additionalDriver &&
                                        invalidAdditionalDriver(
                                          aD,
                                          values,
                                          index,
                                          errors,
                                          touched
                                        ) ? (
                                          <span
                                            className={classes.errorWarning}
                                            style={{ marginLeft: 3 }}
                                            id={
                                              "additionalDriverErrorMsg" +
                                              (index + 1)
                                            }
                                          >
                                            {t(
                                              errors?.additionalDriver.toString()
                                            )}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </Grid>
                                      <Grid item xs={1} sm={1} md={1} lg={1}>
                                        <div>
                                          <AccessibleTooltip
                                            text={t("deleteAdditionalDriver")}
                                          >
                                            <IconButton
                                              id={
                                                "infoIconDeleteAddDriver" +
                                                (index + 1)
                                              }
                                              aria-label={t(
                                                "deleteAdditionalDriver"
                                              )}
                                              style={{
                                                color: "#d02216",
                                                borderColor: "#547D66",
                                                marginTop: 3,
                                                marginLeft: -1,
                                              }}
                                              onClick={() => {
                                                deleteAdditionalDriver(
                                                  values.additionalDriver,
                                                  touched,
                                                  index
                                                );
                                              }}
                                              edge="end"
                                              size="large"
                                            >
                                              <CancelIcon />
                                            </IconButton>
                                          </AccessibleTooltip>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                ))}
                              </Grid>
                            </>
                          )}
                      </>
                    )}
                  {companyInfo?.isDriverValidityActivated &&
                    enableAdditionalDriverBtn && (
                      <>
                        <Grid item xs={12}>
                          <Box
                            role="note"
                            className="alignItemCentreWithH2Font"
                            justifyContent="flex-end"
                          >
                            <Button
                              id="addDriverButton"
                              title={t("journeyInput.addAdditionalDriver")}
                              ref={btnAddDriver}
                              style={{ textTransform: "none" }}
                              color="inherit"
                              disabled={disableAdditionalDriverButton(
                                values,
                                errors,
                                touched
                              )}
                              onClick={() => {
                                handleaddAdditionalDriverList(
                                  values.additionalDriver
                                );
                              }}
                            >
                              {" "}
                              {t("addAdditionalDriverBtn")}
                            </Button>
                            <AccessibleTooltip
                              text={t(
                                "journeyInput.addAdditionalDriverTooltip"
                              )}
                            >
                              <span
                                role="note"
                                style={{
                                  marginTop: 11,
                                  marginBottom: 11,
                                  display: "inline-grid",
                                }}
                              >
                                <div>
                                  <IconButton
                                    id="additionalDriverinfoIcon"
                                    size="small"
                                    color="primary"
                                    style={{ paddingBottom: 8 }}
                                    aria-label={t(
                                      "journeyInput.addAdditionalDriverTooltip"
                                    )}
                                  >
                                    <InfoIcon fontSize="small" />
                                  </IconButton>
                                </div>
                              </span>
                            </AccessibleTooltip>
                          </Box>
                        </Grid>
                      </>
                    )}

                  <Grid item md={12} lg={12} xs={12}>
                    <Box
                      className="alignItemCentreWithH2Font"
                      justifyContent="flex-end"
                    >
                      <Button
                        id="SubmitButton"
                        title={
                          values.isDailyRentalSingleProduct
                            ? t("BookAVehicle")
                            : t("Submit")
                        }
                        color="primary"
                        variant="contained"
                        type="submit"
                        style={{ textTransform: "none" }}
                        disabled={!isValid || !dirty}
                      >
                        {values.isDailyRentalSingleProduct
                          ? t("BookAVehicle")
                          : t("Submit")}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </CardContent>
    </Card>
  );
};

export default JourneyInputForm;
