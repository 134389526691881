import React, { useEffect } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { ReservationProps } from "../../reservationType";
import Accordion from "@mui/material/Accordion";

import { reservationValidationSchema } from "./reservationValidationSchema";
import {
  ReservationLocationsViewModel,
  ReservationObject,
  ReservationReasonForHireViewModel,
  resInitialValue,
  CarClass,
  JourneyProfilerViewModel,
  ReservationLocInfoViewModel,
  BrandType,
} from "./reservationFormType";
import EtdCarClasses from "./controls/carClass";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Formik, Form, Field } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import EtdDatePickerV2 from "controls/formik/EtdDatePickerV2";
import EtdTimePicker from "../../../../controls/formik/EtdTimePicker";
import axiosInstance from "helpers/axiosInstance";
import { registerLocale } from "react-datepicker";
import de from "date-fns/locale/de";
import es from "date-fns/locale/es";
import fr from "date-fns/locale/fr";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "components/store/reducers/rootReducer";
import {
  updateReservation,
  resetReservation,
} from "components/store/actions/reservation/reservationActions";
import "react-datepicker/dist/react-datepicker.css";
import makeStyles from "@mui/styles/makeStyles";
import CompactJourneyDetailsView from "../Shared/compactJourneyDetailsView";
import JourneyStartEndMethods from "./journeyStartEndMethods";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showInfoAlertMessage,
} from "components/store/actions/shared/alertActions";

import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { authService } from "services/authService";
import moment from "moment";
import {
  getDateObjectFromShortString,
  getIntlDateFormatForLocale,
} from "helpers/localization";
import { countriesToShowEmmision, useCo2Dict } from "controls/hooks/useCo2Dict";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    verticalAlign: "center",
    padding: "10px",
    "& .MuiTypography-body2": {
      fontWeight: "bold",
      fontSize: "0.875rem",
    },
  },
  heading: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
  labelText: {
    color: "#000",
    fontFamily: "Arial, Roboto, Segoe UI",
    fontSize: "0.875rem",
    fontWeight: "bold",
    verticalAlign: "middle",
    whiteSpace: "nowrap",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: "0.75rem",
    marginLeft: "15px",
  },
  textarea: {
    resize: "both",
  },
  focused: { outline: "none" },
}));

const AccordionSummary = withStyles({
  root: {
    //  flexDirection: "column",
    backgroundColor: "#006639",
    fontWeight: "bold",
    color: "#fff",
    border: "2px solid #006639",
    "&$focused": {
      color: "#006639",
    },
    "&.Mui-expanded": {
      minHeight: "48px",
    },
    "&.Mui-focused": {
      backgroundColor: "#006639",
    },
  },

  content: {
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
    minHeight: "12px",
  },
  expandIcon: {
    marginRight: 0,
    paddingTop: 0,
    color: "#fff",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles({
  root: {
    "& ::placeholder": {
      color: "#767676",
      opacity: "1",
    },
  },
})(MuiAccordionDetails);

const ReservationForm: React.FC<ReservationProps> = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [carClassLoading, setCarClassLoading] = React.useState(false);
  const [loadingAccount, setLoadingAccount] = React.useState(false);
  const [journeyprofile, setjourneyProfile] =
    React.useState<JourneyProfilerViewModel>();
  const [validatingAccount, setValidatingAccount] = React.useState(false);
  const [resdata, setResData] =
    React.useState<ReservationObject>(resInitialValue);
  const [carClasses, setCarClassess] = React.useState<CarClass | null>(null);
  const [selectedReasonForHire, setselectedReasonForHire] =
    React.useState<ReservationReasonForHireViewModel>();
  const [selectedCarClass, setselectedCarClass] = React.useState({});
  const [locationsView, setlocationsView] =
    React.useState<ReservationLocationsViewModel>();
  const [initialLocation, setInitialLocation] =
    React.useState<ReservationLocationsViewModel>();
  // let initialLocation;
  const [startLocInfo, setStartLocInfo] =
    React.useState<ReservationLocInfoViewModel>();
  const [endLocInfo, setEndLocInfo] =
    React.useState<ReservationLocInfoViewModel>();
  const [startMethod, setStartMethod] = React.useState(false);
  const [endMethod, setEndMethod] = React.useState(false);
  const [accountChanged, setaccountChanged] = React.useState(false);
  const [formValidation, setFormValidation] = React.useState(
    reservationValidationSchema
  );
  const theme = useTheme();
  const isSmXs = useMediaQuery(theme.breakpoints.down("lg"));
  const locale = authService.getUserLocale();
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [startTime, setStartTime] = React.useState("0");
  const [endTime, setEndTime] = React.useState("0");
  // store whether the start date is being set for the first time. 1 == first time
  const [firstTimeSdSet, setFirstTimeSdSet] = React.useState(0);
  const titleRef = React.useRef<HTMLDivElement>(null);
  registerLocale("de", de);
  registerLocale("es", es);
  registerLocale("fr", fr);
  const formRef = React.useRef<any>(null);
  let existingRes = useSelector((state: AppState) => state.dailyRental);
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const qsType = queryParams.get("type");

  const [resType, setResType] = React.useState("0");
  const [isDirty, setIsDirty] = React.useState(false);
  const history = useNavigate();
  const co2rate = useCo2Dict();
  const localeDateTimeFormat = getIntlDateFormatForLocale(locale);
  const [endDateChangedDirectly, setEndDateChangedDirectly] =
    React.useState(false);
  function isNullOrWhitespace(input) {
    return !input || !input.trim();
  }

  const handleCarClassSelect = (car, key) => {
    let newCarClassess = JSON.parse(
      JSON.stringify(formRef.current.values.carClass)
    );
    console.log("Car: ", car);
    for (var i = 0; i < newCarClassess.carsInformation.classInfo.length; i++) {
      for (
        var j = 0;
        j < newCarClassess.carsInformation.classInfo[i].brandInfo.length;
        j++
      ) {
        if (
          newCarClassess.carsInformation.classInfo[i].brandInfo[j].uniqueKey ===
          car.car.uniqueKey
        ) {
          const selected =
            !newCarClassess.carsInformation.classInfo[i].brandInfo[j]
              .isSelected;
          newCarClassess.carsInformation.classInfo[i].brandInfo[j].isSelected =
            selected;
        } else {
          newCarClassess.carsInformation.classInfo[i].brandInfo[j].isSelected =
            false;
        }
        newCarClassess.carsInformation.classInfo[i].brandInfo[
          j
        ].selectedExtras = [];
      }
    }
    setCarClassess(newCarClassess);
    setselectedCarClass(car.car);

    let fullBrandName = "";

    if (newCarClassess !== null && newCarClassess.brand !== "") {
      if (newCarClassess.brand === BrandType.NATIONAL)
        fullBrandName = t("journeyMethod.ET");
      else if (newCarClassess.brand === BrandType.ENTERPRISE)
        fullBrandName = t("journeyMethod.ZL");
    }

    // formRef.current.setFieldTouched("carClass", true, true);
    if (formRef.current) {
      formRef.current.setFieldValue("fullBrandName", fullBrandName);
      formRef.current.setFieldValue("carClass", newCarClassess);
    }
  };

  const handleCarExtras = (key, extras) => {
    let newCarClassess = JSON.parse(
      JSON.stringify(formRef.current.values.carClass)
    );
    for (var i = 0; i < newCarClassess.carsInformation.classInfo.length; i++) {
      for (
        var j = 0;
        j < newCarClassess.carsInformation.classInfo[i].brandInfo.length;
        j++
      ) {
        if (
          newCarClassess.carsInformation.classInfo[i].brandInfo[j].uniqueKey ===
          key
        ) {
          newCarClassess.carsInformation.classInfo[i].brandInfo[
            j
          ].selectedExtras = extras;
        }
      }
    }
    setCarClassess(newCarClassess);

    if (formRef.current) {
      formRef.current.setFieldValue("carClass", newCarClassess);
    }
  };

  const setReservationDrivers = (
    firstName,
    lastName,
    email,
    additionalDriver,
    isDriverValidityActivated
  ) => {
    if (!(firstName && lastName && email)) {
      return;
    }
    var searchCriteria = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
    };
    axiosInstance
      .post("/drivers/driverdeclaration", searchCriteria)
      .then((result) => {
        let drivers = {
          primaryDriver: result.data,
          additionalDriver: additionalDriver,
          isBOBOToggleEnabledAndIsDriverDeclaration: isDriverValidityActivated,
        };
        authService.setDriverInfo(drivers);
      });
  };

  const getReservationDetails = async (id, restype) => {
    setLoading(true);
    if (existingRes.reservation === undefined) {
      let url = `reservationwizard/${
        restype === "1"
          ? "getmodifydetails"
          : restype === "2"
          ? "getrebookdetails"
          : "reservation"
      }/${id}`;
      await axiosInstance
        .get(url)
        .then((response) => {
          console.log("Res data: ", response.data.data);
          appInsights.trackTrace({
            message: "getting reservation details",
            properties: {
              reservation: response.data.data,
              Component: "Daily Rental",
              userId: authService.getUserName(),
              companyId: authService.getUserCompanyInternalId(),
            },
            severityLevel: SeverityLevel.Information,
          });
          let reservationDetails = response.data.data;
          setResData(reservationDetails);
          if (restype === "1" || restype === "2") {
            setReservationDrivers(
              reservationDetails.driverViewModel?.firstName,
              reservationDetails.driverViewModel?.lastName,
              reservationDetails.driverViewModel?.emailAddress,
              reservationDetails.additionalDriver,
              reservationDetails.driverDeclaration
            );
          }

          setStartTime(
            reservationDetails.dateTime.startTime === null
              ? "12:00:00"
              : getTimeValue(reservationDetails.dateTime.startDateTime, 0)
          );
          setEndTime(
            reservationDetails.dateTime.endTime === null
              ? "12:00:00"
              : getTimeValue(reservationDetails.dateTime.endDateTime, 1)
          );
          setjourneyProfile(reservationDetails.journeyViewModel);
          if (restype === "1" || restype === "2") {
            setselectedReasonForHire(reservationDetails.reasonForHire);
            setlocationsView(reservationDetails.locations);
          }
          setLoading(false);

          if (titleRef.current !== null) titleRef.current.focus();

          if (
            reservationDetails.warnings &&
            reservationDetails.warnings.length > 0
          ) {
            dispatch(showInfoAlertMessage(reservationDetails.warnings));
            dispatch(broadcastAnnouncement(reservationDetails.warnings));
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
          appInsights.trackException({
            exception: error,
            properties: {
              method: "reservationdetails",
              Component: "DR Reservation details",
            },
            severityLevel: SeverityLevel.Error,
          });
        });
    } else {
      if (formRef.current) {
        formRef.current.resetForm();
      }
      if (existingRes.reservation !== null) {
        setIsDirty(false);
        setResData(existingRes.reservation);
        setStartTime(
          getTimeValue(existingRes.reservation.dateTime.startDateTime, 0)
        );
        setEndTime(
          getTimeValue(existingRes.reservation.dateTime.endDateTime, 1)
        );
        console.log("existingRes.reservation", existingRes.reservation);
        var rfh = JSON.parse(
          JSON.stringify(existingRes.reservation.reasonForHire)
        );
        rfh.isSelectedAccountValid = true;
        setselectedReasonForHire(rfh);
        setjourneyProfile(existingRes.reservation.journeyViewModel);
        const response2 = Object.assign({}, existingRes.reservation.locations);
        setInitialLocation(response2);
        setlocationsView(existingRes.reservation.locations);
        setCarClassess(existingRes.reservation.carClass ?? null);
        await delay(500);
        setLoading(false);

        if (titleRef.current !== null) titleRef.current.focus();

        if (existingRes.hasError) {
          if (Object.keys(existingRes.errors).length > 0) {
            let errors: string[] = [];
            for (const [key, value] of Object.entries(existingRes.errors)) {
              console.log(`${key}: ${value}`);
              errors.push(value);
            }
            //  let errors = convertToFormikError(
            //   err.response.data.errors,
            //    t
            //  );
            //  console.log("ERRS: ", errors);
            //  setErrors(errors);

            dispatch(showErrorAlertMessage(errors));
            dispatch(broadcastAnnouncement(errors.join(". ")));
            window.scrollTo(0, 0);
          }
        }
      }
    }
  };

  useEffect(() => {
    dispatch(clearAlertMessage());
    if (titleRef.current !== null) titleRef.current.focus();
    if (qsType !== null || qsType !== undefined) {
      setResType(qsType?.toString() || "");
      setIsDirty(!(qsType === "1" || qsType === "2"));
    }
    console.log("ResType = ", resType);
    const fetchData = async () => {
      await getReservationDetails(props.journeyUId, qsType ?? "0");
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  useEffect(() => {
    const resType = qsType ?? "0";
    if (
      resType === "0" &&
      (existingRes.reservation === undefined || accountChanged)
    )
      displayAccountSpecificViews();
    if (
      formRef.current &&
      selectedReasonForHire &&
      (existingRes.reservation === undefined || accountChanged)
    ) {
      formRef.current.setFieldValue(
        "reasonForHire.accountUId",
        selectedReasonForHire.accountUId
      );
      formRef.current.setFieldValue(
        "reasonForHire.accountName",
        selectedReasonForHire.accountName
      );
      formRef.current.setFieldValue(
        "reasonForHire.accountNumber",
        selectedReasonForHire.accountNumber
      );
      formRef.current.setFieldValue(
        "reasonForHire.isSelectedAccountValid",
        selectedReasonForHire.isSelectedAccountValid
      );
      formRef.current.setFieldValue(
        "reasonForHire.brands",
        selectedReasonForHire.brands
      );
      formRef.current.setFieldValue("isAccountValidated", true);
    }
    if (selectedReasonForHire === undefined && formRef.current)
      formRef.current.setFieldValue("reasonForHire.accountUId", null);
  }, [selectedReasonForHire, resdata]);

  const validateAccount = async (accountUId, callAdditionalInformationApi) => {
    dispatch(clearAlertMessage());
    if (accountUId !== "") {
      setValidatingAccount(true);
      dispatch(
        broadcastAnnouncement(t("journeyMethod.validatingAccountMessage"))
      );
      await axiosInstance
        .get(`reservation/validateaccount/${accountUId}`)
        .then((response) => {
          console.log(response.data);
          setselectedReasonForHire(response.data);
          setValidatingAccount(false);
          if (response.data?.accountUId && callAdditionalInformationApi) {
          }
        })
        .catch((error) => {
          var errorlist: string[] = [];
          setValidatingAccount(false);
          if (error.response && error.response.data.messages) {
            error.response.data.messages.forEach((element) => {
              if (element === "ERR20074")
                errorlist.push(t("journeyMethod." + element));
              else errorlist.push(element);
            });
            dispatch(showErrorAlertMessage(errorlist));
            dispatch(broadcastAnnouncement(errorlist.join(",")));
          }
          console.error("There was an error!", error);
          appInsights.trackException({
            exception: error,
            properties: {
              method: "validateAccount",
              Component: "DR Reservation details",
            },
            severityLevel: SeverityLevel.Error,
          });
        });
    } else {
      setselectedReasonForHire(undefined);
    }
  };

  function validateDate() {
    const startDt = moment.isDate(
      new Date(formRef.current.values.dateTime.startDateTime)
    );
    const endDt = moment.isDate(
      new Date(formRef.current.values.dateTime.endDateTime)
    );
    if (startTime !== "0" && endTime !== "0") {
      if (startDt && endDt) return true;
    }
    return false;
  }

  function convertToDate(dt: any) {
    if (dt === null || dt === "") return null;
    if (dt && typeof dt === "string" && dt.includes("-")) {
      let dateParts = dt.split("-");
      return new Date(+dateParts[0], Number(dateParts[1]) - 1, +dateParts[2]);
    }
    return moment(dt).format("YYYY-MM-DD");
  }

  const displayAccountSpecificViews = async () => {
    dispatch(clearAlertMessage());
    if (selectedReasonForHire) {
      setLoadingAccount(true);
      setStartMethod(false);
      setEndMethod(false);
      setStartLocInfo(undefined);
      setEndLocInfo(undefined);
      dispatch(broadcastAnnouncement(t("journeyMethod.loadingJourneyMethods")));
      await axiosInstance
        .get(
          `reservation/locations/` +
            resdata.locationsURL +
            `/${selectedReasonForHire?.accountUId}/${selectedReasonForHire?.brands}/${props.journeyUId}`
        )
        .then((response) => {
          const response2 = Object.assign({}, response.data);
          console.log("locationView: ", response.data);
          setInitialLocation(response2);
          setlocationsView(response.data);
          setLoadingAccount(false);
          dispatch(
            broadcastAnnouncement(t("journeyMethod.loadedJourneyMethods"))
          );
        })
        .catch((error) => {
          dispatch(showErrorAlertMessage(t("UnexpectedError")));
          dispatch(broadcastAnnouncement(t("UnexpectedError")));
          console.error("There was an error!", error);
          setLoadingAccount(false);
        });
    }
  };

  const convertArrayToDictionary = (inputArray) => {
    let dicObject = {};
    if (inputArray && inputArray.length > 0) {
      for (var i = 0; i < inputArray.length; i++) {
        dicObject[inputArray[i].brand] = inputArray[i].value;
      }
    }
    return dicObject;
  };
  const getCarClasses = async (
    startLoc: ReservationLocInfoViewModel,
    endLoc: ReservationLocInfoViewModel
  ) => {
    dispatch(clearAlertMessage());
    if (
      formRef.current &&
      formRef.current.values.dateTime.startDateTime !== null &&
      formRef.current.values.dateTime.endDateTime !== null &&
      selectedReasonForHire &&
      locationsView
      // startMethod &&
      // endMethod &&
      //  startLocInfo &&
      //  endLocInfo
    ) {
      console.log("getting car classes");
      if (formRef.current) formRef.current.setFieldValue("carClass", null);
      setCarClassLoading(true);
      let model = {
        JourneyProfileId: props.journeyUId,
        AccountId: selectedReasonForHire?.accountUId,
        StartLocation: {
          Name: startLoc.Name,
          Latitude: startLoc.Latitude,
          Longitude: startLoc.Longitude,
          StationIds: convertArrayToDictionary(startLoc.StationIds),
          PeopleSoftIds: convertArrayToDictionary(startLoc.PeopleSoftIds),
        },
        EndLocation: {
          Name: endLoc.Name,
          Latitude: endLoc.Latitude,
          Longitude: endLoc.Longitude,
          StationIds: convertArrayToDictionary(endLoc.StationIds),
          PeopleSoftIds: convertArrayToDictionary(endLoc.PeopleSoftIds),
        },
        StartDateTime: moment(
          formRef.current.values.dateTime.startDateTime
        ).format("YYYY-MM-DD[T]HH:mm:SS"),
        EndDateTime: moment(formRef.current.values.dateTime.endDateTime).format(
          "YYYY-MM-DD[T]HH:mm:SS"
        ),
        Loyalty: null,
      };
      console.log("Model: ", model);
      await axiosInstance
        .post(`/reservation/carclasses`, model)
        .then((response) => {
          console.log("carClasses data : ", response.data);
          setCarClassLoading(false);
          if (response.data.errors && response.data.errors.length > 0) {
            setEndLocInfo(undefined);
            dispatch(showErrorAlertMessage(response.data.errors));
            dispatch(broadcastAnnouncement(response.data.errors));
            setCarClassess(null);
            if (formRef.current)
              formRef.current.setFieldValue("carClass", null);
            window.scrollTo(0, 0);
          } else {
            setCarClassess(response.data);
            if (formRef.current)
              formRef.current.setFieldValue("carClass", response.data);
          }
          if (response.data.warnings && response.data.warnings.length > 0) {
            dispatch(showInfoAlertMessage(response.data.warnings));
            dispatch(broadcastAnnouncement(response.data.warnings));
            window.scrollTo(0, 0);
          }
        })
        .catch((error) => {
          dispatch(showErrorAlertMessage(t("UnexpectedError")));
          dispatch(broadcastAnnouncement(t("UnexpectedError")));
          setCarClassess(null);
          if (formRef.current) formRef.current.setFieldValue("carClass", null);
          console.error("There was an error!", error);
          setCarClassLoading(false);
          window.scrollTo(0, 0);
        });
    } else {
      setCarClassess(null);
      if (formRef.current) formRef.current.setFieldValue("carClass", null);
    }
  };

  const handlePhoneNumberChange = (
    selectedInternationalDiallingCode,
    number
  ) => {
    return "".concat(
      "(+",
      typeof selectedInternationalDiallingCode === "string" &&
        selectedInternationalDiallingCode !== "+"
        ? selectedInternationalDiallingCode
        : "",
      ")",
      number
    );
  };

  const getTimeValue = (dt, fieldType) => {
    let result = "0";
    if (dt) {
      let date = new Date(dt);
      var hours = ("0" + date.getHours()).slice(-2);
      var mins = ("0" + date.getMinutes()).slice(-2);
      var secs = "00";
      result = hours + ":" + mins + ":" + secs;
    } else result = startTime;

    if (fieldType === 0) setStartTime(result);
    else setEndTime(result);
    return result;
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={resdata}
      validateOnChange={true}
      validateOnBlur={false}
      validationSchema={formValidation}
      innerRef={formRef}
      onSubmit={async (values, { setFieldTouched, setSubmitting }) => {
        console.log("submitted values (reservationForm): ", values);
        dispatch(clearAlertMessage());
        let url = `/reservationwizard/reservation/${
          resType === "2" ? "rebook" : "validate"
        }`;
        appInsights.trackTrace({
          message: "sending DR request for validation",
          properties: { object: values, Component: "DR Reservation" },
          severityLevel: SeverityLevel.Information,
        });
        await axiosInstance
          .post(url, values)
          .then((res) => {
            setSubmitting(false);
            console.log("validated.. (reservationForm)", res.data.data);
            if (res.data.success) {
              dispatch(
                updateReservation({
                  reservation: res.data.data,
                  hasError: false,
                  errors: [],
                })
              );
              if (props.handleNext) props.handleNext(0, 0);
            } else {
              console.log("Validation Error", res.data.errors);
              let errorlist: string[] = [];
              if (Object.keys(res.data.errors).length > 0) {
                for (const [key, value] of Object.entries(res.data.errors)) {
                  if (
                    key === "unexpectedError" &&
                    Object.keys(res.data.errors).length > 1
                  )
                    // remove the exception error if there are more than 1 errors in the list
                    continue;
                  else errorlist.push(value as string);
                  //console.log(value)
                }
                dispatch(showErrorAlertMessage(errorlist));
                dispatch(broadcastAnnouncement(errorlist.join(". ")));
              }
            }
          })
          .catch((err) => {
            setSubmitting(false);
            if (err.response && err.response.data.messages) {
              if (Object.keys(err.response.data.errors).length > 0) {
                for (const [key, value] of Object.entries(
                  err.response.data.errors
                )) {
                }
                //  let errors = convertToFormikError(
                //   err.response.data.errors,
                //    t
                //  );
                //  console.log("ERRS: ", errors);
                //  setErrors(errors);
              }
              let errorlist = err.response.data.messages.filter(
                (n) => n !== ""
              );
              const translatedErrorlist = errorlist
                .map(function (value) {
                  return t(value);
                })
                .join("\r\n");
              dispatch(showErrorAlertMessage(translatedErrorlist));
              dispatch(broadcastAnnouncement(translatedErrorlist));
            } else {
              dispatch(
                showErrorAlertMessage("unable to save the journey." + err)
              );
              dispatch(
                broadcastAnnouncement("unable to save the journey." + err)
              );
            }

            appInsights.trackException({
              exception: err,
              properties: { method: "onSubmit", Component: "DR Reservation" },
              severityLevel: SeverityLevel.Error,
            });
          });
        window.scrollTo(0, 0);
      }}
    >
      {({
        values,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched,
        touched,
        handleChange,
        errors,
        isSubmitting,
      }) => (
        <Box
          boxShadow={3}
          p={3}
          bgcolor="background.paper"
          style={{ width: "100%" }}
        >
          {loading && resdata ? (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              {t("pleaseWait")} <br />
              <CircularProgress />
            </div>
          ) : (
            <Grid container>
              <div ref={titleRef} tabIndex={-1} className={classes.focused}>
                <Typography
                  variant="h1"
                  style={{
                    marginTop: 0,
                    marginBottom: "15px",
                  }}
                >
                  {t("ReservationWizard.reservationPageTitle")}
                </Typography>
              </div>
              <Backdrop className={classes.backdrop} open={validatingAccount}>
                <Typography variant="h1">
                  {t("journeyMethod.validatingAccountMessage")}
                </Typography>
              </Backdrop>
              {/* need to refine */}
              <Backdrop
                className={classes.backdrop}
                open={
                  loading || loadingAccount || carClassLoading || isSubmitting
                }
              >
                <CircularProgress />
              </Backdrop>
              <CompactJourneyDetailsView
                journeyUId={"00-00"}
                journeyDetails={props.journeyDetails}
              />
              <Grid item xs={12} style={{ marginTop: 10, color: "#AB0808" }}>
                <Form style={{ width: "100%" }}>
                  <span style={{ fontWeight: "bold", color: "#000000" }}>
                    {t("lblRequiedFields")}
                    {countriesToShowEmmision.includes(
                      locationsView?.startLocation.country ?? ""
                    )
                      ? t("lblRequiedCO2Fields")
                      : ""}
                  </span>

                  {resType === "1" && (
                    <Accordion
                      style={{ width: "100%" }}
                      defaultExpanded
                      id="sectionResNumber"
                    >
                      <h2>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                          }
                          aria-controls="panel-resnumber"
                          id="resNumber"
                          className={classes.heading}
                        >
                          {t(
                            "ReservationWizard.reservationPage.labelSectionResNumber"
                          )}
                        </AccordionSummary>
                      </h2>
                      <AccordionDetails>
                        <Grid container>
                          <Grid container direction="row">
                            <Grid
                              item
                              xs={12}
                              md={3}
                              className={classes.gridItem}
                            >
                              <label
                                htmlFor={"resNumber"}
                                className={classes.labelText}
                              >
                                {t(
                                  "ReservationWizard.reservationPage.labelResNumber"
                                )}
                              </label>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={9}
                              className={classes.gridItem}
                            >
                              <Field
                                name="reservationNumber"
                                id="reservationNumber"
                                value={values.reservationNumberString}
                                fullWidth
                                style={{ paddingBottom: 0, paddingTop: 0 }}
                                component={TextField}
                                variant="outlined"
                                disabled={true}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )}
                  <Accordion
                    style={{ width: "100%" }}
                    id="sectionStartEndDates"
                    defaultExpanded
                  >
                    <h2>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                        }
                        aria-controls="panel-startEndDates"
                        className={classes.heading}
                      >
                        {" "}
                        {t(
                          "ReservationWizard.reservationPage.labelSectionDateTime"
                        )}
                      </AccordionSummary>
                    </h2>
                    <AccordionDetails id="panel-startEndDates">
                      <Grid container>
                        <Grid
                          container
                          direction="row"
                          alignContent={"space-between"}
                        >
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.gridItem}
                          >
                            <label
                              htmlFor={"dateTime.startDate"}
                              className={classes.labelText}
                            >
                              {t(
                                "ReservationWizard.reservationPage.labelStartDate"
                              )}{" "}
                              *
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}
                          >
                            <Field
                              component={EtdDatePickerV2}
                              isPastDateAllowed={true}
                              daysPastDateAllowed={1}
                              label="Start date"
                              name="startDate"
                              id="dateTime.startDateTime"
                              localeDateTimeFormat={localeDateTimeFormat}
                              value={
                                values.dateTime.startDate
                                  ? getDateObjectFromShortString(
                                      values.dateTime.startDate
                                    )
                                  : null
                              }
                              errorMessageId="dateTime.startDateTime-helper-select"
                              handleChange={(e) => {
                                setIsDirty(true);
                                let newValue = e;
                                let newEndDate: string | null;
                                console.log("Start Value before format:", e);
                                const format =
                                  localeDateTimeFormat.toUpperCase();
                                let parsedDate = moment(newValue, format, true);
                                let isValidTime = startTime !== "0";
                                if (parsedDate.isValid()) {
                                  newValue =
                                    moment(parsedDate).format("YYYY-MM-DD");
                                  newEndDate = moment(parsedDate)
                                    .add(1, "days")
                                    .format("YYYY-MM-DD");
                                  // setFieldValue("dateTime.startDate", newValue);
                                } else {
                                  newValue = null;
                                  newEndDate = null;
                                }
                                console.log(
                                  "Start Value after format:",
                                  format,
                                  newValue
                                );
                                console.log(
                                  "End Value after format:",
                                  format,
                                  newEndDate
                                );
                                setStartDate(newValue);
                                if (
                                  newValue !== null &&
                                  parsedDate.isValid() &&
                                  isValidTime
                                ) {
                                  const hours = Number(startTime.split(":")[0]);
                                  const minutes = Number(
                                    startTime.split(":")[1]
                                  );
                                  parsedDate.set({
                                    hour: hours,
                                    minute: minutes,
                                  });

                                  const dtStart = moment(parsedDate).format(
                                    "YYYY-MM-DD[T]HH:mm:SS"
                                  );
                                  setFieldTouched(
                                    "dateTime.startDateTime",
                                    true
                                  );
                                  setFieldValue(
                                    "dateTime.startDateTime",
                                    dtStart
                                  );
                                } else {
                                  setFieldTouched(
                                    "dateTime.startDateTime",
                                    true
                                  );
                                  setFieldValue("dateTime.startDateTime", null);
                                }
                                if (newValue !== null) {
                                  setFieldTouched("dateTime.startDate", true);
                                  setFieldValue("dateTime.startDate", newValue);
                                } else {
                                  setFieldTouched("dateTime.startDate", true);
                                  setFieldValue("dateTime.startDate", null);
                                }

                                if (
                                  !endDateChangedDirectly &&
                                  resType === "2"
                                ) {
                                  setFirstTimeSdSet(firstTimeSdSet + 1);
                                  setFieldTouched("dateTime.endDate", true);
                                  setFieldValue("dateTime.endDate", newEndDate);

                                  let dtEnd: string;
                                  if (
                                    newEndDate !== null &&
                                    parsedDate.isValid() &&
                                    isValidTime
                                  ) {
                                    const hours = Number(endTime.split(":")[0]);
                                    const minutes = Number(
                                      endTime.split(":")[1]
                                    );
                                    parsedDate.set({
                                      hour: hours,
                                      minute: minutes,
                                    });

                                    dtEnd = moment(parsedDate)
                                      .add(1, "days")
                                      .format("YYYY-MM-DD[T]HH:mm:SS");

                                    setTimeout(() => {
                                      setFieldTouched(
                                        "dateTime.endDateTime",
                                        true
                                      );
                                      setFieldValue(
                                        "dateTime.endDateTime",
                                        dtEnd
                                      );
                                    }, 50);
                                  } else {
                                    setFieldTouched(
                                      "dateTime.endDateTime",
                                      true
                                    );
                                    setFieldValue("dateTime.endDateTime", null);
                                  }
                                  setFieldValue(
                                    "dateTime.startTime",
                                    startTime === "0" ? "12:00:00" : startTime
                                  );
                                  setFieldValue(
                                    "dateTime.endTime",
                                    startTime === "0" ? "12:00:00" : endTime
                                  );
                                }

                                console.log(
                                  "Start Value onChange finished without error"
                                );
                              }}
                              handleBlur={(e) => {
                                setFieldTouched("dateTime.startDate", true);
                              }}
                              isError={
                                touched.dateTime?.startDateTime &&
                                errors &&
                                errors.dateTime?.startDate
                                  ? true
                                  : false
                              }
                            />
                            {touched.dateTime?.startDateTime &&
                            errors &&
                            errors.dateTime?.startDate ? (
                              <span
                                id="dateTime.startDateTime-helper-select"
                                className={classes.errorWarning}
                              >
                                {firstTimeSdSet === 1
                                  ? ""
                                  : t(errors.dateTime?.startDate)}
                              </span>
                            ) : (
                              <span id="dateTime.startDateTime-helper-select"></span>
                            )}
                          </Grid>
                          <Grid item xs={12} md={2}></Grid>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.gridItem}
                          >
                            <label
                              htmlFor={"dateTime.startTime"}
                              className={classes.labelText}
                            >
                              {t(
                                "ReservationWizard.reservationPage.labelStartTime"
                              )}{" "}
                              *
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}
                          >
                            <Field
                              id="dateTime.startTime"
                              name={"dateTime.startTime"}
                              fieldName={"dateTime.startTime"}
                              component={EtdTimePicker}
                              format={t("TimeFormat")}
                              defaultValue={startTime}
                              errorMessageId={
                                "dateTime.startTime-helper-select"
                              }
                              onChange={(e) => {
                                setIsDirty(true);
                                let time = e.target.value;

                                if (time === "0") {
                                  setFieldValue("dateTime.startDateTime", null);
                                  setFieldTouched(
                                    "dateTime.startDateTime",
                                    true
                                  );
                                } else {
                                  let parsedDate = moment(
                                    values.dateTime.startDate,
                                    "YYYY-MM-DD",
                                    true
                                  );
                                  if (parsedDate.isValid()) {
                                    const hours = Number(time.split(":")[0]);
                                    const minutes = Number(time.split(":")[1]);
                                    parsedDate.set({
                                      hour: hours,
                                      minute: minutes,
                                    });

                                    setFieldValue(
                                      "dateTime.startDateTime",
                                      moment(parsedDate).format(
                                        "YYYY-MM-DD[T]HH:mm:SS"
                                      )
                                    );
                                    setFieldTouched(
                                      "dateTime.startDateTime",
                                      true
                                    );
                                  }
                                }
                                setFieldTouched("dateTime.startTime", true);
                                setFieldValue("dateTime.startTime", time);
                                setStartTime(time);
                              }}
                            />
                            {touched.dateTime?.startTime &&
                            errors &&
                            errors.dateTime?.startTime ? (
                              <span
                                id="dateTime.startTime-helper-select"
                                className={classes.errorWarning}
                              >
                                {t(errors.dateTime?.startTime)}
                              </span>
                            ) : (
                              <span id="dateTime.startTime-helper-select"></span>
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="row"
                          alignContent={"space-between"}
                        >
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.gridItem}
                          >
                            <label
                              htmlFor={"dateTime.endDate"}
                              className={classes.labelText}
                            >
                              {t(
                                "ReservationWizard.reservationPage.labelEndDate"
                              )}{" "}
                              *
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}
                          >
                            <Field
                              component={EtdDatePickerV2}
                              isPastDateAllowed={true}
                              daysPastDateAllowed={1}
                              label="End date"
                              name="endDate"
                              id="dateTime.endDate"
                              localeDateTimeFormat={localeDateTimeFormat}
                              value={
                                values.dateTime.endDate
                                  ? getDateObjectFromShortString(
                                      values.dateTime.endDate
                                    )
                                  : null
                              }
                              errorMessageId={"dateTime.endDate-helper-select"}
                              handleChange={(e) => {
                                setEndDateChangedDirectly(true);
                                setIsDirty(true);
                                let newValue = e;
                                const format =
                                  localeDateTimeFormat.toUpperCase();
                                let parsedDate = moment(newValue, format, true);
                                let isValidTime = endTime !== "0";
                                if (parsedDate.isValid()) {
                                  newValue =
                                    moment(parsedDate).format("YYYY-MM-DD");
                                  //  setFieldValue("dateTime.endDate", newValue);
                                } else {
                                  newValue = null;
                                  // setFieldValue(
                                  //   "dateTime.endDate",
                                  //   "Invalid Date"
                                  // );
                                }

                                setEndDate(newValue);

                                if (
                                  newValue !== null &&
                                  parsedDate.isValid() &&
                                  isValidTime
                                ) {
                                  const hours = Number(endTime.split(":")[0]);
                                  const minutes = Number(endTime.split(":")[1]);
                                  parsedDate.set({
                                    hour: hours,
                                    minute: minutes,
                                  });

                                  const dt = moment(parsedDate).format(
                                    "YYYY-MM-DD[T]HH:mm:SS"
                                  );

                                  setFieldTouched("dateTime.endDateTime", true);
                                  setFieldValue("dateTime.endDateTime", dt);
                                } else {
                                  setFieldTouched("dateTime.endDateTime", true);
                                  setFieldValue("dateTime.endDateTime", null);
                                }

                                if (newValue !== null) {
                                  setFieldTouched("dateTime.endDate", true);
                                  setFieldValue("dateTime.endDate", newValue);
                                } else {
                                  setFieldTouched("dateTime.endDate", true);
                                  setFieldValue("dateTime.endDate", null);
                                }
                              }}
                              handleBlur={(e) => {
                                setFieldTouched("dateTime.endDate", true);
                              }}
                              isError={
                                touched.dateTime?.endDateTime &&
                                errors &&
                                errors.dateTime?.endDate
                                  ? true
                                  : false
                              }
                            />
                            {touched.dateTime?.endDateTime &&
                            errors &&
                            errors.dateTime?.endDate ? (
                              <span
                                id="dateTime.endDate-helper-select"
                                className={classes.errorWarning}
                              >
                                {t(errors.dateTime?.endDate)}
                              </span>
                            ) : (
                              <span id="dateTime.endDate-helper-select"></span>
                            )}
                            {authService.getDriverInfo()
                              .isBOBOToggleEnabledAndIsDriverDeclaration &&
                            errors.dateTime?.endDate?.endsWith(
                              "msgInvalidDriver"
                            ) ? (
                              qsType === "1" ? (
                                !touched.dateTime?.endDateTime && (
                                  <span
                                    id="dateTime.endDate-helper-select"
                                    className={classes.errorWarning}
                                  >
                                    {t(
                                      "ReservationWizard.reservationPage.msgInvalidDriver"
                                    )}
                                  </span>
                                )
                              ) : (
                                <Grid container justifyContent="center">
                                  <Button
                                    id="btnStartAgain"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                      history(
                                        "/home?id=" +
                                          resdata.journeyProduct?.uId +
                                          "&isStartAgain=1"
                                      );
                                    }}
                                    type="submit"
                                    style={{
                                      marginTop: "0.5em",
                                      textTransform: "none",
                                    }}
                                  >
                                    {t(
                                      "ReservationWizard.reservationPage.btnStartAgain"
                                    )}
                                  </Button>
                                </Grid>
                              )
                            ) : (
                              <span></span>
                            )}
                          </Grid>
                          <Grid item xs={12} md={2}></Grid>
                          <Grid
                            item
                            xs={12}
                            md={2}
                            className={classes.gridItem}
                          >
                            <label
                              htmlFor={"dateTime.endTime"}
                              className={classes.labelText}
                            >
                              {t(
                                "ReservationWizard.reservationPage.labelEndTime"
                              )}{" "}
                              *
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}
                          >
                            <Field
                              component={EtdTimePicker}
                              format={t("TimeFormat")}
                              label="End Time"
                              id="dateTime.endTime"
                              name="dateTime.endTime"
                              fieldName="dateTime.endTime"
                              defaultValue={endTime}
                              errorMessageId={"dateTime.endTime-helper-select"}
                              onChange={(e) => {
                                setIsDirty(true);
                                let time = e.target.value;

                                if (time === "0") {
                                  setFieldValue("dateTime.endDateTime", null);
                                  setFieldTouched("dateTime.endDateTime", true);
                                } else {
                                  let parsedDate = moment(
                                    values.dateTime.endDate,
                                    "YYYY-MM-DD",
                                    true
                                  );
                                  if (parsedDate.isValid()) {
                                    const hours = Number(time.split(":")[0]);
                                    const minutes = Number(time.split(":")[1]);
                                    parsedDate.set({
                                      hour: hours,
                                      minute: minutes,
                                    });
                                    setFieldValue(
                                      "dateTime.endDateTime",
                                      moment(parsedDate).format(
                                        "YYYY-MM-DD[T]HH:mm:SS"
                                      )
                                    );
                                    setFieldTouched(
                                      "dateTime.endDateTime",
                                      true
                                    );
                                  }
                                }
                                setFieldTouched("dateTime.endTime", true);
                                setFieldValue("dateTime.endTime", time);

                                setEndTime(time);
                              }}
                              placeholder="End time"
                            />
                            {touched.dateTime?.startTime &&
                            errors &&
                            errors.dateTime?.endTime ? (
                              <span
                                id="dateTime.endTime-helper-select"
                                className={classes.errorWarning}
                              >
                                {t(errors.dateTime?.endTime)}
                              </span>
                            ) : (
                              <span id="dateTime.endTime-helper-select"></span>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    style={{ width: "100%" }}
                    defaultExpanded
                    id="sectionAccounts"
                  >
                    <h2>
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                        }
                        aria-controls="panel-accounts"
                        id="accountSummary"
                        className={classes.heading}
                      >
                        {isNullOrWhitespace(
                          values.reasonForHire.reasonForHireLabel
                        )
                          ? t(
                              "ReservationWizard.reservationPage.labelSectionAccounts"
                            )
                          : values.reasonForHire.reasonForHireLabel}
                      </AccordionSummary>
                    </h2>
                    <AccordionDetails id="panel-accounts">
                      <Grid container>
                        <Grid container direction="row">
                          <Grid
                            item
                            xs={12}
                            md={3}
                            className={classes.gridItem}
                          >
                            <label
                              htmlFor={"reasonForHire"}
                              className={classes.labelText}
                            >
                              {t("Res.DisplayName")} *
                            </label>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={9}
                            className={classes.gridItem}
                          >
                            <Select
                              native
                              value={
                                values.reasonForHire.accountUId
                                  ? values.reasonForHire.accountUId
                                  : ""
                              }
                              fullWidth
                              inputProps={{
                                id: "reasonForHire",
                                name: "reasonForHire",
                                "aria-label": t("Res.DisplayName"),
                                "aria-haspopup": "listbox",
                              }}
                              title={t("Res.DisplayName")}
                              name="reasonForHire"
                              aria-describedby={"reasonForHireError"}
                              error={
                                errors["reasonForHire"] !== undefined &&
                                touched.reasonForHire?.accountUId
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                setIsDirty(true);
                                setaccountChanged(true);
                                setFieldValue(
                                  "reasonForHire.accountUId",
                                  e.target.value
                                );
                                setCarClassess(null);
                                setFieldValue("carClass", null);
                                validateAccount(e.target.value, true);
                                if (existingRes) dispatch(resetReservation());
                              }}
                              onBlur={(e) => {
                                setFieldTouched("reasonForHire.accountUId");
                              }}
                              disabled={resType === "1" || resType === "2"}
                            >
                              {values.reasonForHire &&
                                values.reasonForHire.availableReasonsForHire &&
                                values.reasonForHire.availableReasonsForHire?.map(
                                  (opt: any) => (
                                    <option key={opt.value} value={opt.value}>
                                      {opt.text}
                                    </option>
                                  )
                                )}
                            </Select>
                            {errors.reasonForHire?.accountUId &&
                            touched.reasonForHire?.accountUId ? (
                              <span
                                id="reasonForHireError"
                                className={classes.errorWarning}
                              >
                                {t(errors?.reasonForHire?.accountUId ?? "")}
                              </span>
                            ) : (
                              <span id="reasonForHireError"></span>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {!loadingAccount &&
                    locationsView &&
                    selectedReasonForHire && (
                      <>
                        <Field
                          component={JourneyStartEndMethods}
                          label="Journey Start"
                          name="startLocation"
                          placeholder="Journey Start"
                          locationView={locationsView}
                          reasonForHire={selectedReasonForHire}
                          setStartLocInfo={setStartLocInfo}
                          setEndLocInfo={setEndLocInfo}
                          setStartMethod={setStartMethod}
                          setEndMethod={setEndMethod}
                          startLocInfo={startLocInfo}
                          setIsDirty={setIsDirty}
                          initialLocation={initialLocation}
                          setlocationsView={setlocationsView}
                          useStartAsEndLocation={
                            journeyprofile?.useStartAsEndLocation
                          }
                          locationDestination={
                            journeyprofile?.allPoints[0].destination
                          }
                          startLocationDestination={
                            journeyprofile?.allPoints[0].destination
                          }
                          endLocationDestination={
                            journeyprofile?.allPoints[
                              journeyprofile?.allPoints.length - 1
                            ].destination
                          }
                          isExistingRes={
                            (existingRes &&
                              existingRes.reservation !== undefined &&
                              existingRes !== null) ||
                            resType === "1" ||
                            resType === "2"
                          }
                          isReadOnly={resType === "1" || resType === "2"}
                          journeyUId={props.journeyUId}
                          reservationDate={values.dateTime.startDateTime}
                          reservationStartDate={values.dateTime.startDateTime}
                          reservationEndDate={values.dateTime.endDateTime}
                          isDateChanged={validateDate()}
                          errors={errors.locations}
                          updateCarClass={getCarClasses}
                        />
                      </>
                    )}
                  {!loadingAccount &&
                    selectedReasonForHire &&
                    // startMethod &&
                    // endMethod &&
                    values.carClass &&
                    values.carClass.carsInformation?.classInfo.length > 0 && (
                      <>
                        <Accordion
                          style={{ width: "100%" }}
                          id="carClasses"
                          defaultExpanded
                        >
                          <h2>
                            <AccordionSummary
                              expandIcon={
                                <ExpandMoreIcon sx={{ color: "#FFFFFF" }} />
                              }
                              aria-controls="carClassesSummary"
                              id="carClassesSummary"
                              className={classes.heading}
                            >
                              {t(
                                "ReservationWizard.reservationPage.labelSectionVehciles"
                              )}
                            </AccordionSummary>
                          </h2>
                          <AccordionDetails>
                            {carClassLoading ? (
                              <div style={{ width: "100%" }}>
                                <LinearProgress color="primary" />
                              </div>
                            ) : (
                              <div
                                role="feed"
                                aria-label={"car classes"}
                                style={{
                                  alignItems: "center",
                                  verticalAlign: "middle",
                                  width: "100%",
                                  maxHeight: isSmXs ? "auto" : "500px",
                                  overflowY: "scroll",
                                  textAlign: "center",
                                }}
                              >
                                {values.carClass?.errors?.map((err, i) => (
                                  <span>{err}</span>
                                ))}
                                {values.carClass?.carsInformation?.classInfo?.map(
                                  (car, i) => (
                                    <div
                                      role="article"
                                      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                                      tabIndex={0}
                                      key={i}
                                      style={{ marginTop: "1em" }}
                                    >
                                      <Field
                                        component={EtdCarClasses}
                                        name={"vehicles" + i}
                                        carInfo={car}
                                        handleCarSelect={handleCarClassSelect}
                                        handleCarExtras={handleCarExtras}
                                        carKey={car.uniqueKey}
                                        mainIndex={i}
                                        showEmission={countriesToShowEmmision.includes(
                                          locationsView?.startLocation
                                            .country ?? ""
                                        )}
                                        locale={locale}
                                      />
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "1em",
                    }}
                  >
                    <Button
                      id="btnBack"
                      color="primary"
                      aria-label={t("lblBackToPreviousStep")}
                      variant="contained"
                      onClick={() => {
                        dispatch(clearAlertMessage());
                        if (props.handleBack) props.handleBack(0, 0);
                      }}
                    >
                      {t("btnBack")}
                    </Button>
                    <Button
                      id="btnSubmit"
                      color="primary"
                      variant="contained"
                      aria-label={t("lblContinueToNextStep")}
                      disabled={!isValid || !dirty}
                      type="submit"
                    >
                      {t("btnContinue")}
                    </Button>
                  </div>
                </Form>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </Formik>
  );
};

export default ReservationForm;
