import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { clearAlertMessage } from "components/store/actions/shared/alertActions";
import { useDispatch, useSelector } from "react-redux";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { authService } from "../../../services/authService";
import MyProfile from "./myprofile";
import MyCCProfile from "./myCCprofile";
import { useStickyState } from "../../../helpers/useStickyState";
import { AppState } from "components/store/reducers/rootReducer";
import axiosInstance from "helpers/axiosInstance";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    "& .MuiTab-wrapper": {
      alignItems: "flex-end",
      marginRight: 10,
      marginTop: 10,
      marginBottom: 10,
    },
    "& .MuiTab-root": {
      lineHeight: 2,
      fontWeight: "bold",
      fontSize: "0.9rem",
      textTransform: "none",
      minWidth: "100px",
    },
  },
  tabs: {
    //borderRight: `1px solid black`,
    overflow: "visible",
    minWidth: "110px",
    marginRight: 20,
  },
  button: { textTransform: "none" },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} bgcolor="background.paper">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function AllProfiles() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useStickyState(0, "profileTabSelected"); // React.useState(0);
  const divRef = React.useRef<HTMLElement>(null);
  const profileRef = React.useRef<HTMLDivElement>(null);
  const announcement = useSelector((state: AppState) => state.announcement.announcement);

  const takoApiEnv = authService.getTakoApiEnv();
  const isCarShareReservation = String(takoApiEnv).toUpperCase().includes("CS");

  React.useEffect(() => {
    document.title = t("basePageTitle") + t("Users.myprofilePageTitle");
    dispatch(clearAlertMessage());
    dispatch(broadcastAnnouncement(t("Users.AccountSettings")));

  }, []);

  const handleChange = (event, newValue) => {
    dispatch(clearAlertMessage());
    setValue(newValue);    
  };

  return (
    <Grid container>
      
      <Grid item xs={12} md={12} sx={{paddingLeft: "1rem", paddingBottom: "1rem"}}>
        <Typography id="titleHeader" ref={divRef} variant="h1" tabIndex={-1}>
          {announcement}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        className={classes.root}
        style={{ display: "flex", minWidth: "60" }}
      >
        <Tabs
          id="profileManagmentTabs"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          //aria-label="Profile Management Tabs"
          className={classes.tabs}
          textColor="primary"
          indicatorColor="primary"
          style={{ width: "100%" }}
        >
          <Tab
            ref={profileRef}
            style={value !== 0 ? { color: "black" } : {}}
            label={t("MyProfile")}
            {...a11yProps(0)}
          />
          <Tab
            style={value !== 1 ? { color: "black" } : {}}
            label={isCarShareReservation ? t("MyCSProfile") : t("MyCCProfile")}
            {...a11yProps(1)}
          />
        </Tabs>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TabPanel value={value} index={0}>
          <MyProfile />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <MyCCProfile />
        </TabPanel>
      </Grid>
    </Grid>
  );
}
