export function isWithin12Months (endDateString) {
    const endDate = new Date(endDateString);
    // Get the current date and add 12 months to it
    const currentDate = new Date();
    currentDate.setFullYear(currentDate.getFullYear() + 1);
    // Compare the parsed date with the current date + 12 months
    if(endDate <= currentDate){
      return true;
    }
    console.log("The end date is not within 12 months")
    return false;
  }
  
  export function isWithin28Days (startDateString, endDateString){  
    const startDate = new Date(startDateString)
    const endDate = new Date(endDateString)
    // Get the difference in milliseconds
    const diffInMs = Math.abs(endDate.getTime() - startDate.getTime());
    // Convert milliseconds to days and round down to whole days
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    // Check if the difference is less than 28 days
    if(diffInDays <= 28){
      return true;
    }
    console.log("Duration more than > 28")
    return false;
  }
  
  
  const arrayOfCountriesForInternationalReservation = ['usa', 'canada' ]
  export function isInUSAorCanada(startLocation){
    const countryName = getStartLocationCountryName(startLocation);
    const result = arrayOfCountriesForInternationalReservation.some(countryInList => countryName.toLowerCase() === countryInList);
    return result;
    }
  
  export function getStartLocationCountryName(startLocation){
   const elements = startLocation.split(",")
   return elements[elements.length - 1].trim();
  }