import React, { useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import {
  CarClubDriverContactInfo,
  CarClubDriverContactinfoInitialValue,
} from "../usertype";
import { useNavigate } from "react-router-dom";
import CarClubLogo from "Assets/Images/CarClubLogo.png";
import CarShareLogo from "Assets/Images/CarShareLogo.png";
import CCProduct from "Assets/Images/CCProduct.png";
import CSProduct from "Assets/Images/CSProduct.png";
import {
  Box,
  Backdrop,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Divider,
  Link,
} from "@mui/material";
import axiosInstance from "../../../helpers/axiosInstance";

import { clearAlertMessage } from "../../store/actions/shared/alertActions";
import { useDispatch } from "react-redux";
import { authService } from "services/authService";
import { useMsal } from "@azure/msal-react";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { DarkerDisabledTextField } from "components/Reservation/steps/Products/productSelectionForm";
import {
  ReAuthenticateCarClub,
  reAuthenticationStatus,
} from "services/carClubReAuth";
import MyCCprofilePersonalInfo from "./myCCprofilePersonalInfo";
import { CarClubHome } from "../../home/carClubHome";
import { ChangeCCPinPassword } from "../../admin/changeCCPinPassword";
import {
  CCPasswordChange,
  CCPinChange,
  InitialPasswordValue,
  InitialPinValue,
} from "../ccProfileType";
import PasswordValidationRules from "../passwordValidationRules";

const useStyles = makeStyles({
  root: {
    "& .MuiFormHelperText-root": {
      color: "#DF0000",
      fontSize: 12,
      marginLeft: "15px",
    },
    "& ::placeholder": {
      fontStyle: "italic",
      color: "#000",
      opacity: 0.95,
      whiteSpace: "pre-wrap",
      "@media (max-width:330px)": {
        fontSize: "0.6rem",
      },
    },
    "& .MuiTypography-h1": {
      color: "red",
      fontSize: 14,
      marginLeft: "15px",
    },
  },
  img: {
    margin: "15px",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    "&:focus": {
      border: "3px solid #006639",
    },
  },

  inputRoot: {
    fontSize: 30,
  },
  labelRoot: {
    fontSize: 30,
    color: "red",
    "&$labelFocused": {
      color: "purple",
    },
  },
  errorWarning: {
    color: "#DF0000",
    fontSize: 12,
    marginLeft: "15px",
  },
  backdrop: {
    zIndex: 99999,
    color: "#fff",
  },
  labelFocused: {},
});

export default function MyCCProfile() {
  const [loading, setLoading] = useState(false);
  const [driverContactInfo, setDriverContactInfo] =
    useState<CarClubDriverContactInfo>(CarClubDriverContactinfoInitialValue);

  const [ccPasswordDetail, setCCPasswordDetail] =
    useState<CCPasswordChange>(InitialPasswordValue);
  const [ccPinDetail, setCCPinDetail] = useState<CCPinChange>(InitialPinValue);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const classes = useStyles();

  const takoApiEnv = authService.getTakoApiEnv();
  const isCarShareReservation = String(takoApiEnv).toUpperCase().includes("CS");

  const [ccMemberId, setccMemberId] = React.useState(
    authService.getUserCarClubCompanyId()
  );
  const [ccDriverId, setccDriverId] = React.useState(
    authService.getUserDriverId()
  );

  const [isForgotPassword, setIsForgotPassword] = React.useState(false);

  const [isCCLoggedIn, setIsCCLoggedIn] = useState(
    JSON.parse(localStorage.getItem("ccLoggedIn") ?? "false") ?? []
  );

  useEffect(() => {
    window.localStorage.setItem("ccLoggedIn", JSON.stringify(isCCLoggedIn));
  }, [isCCLoggedIn]);

  const setNullValuesToEmpty = (obj) =>
    JSON.parse(JSON.stringify(obj, (k, v) => (v === null ? "" : v)));

  const getCCProfileDetail = async () => {
    setLoading(true);
    const result = await axiosInstance.get("/carclub/getdrivercontactinfo");
    setDriverContactInfo(setNullValuesToEmpty(result.data.data));
    console.log("getdrivercontactinfo=", result.data);
    setLoading(false);
  };

  const onPersonalInfoChange = useCallback(
    (personalInfo: CarClubDriverContactInfo) => {
      setDriverContactInfo(personalInfo);
    },
    []
  );

  useEffect(() => {
    document.title = t("basePageTitle") + t("Users.myprofilePageTitle");
    dispatch(clearAlertMessage());
    console.log(
      "current user logged in?",
      isCCLoggedIn,
      "isccReauthExpired",
      authService.getUserccReauthExpired(),
      "isccPersisted",
      authService.getUserccPersisted()
    );
    if (isCCLoggedIn) getCCProfileDetail(); //reAuthCC();
  }, []);

  return !isCCLoggedIn ? (
    <CarClubHome redirectTo="" focusCCPwd={true} hideCCTile={false} />
  ) : (
    <Box id="viewUserBox" boxShadow={3} p={3} bgcolor="background.paper">
      <Grid container>
        <Backdrop
          className={classes.backdrop}
          open={loading /* || isSubmitting */}
        >
          <CircularProgress />
        </Backdrop>
        <Grid item xs={12} md={12} container>
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingBottom: 0, paddingTop: 10 }}
            container
            direction="row"
          >
            <Grid
              item
              style={{
                minWidth: 100,
                maxWidth: 200,
                padding: 0,
              }}
            >
              <Typography>
                <img
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    marginBottom: "10px",
                  }}
                  alt="Enterprise Car Club"
                  id={"CarClubLogoImage"}
                  src={isCarShareReservation ? CarShareLogo : CarClubLogo}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <img
                className={classes.img}
                alt="" //{product.productName}
                src={isCarShareReservation ? CSProduct : CCProduct}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            style={{ paddingBottom: 15, paddingTop: 10 }}
          >
            <Typography style={{ fontWeight: "bold" }}>
              {t("Res.carClubMemberId")}
            </Typography>
          </Grid>
          <Grid
            item
            container
            spacing={3}
            /* xs={12}
              md={12} */
            style={{
              paddingBottom: 0,
              paddingTop: 10,
              marginBottom: "1em",
            }}
          >
            <Grid
              item
              xs={12}
              md="auto"
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                paddingRight: 0,
                marginBottom: "1em",
              }}
            >
              <DarkerDisabledTextField
                size="small"
                id="carClubMemberIdTextBox"
                defaultValue={ccMemberId}
                disabled={true}
                inputProps={{
                  "aria-label": t("Res.carClubMemberId"),
                  "aria-required": true,
                }}
                required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={1}
              md="auto"
              style={{
                paddingBottom: 0,
                paddingTop: 10,
                paddingLeft: 4,
                paddingRight: 8,
                maxWidth: 20,
                minWidth: 10,
              }}
            >
              <Typography variant="body1" ><strong> {"-"}</strong></Typography>
            </Grid>
            <Grid
              item
              xs={6}
              md="auto"
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                paddingLeft: 0,
                marginBottom: "1em",
              }}
            >
              <DarkerDisabledTextField
                size="small"
                id="carClubDriverIdTextBox"
                defaultValue={ccDriverId}
                disabled={true}
                inputProps={{
                  "aria-label": t("Res.carClubDriverId"),
                  "aria-required": true,
                }}
                required
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md="auto"
              style={{ paddingTop: 0, paddingLeft: 15, marginBottom: "1em" }}
            >
              <Button
                variant="contained"
                style={{ textTransform: "uppercase" }}
                onClick={() => {
                  authService.setUserccLogout();
                  setIsCCLoggedIn(false);
                  console.log(
                    "current user logged in?",
                    isCCLoggedIn,
                    "isccReauthExpired",
                    authService.getUserccReauthExpired(),
                    "isccPersisted",
                    authService.getUserccPersisted()
                  );
                }}
                disabled={false}
                color="primary"
                id="logouthyperlink"
              >
                {t("Logout")}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md="auto"
              style={{ paddingTop: 6, paddingLeft: 15, marginBottom: "1em" }}
            >
              <Link
                id="carclubresetpwhyperlink"
                //target="_blank"
                rel="noopener"
                underline="always"
                component="button"
                onClick={() => {
                  setIsForgotPassword(!isForgotPassword); //Toggle
                  console.log("cc user reset pw");
                }}
                /* href={
                    product.companyCarShareResetPasswordLink
                  } */
                display="inline"
                variant="body2"
                style={{ fontSize: "1.2em" }}
              >
                {!isForgotPassword
                  ? t("ChangePasswordPin")
                  : t("UpdateProfile")}
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Divider
          style={{
            //marginTop: 20,
            backgroundColor: "black",
            height: "0.8px",
          }}
        />
      </Grid>
      {!isForgotPassword ? (
        <Grid item xs={12} md={6} container>
          <MyCCprofilePersonalInfo
            driverContactInfo={driverContactInfo}
            ccMemberDriverId={ccMemberId.concat("-", ccDriverId)}
            onDataChange={onPersonalInfoChange}
          />
        </Grid>
      ) : (
        <Grid item xs={12} md={12} container>
          <Grid item xs={12} md={6} container>
            <ChangeCCPinPassword
              passwordValues={ccPasswordDetail}
              pinValues={ccPinDetail}
              ccMemberDriverId={ccMemberId.concat("-", ccDriverId)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <PasswordValidationRules />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
