import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { CarClubDriverContactInfo } from "../usertype";
import { useNavigate } from "react-router-dom";
import axiosInstance from "helpers/axiosInstance";
import {
  clearAlertMessage,
  showErrorAlertMessage,
  showSuccessAlertMessage,
} from "components/store/actions/shared/alertActions";
import { broadcastAnnouncement } from "components/store/actions/shared/announcementActions";
import { appInsights } from "helpers/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { useDispatch } from "react-redux";
import { convertToFormikError } from "./myprofile";
import _ from "lodash";

const personalInfoValidationSchema = Yup.object({
  firstName: Yup.string()
    .required("Users.Errors.firtNameRequired")
    .max(20, "Users.Errors.firstNameMaxLength")
    .nullable(),
  lastName: Yup.string()
    .required("Users.Errors.lastNameRequired")
    .max(30, "Users.Errors.lastNameMaxLength")
    .nullable(),
  email: Yup.string()
    //.required("Users.Errors.emailRequired")
    .email("Users.Errors.invalidEmail")
    .max(100, "Users.Errors.emailMaxLength")
    .nullable(),
  homePhone: Yup.string()
    .notRequired()
    .min(4, "Users.Errors.ccProfilePhoneNumbeMaxLength")
    .max(17, "Users.Errors.ccProfilePhoneNumbeMaxLength")
    .nullable()
    .test("homePhone", "Users.Errors.phoneNumberIncorrect", (value) => {
      let exp = /^[0-9\+\-\/]*$/;
      if (value !== undefined && value !== null && !!value) {
        return exp.test(value);
      }
      return true;
    }),
  workPhone: Yup.string()
    .notRequired()
    .min(4, "Users.Errors.ccProfilePhoneNumbeMaxLength")
    .max(17, "Users.Errors.ccProfilePhoneNumbeMaxLength")
    .nullable()
    .test("workPhone", "Users.Errors.phoneNumberIncorrect", (value) => {
      let exp = /^[0-9\+\-\/]*$/;
      if (value !== undefined && value !== null && !!value) {
        return exp.test(value);
      }
      return true;
    }),
  mobilePhone: Yup.string()
    .notRequired()
    .min(4, "Users.Errors.ccProfilePhoneNumbeMaxLength")
    .max(17, "Users.Errors.ccProfilePhoneNumbeMaxLength")
    .nullable()
    .test("mobilePhone", "Users.Errors.phoneNumberIncorrect", (value) => {
      let exp = /^[0-9\+\-\/]*$/;
      if (value !== undefined && value !== null && !!value) {
        return exp.test(value);
      }
      return true;
    }),
});

const MyCCprofilePersonalInfo: React.FC<{
  driverContactInfo: CarClubDriverContactInfo;
  ccMemberDriverId: string;
  onDataChange: (personalInfo: CarClubDriverContactInfo) => void;
}> = ({ driverContactInfo, ccMemberDriverId, onDataChange }) => {
  const { t } = useTranslation();
  let history = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiFormHelperText-root": {
        color: "#DF0000",
        fontSize: 12,
        marginLeft: "15px",
      },
      "& ::placeholder": {
        fontStyle: "italic",
        color: "#000",
        opacity: 0.95,
        whiteSpace: "pre-wrap",
        "@media (max-width:330px)": {
          fontSize: "0.6rem",
        },
      },
      "& .MuiTypography-h1": {
        color: "red",
        fontSize: 14,
        marginLeft: "15px",
      },
    },
    gridItem: {
      verticalAlign: "center",
      paddingBottom: 0,
      paddingTop: 10,
      "& .MuiTypography-body2": {
        fontWeight: "bold",
        fontSize: "0.875rem",
      },
    },
    heading: {
      fontSize: "1rem",
      fontWeight: "bold",
    },
    labelText: {
      color: "#000",
      fontFamily: "Arial, Roboto, Segoe UI",
      fontSize: "0.875rem",
      fontWeight: "bold",
      verticalAlign: "middle",
    },
    errorWarning: {
      color: "#DF0000",
      fontSize: "0.75rem",
      marginLeft: "15px",
    },
    backdrop: {
      zIndex: 99999,
      color: "#fff",
    },
    textarea: {
      resize: "both",
    },
    focused: { outline: "none" },
    placeHolder: {
      "& ::placeholder": {
        color: "#767676",
        opacity: "1",
      },
    },
  }));
  const classes = useStyles();

  const handleCancel = () => {
    history("/home");
  };

  useEffect(() => {
    dispatch(clearAlertMessage());
  }, []);

  return (
    <Box id="personalInfoBox" boxShadow={3} p={3} bgcolor="background.paper">
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      <Formik
        initialValues={driverContactInfo}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize={true}
        validationSchema={personalInfoValidationSchema}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          dispatch(clearAlertMessage());
          setLoading(true);
          await axiosInstance
            .post("/carclub/updatedrivercontactinfo", values)
            .then((response) => {
              setSubmitting(false);
              if (response.data.success) {
                appInsights.trackEvent({
                  name: "My CC Profile Updated",
                  properties: { CCMemberDriverId: ccMemberDriverId },
                });
                dispatch(
                  showSuccessAlertMessage(t("Users.updateCCProfileSuccess"))
                );
                onDataChange(values);
                /* dispatch(
                  broadcastAnnouncement(
                    t("Users.updateCCProfileSuccess")
                  )
                ); */
              }
              //errors in updating profile
              else {
                let message = _.camelCase(response.data.message);
                message = t(`Users.Errors.${message}`);
                dispatch(showErrorAlertMessage(message));
                dispatch(broadcastAnnouncement(message));
              }
              window.scrollTo(0, 0);
            })
            .catch((err) => {
              setSubmitting(false);
              if (err.response && err.response.data.messages) {
                if (Object.keys(err.response.data.errors).length > 0) {
                  for (const [key, value] of Object.entries(
                    err.response.data.errors
                  )) {
                    console.log(`${key}: ${value}`);
                    // errors;
                  }
                  let errors = convertToFormikError(
                    err.response.data.errors,
                    t
                  );
                  //  console.log("ERRS: ", errors);
                  setErrors(errors);
                }
                let errorlist = err.response.data.messages.filter(
                  (n) => n !== ""
                );
                const translatedErrorlist = errorlist
                  .map(function (value) {
                    return t(value);
                  })
                  .join("\r\n");
                dispatch(showErrorAlertMessage(translatedErrorlist));
                dispatch(broadcastAnnouncement(translatedErrorlist));
              } else {
                dispatch(
                  showErrorAlertMessage("unable to save the car club user.")
                );
                dispatch(
                  broadcastAnnouncement("unable to save the car club user.")
                );
              }

              appInsights.trackException({
                exception: err,
                properties: {
                  method: "onSubmit",
                  Component: "MyCCprofilePersonalInfo",
                },
                severityLevel: SeverityLevel.Error,
              });
            });
          setLoading(false);
          window.scrollTo(0, 0);
        }}
      >
        {({
          values,
          isValid,
          dirty,
          setFieldValue,
          setFieldTouched,
          touched,
          handleChange,
          handleBlur,
          errors,
          isSubmitting,
        }) => (
          <Form>
            <Grid container className={classes.placeHolder}>
              <Grid item xs={12} md={12}>
                <Typography
                  id="titleHeader"
                  //ref={divRef}
                  variant="h1"
                  //onClick={()=>{console.log("values", values, "isValid",isValid, "dirty",dirty, "result",!isValid || !dirty,"errors", errors,"touched",touched,"initialValue",driverContactInfo);}}
                  tabIndex={-1}
                >
                  {t("Users.personalInformationTitle")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={12} className={classes.gridItem}>
                <label htmlFor={"firstName"} className={classes.labelText}>
                  {t("Users.firstname")}
                </label>
              </Grid>
              <Grid item xs={12} md={12} className={classes.gridItem}>
                <Field
                  name="firstName"
                  id="firstName"
                  value={values.firstName}
                  fullWidth
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  inputProps={{
                    "aria-label": t("Users.firstname"),
                    "aria-required": "true",
                  }}
                  onChange={(e) => {
                    setFieldTouched("firstName");
                    setFieldValue("firstName", e.target.value);
                  }}
                  onBlur={handleBlur}
                  component={TextField}
                  variant="outlined"
                  error={
                    touched.firstName && errors.firstName !== undefined
                      ? true
                      : false
                  }
                  placeholder={t("Users.firstnamePlaceholder")}
                  disabled={true}
                />
                {touched.firstName && errors.firstName ? (
                  <span id="firstNameError" className={classes.errorWarning}>
                    {t(errors?.firstName ?? "")}
                  </span>
                ) : (
                  <span id="firstNameError"></span>
                )}
              </Grid>

              <Grid item xs={12} md={12} className={classes.gridItem}>
                <label htmlFor={"lastName"} className={classes.labelText}>
                  {t("Users.lastname")}
                </label>
              </Grid>
              <Grid item xs={12} md={12} className={classes.gridItem}>
                <Field
                  name="lastName"
                  id="lastName"
                  value={values.lastName}
                  fullWidth
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  inputProps={{
                    "aria-label": t("Users.lastname"),
                    "aria-required": true,
                  }}
                  onChange={(e) => {
                    setFieldTouched("lastName");
                    setFieldValue("lastName", e.target.value);
                  }}
                  onBlur={handleBlur}
                  component={TextField}
                  variant="outlined"
                  error={
                    touched.lastName && errors.lastName !== undefined
                      ? true
                      : false
                  }
                  placeholder={t("Users.lastnamePlaceholder")}
                  disabled={true}
                />
                {touched.lastName && errors.lastName ? (
                  <span id="lastNameError" className={classes.errorWarning}>
                    {t(errors?.lastName ?? "")}
                  </span>
                ) : (
                  <span id="lastNameError"></span>
                )}
              </Grid>

              <Grid item xs={12} md={7} className={classes.gridItem}>
                <label htmlFor={"email"} className={classes.labelText}>
                  {t("Users.emailaddress")}
                </label>
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={7} className={classes.gridItem}>
                <Field
                  name="email"
                  id="email"
                  value={values.email}
                  fullWidth
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  inputProps={{
                    "aria-label": t("Users.emailaddress"),
                    "aria-required": true,
                  }}
                  onChange={(e) => {
                    setFieldTouched("email");
                    setFieldValue("email", e.target.value);
                  }}
                  onBlur={handleBlur}
                  component={TextField}
                  variant="outlined"
                  error={
                    touched.email && errors.email !== undefined ? true : false
                  }
                  placeholder={t("Users.emailPlaceholder")}
                />
                {touched.email && errors.email ? (
                  <span id="emailError" className={classes.errorWarning}>
                    {t(errors?.email ?? "")}
                  </span>
                ) : (
                  <span id="emailError"></span>
                )}
              </Grid>
              <Grid item xs={12} md={5} />

              <Grid item xs={12} md={7} className={classes.gridItem}>
                <label htmlFor={"homePhone"} className={classes.labelText}>
                  {t("Users.homePhone")}
                </label>
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={7} className={classes.gridItem}>
                <Field
                  name="homePhone"
                  id="homePhone"
                  value={values.homePhone}
                  fullWidth
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  inputProps={{
                    "aria-label": t("Users.homePhone"),
                    "aria-required": true,
                  }}
                  onChange={(e) => {
                    setFieldTouched("homePhone");
                    setFieldValue("homePhone", e.target.value);
                  }}
                  onBlur={handleBlur}
                  component={TextField}
                  variant="outlined"
                  error={
                    touched.homePhone && errors.homePhone !== undefined
                      ? true
                      : false
                  }
                  placeholder={t("Users.homePhonePlaceholder")}
                />
                {touched.homePhone && errors.homePhone ? (
                  <span id="homePhoneError" className={classes.errorWarning}>
                    {t(errors?.homePhone ?? "")}
                  </span>
                ) : (
                  <span id="homePhoneError"></span>
                )}
              </Grid>
              <Grid item xs={12} md={5} />

              <Grid item xs={12} md={7} className={classes.gridItem}>
                <label htmlFor={"workPhone"} className={classes.labelText}>
                  {t("Users.workPhone")}
                </label>
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={7} className={classes.gridItem}>
                <Field
                  name="workPhone"
                  id="workPhone"
                  value={values.workPhone}
                  fullWidth
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  inputProps={{
                    "aria-label": t("Users.workPhone"),
                    "aria-required": true,
                  }}
                  onChange={(e) => {
                    setFieldTouched("workPhone");
                    setFieldValue("workPhone", e.target.value);
                  }}
                  onBlur={handleBlur}
                  component={TextField}
                  variant="outlined"
                  error={
                    touched.workPhone && errors.workPhone !== undefined
                      ? true
                      : false
                  }
                  placeholder={t("Users.workPhonePlaceholder")}
                />
                {touched.workPhone && errors.workPhone ? (
                  <span id="workPhoneError" className={classes.errorWarning}>
                    {t(errors?.workPhone ?? "")}
                  </span>
                ) : (
                  <span id="workPhoneError"></span>
                )}
              </Grid>
              <Grid item xs={12} md={5} />

              <Grid item xs={12} md={7} className={classes.gridItem}>
                <label htmlFor={"mobilePhone"} className={classes.labelText}>
                  {t("Users.mobilePhone")}
                </label>
              </Grid>
              <Grid item xs={12} md={5} />
              <Grid item xs={12} md={7} className={classes.gridItem}>
                <Field
                  name="mobilePhone"
                  id="mobilePhone"
                  value={values.mobilePhone}
                  fullWidth
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  inputProps={{
                    "aria-label": t("Users.mobilePhone"),
                    "aria-required": true,
                  }}
                  onChange={(e) => {
                    setFieldTouched("mobilePhone");
                    setFieldValue("mobilePhone", e.target.value);
                  }}
                  onBlur={handleBlur}
                  component={TextField}
                  variant="outlined"
                  error={
                    touched.mobilePhone && errors.mobilePhone !== undefined
                      ? true
                      : false
                  }
                  placeholder={t("Users.mobilePhonePlaceholder")}
                />
                {touched.mobilePhone && errors.mobilePhone ? (
                  <span id="mobilePhoneError" className={classes.errorWarning}>
                    {t(errors?.mobilePhone ?? "")}
                  </span>
                ) : (
                  <span id="mobilePhoneError"></span>
                )}
              </Grid>
              <Grid
                container
                direction="row"
                item
                xs={12}
                md={5}
                justifyContent="flex-end"
                style={{ padding: 12, marginTop: "1em", height: "20px" }}
              >
                <Button
                  color="primary"
                  id="btnSubmit"
                  variant="contained"
                  disabled={!isValid || !dirty}
                  type="submit"
                  style={{ textTransform: "none" }}
                >
                  {t("Users.btnSave")}
                </Button>
                <Button
                  color="primary"
                  id="btnCancel"
                  variant="outlined"
                  type="button"
                  onClick={handleCancel}
                  style={{ marginLeft: "0.5em", textTransform: "none" }}
                >
                  {t("Users.btnCancel")}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default MyCCprofilePersonalInfo;
